import React, { useEffect, useState } from 'react'
import SignupTitleAndDesc from '../../components/SignupTitleAndDesc'
import './styles.scss'
import Button from '../../components/Button'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PROPERTIES } from '../../routing/navigation'
import { useAuthDispatch } from '../../contexts/authContext'
import { toast } from '../../components/Toast'
import Loader from '../../components/Loader'

const EmailVerified: React.FC = () => {
  const route = useNavigate()
  const [searchParams] = useSearchParams()
  const { verifyEmail } = useAuthDispatch()
  const token = searchParams.get('token') ?? ''
  const email = searchParams.get('email') ?? ''

  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    ;(async function () {
      setLoading(true)
      try {
        await verifyEmail({ email, token })
        setSuccess(true)
      } catch (error) {
        const err = error as Error
        toast.error(err.message)
      }
      setLoading(false)
    })()
  }, [])

  const continueToDashboard = (): void => {
    route(`/${PROPERTIES}`)
  }

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <SignupTitleAndDesc
            title={
              success ? 'Email address verified' : 'Email verification failed'
            }
            description={
              success
                ? 'The email address is now verified.'
                : 'Unable to verify email'
            }
            type="STD2"
          />

          {success && (
            <Button
              onClick={continueToDashboard}
              label="Continue to dashboard"
            />
          )}
        </>
      )}
    </>
  )
}

export default EmailVerified
