export const registrationValidations = {
  email: (data: Record<string, string>): string => {
    if (!data?.email) return 'Email is required'
    if (
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        data.email
      )
    ) {
      return 'Invalid email'
    }

    return ''
  },
  password: (data: Record<string, string>): string => {
    if (!data.password) return 'Password is required'
    if (data.password.length < 8) {
      return 'Password must be 8 characters'
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(data.password)) {
      return 'Password must contain at least one special character'
    }
    if (!/[A-Z]/.test(data.password)) {
      return 'Password must contain at least on upper case'
    }
    if (!/[a-z]/.test(data.password)) {
      return 'Password must contain at least on lower case'
    }
    return ''
  },
  confirmPassword: (data: Record<string, string>): string =>
    data.password !== data.confirmPassword ? 'Password does not match' : '',
}

export const updatePasswordValidation = {
  oldPassword: (data: Record<string, string>): string =>
    !data.oldPassword ? 'Old password is required' : '',
  newPassword: (data: Record<string, string>): string => {
    if (!data.newPassword) return 'Password is required'
    if (data.newPassword.length < 8) {
      return 'Password must be 8 characters'
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(data.newPassword)) {
      return 'Password must contain at least one special character'
    }
    if (!/[A-Z]/.test(data.newPassword)) {
      return 'Password must contain at least on upper case'
    }
    if (!/[a-z]/.test(data.newPassword)) {
      return 'Password must contain at least on lower case'
    }
    return ''
  },
  newPasswordConfirmation: (data: Record<string, string>): string =>
    data.newPassword !== data.newPasswordConfirmation
      ? 'Password does not match'
      : '',
}

export const beneficiaryValidations = {
  swiftCode: (data: Record<string, string>): string => {
    if (!data.swiftCode) {
      return 'Swift code is required'
    }
    return ''
  },
  country: (data: Record<string, string>): string => {
    if (!data.country) {
      return 'Country  is required'
    }
    return ''
  },
  iban: (data: Record<string, string>): string => {
    if (!data.iban) {
      return 'IBAN is required'
    }
    return ''
  },
  accountNumber: (data: Record<string, string>): string => {
    if (!data.accountNumber) {
      return 'Account number is required'
    }
    return ''
  },
  bankName: (data: Record<string, string>): string => {
    if (!data.bankName) {
      return 'Bank name is required'
    }
    return ''
  },
  accountName: (data: Record<string, string>): string => {
    if (!data.accountName) {
      return 'Account name is required'
    }
    return ''
  },
}

export const validateInput = ({
  data,
  onSubmit = true,
  validations = registrationValidations,
}: {
  data: Record<string, any>
  validations: { [key: string]: (data: Record<string, string>) => string }
  onSubmit: boolean
}): {
  validationResultData?: { [key: string]: string }
  validated: boolean
} => {
  const dataKeys = Object.keys(validations)
  let validationResultData: { [key: string]: string } = {}
  if (!dataKeys.length) return { validated: false }

  dataKeys.forEach((key) => {
    if (!onSubmit && !data[key]) {
      return false
    }
    const result = validations[key](data)
    validationResultData = { ...validationResultData, [key]: result }
  })

  const values = Object.values(validationResultData)
  const filteredValues = Object.values(validationResultData).filter(
    (val) => val
  )

  return {
    validationResultData,
    validated: filteredValues.length === 0 && values.length === dataKeys.length,
  }
}
