import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import GetStarted from '../pages/Getstarted'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import ForgotPassword from '../pages/ForgotPassword'
import SetPassword from '../pages/SetPassword'
import {
  FORGOT_PASSWORD,
  NEW_PASSWORD,
  SIGNIN,
  SIGNUP,
  EMAIL_VERIFIED,
  HOME,
  GET_STARTED
} from './navigation'
import SignupWrapper from '../components/SignupWrapper'
import Nav from '../components/Nav'
import LandingPage from '../pages/LandingPage'
import CheckEmail from '../pages/CheckEmail'
import EmailVerified from '../pages/EmailVerified'
import VerifyEmail from '../pages/VerifyEmail'
import VerifyOtp from '../pages/VerifyOtp'
import VerifyPhone from '../pages/VerifyPhone'
import { Protected, UnProtected } from './RouteComponent'
import Loader from '../components/Loader'
import Investment from '../pages/Investments'

import Profile from '../pages/Profile'
import FiatWallet from '../pages/FiatWallet'
import CryptoWallet from '../pages/CryptoWallet'
import FiatTransactions from '../pages/FiatTransactions'
import SavedProperties from '../pages/SavedProperties'
import Properties from '../pages/Properties'
import PropertyDetails from '../pages/PropertyDetails'
import NotFound from '../pages/404'
import { useAuthState } from '../contexts/authContext'
const Settings = React.lazy(async () => import('../pages/Settings'))

const CryptoTransactions = React.lazy(
  async () => import('../pages/CryptoTransactions')
)
const PropertyPhoto = React.lazy(async () => import('../pages/PropertyPhotos'))
const Kyc = React.lazy(async () => import('../pages/kyc'))
const Referral = React.lazy(async () => import('../pages/Referral'))
const BankAccount = React.lazy(async () => import('../pages/BankAccount'))

const Routing: React.FC = () => {
  const { isAuthenticated } = useAuthState()
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Nav type="landingPageNav" />}>
          <Route index element={<LandingPage />} />
        </Route>
        <Route
          path="/"
          element={
            <UnProtected>
              <SignupWrapper />
            </UnProtected>
          }
        >
          <Route path={`/${GET_STARTED}`} element={<GetStarted />} />
        </Route>
        <Route path="/" element={<SignupWrapper />}>
          <Route path={EMAIL_VERIFIED} element={<EmailVerified />} />
        </Route>
        <Route
          path="/"
          element={
            <UnProtected>
              <SignupWrapper
                showBackButton
                backButtonLabel="Back to home"
                backButtonLink="/getstarted"
              />
            </UnProtected>
          }
        >
          <Route path={SIGNUP} element={<Signup />} />
          <Route path={SIGNIN} element={<Login />} />
        </Route>

        <Route
          path="/"
          element={
            <UnProtected>
              <SignupWrapper
                showBackButton
                backButtonLabel="Back to login"
                backButtonLink={SIGNIN}
              />
            </UnProtected>
          }
        >
          <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={NEW_PASSWORD} element={<SetPassword />} />
        </Route>

        {/* protected routes */}

        <Route
          path={HOME}
          element={
            <Protected>
              <Nav />
            </Protected>
          }
        >
          <Route index element={<Properties />} />
          <Route
            path="profile"
            element={
              <React.Suspense fallback={<Loader />}>
                <Profile />
              </React.Suspense>
            }
          />
          <Route
            path="referral"
            element={
              <React.Suspense fallback={<Loader />}>
                <Referral />
              </React.Suspense>
            }
          />
          <Route
            path="bank-account"
            element={
              <React.Suspense fallback={<Loader />}>
                <BankAccount />
              </React.Suspense>
            }
          />
          <Route
            path="settings"
            element={
              <React.Suspense fallback={<Loader />}>
                <Settings />
              </React.Suspense>
            }
          />
          <Route
            path="properties"
            element={
              <React.Suspense fallback={<Loader />}>
                <Properties />
              </React.Suspense>
            }
          />
          <Route
            path={'properties/:id'}
            element={
              <React.Suspense fallback={<Loader />}>
                <PropertyDetails />
              </React.Suspense>
            }
          />
          <Route
            path={'properties/:id/photos'}
            element={
              <React.Suspense fallback={<Loader />}>
                <PropertyPhoto />
              </React.Suspense>
            }
          />
          <Route
            path={'saved-properties'}
            element={
              <React.Suspense fallback={<Loader />}>
                <SavedProperties />
              </React.Suspense>
            }
          />
          <Route
            path={'investments'}
            element={
              <React.Suspense fallback={<Loader />}>
                <Investment />
              </React.Suspense>
            }
          />
          {/* <Route
            path={'wallet'}
            element={
              <React.Suspense fallback={<Loader />}>
                <Wallet />
              </React.Suspense>
            }
          /> */}
          <Route
            path={'wallet/fiat'}
            element={
              <React.Suspense fallback={<Loader />}>
                <FiatWallet />
              </React.Suspense>
            }
          />
          <Route
            path={'wallet/crypto'}
            element={
              <React.Suspense fallback={<Loader />}>
                <CryptoWallet />
              </React.Suspense>
            }
          />
          <Route
            path={'transactions/fiat'}
            element={
              <React.Suspense fallback={<Loader />}>
                <FiatTransactions />
              </React.Suspense>
            }
          />
          <Route
            path={'transactions/crypto'}
            element={
              <React.Suspense fallback={<Loader />}>
                <CryptoTransactions />
              </React.Suspense>
            }
          />
        </Route>

        <Route path="/verify" element={<SignupWrapper />}>
          <Route
            path="phone"
            element={
              <Protected>
                <VerifyPhone />
              </Protected>
            }
          />
          <Route
            index
            element={
              <Protected>
                <VerifyPhone />
              </Protected>
            }
          />
          <Route
            path="otp"
            element={
              <Protected>
                <VerifyOtp />
              </Protected>
            }
          />
          <Route path="*" element={<p>Not found 404</p>} />
        </Route>

        <Route path="/email" element={<SignupWrapper showBackButton />}>
          <Route
            path="check-email"
            element={
              <Protected>
                <CheckEmail />
              </Protected>
            }
          />
          <Route
            index
            element={
              <Protected>
                <VerifyEmail />
              </Protected>
            }
          />
          <Route
            path="verify-email"
            element={
              <Protected>
                <VerifyEmail />
              </Protected>
            }
          />
        </Route>

        <Route path="/kyc" element={<SignupWrapper />}>
          <Route
            index
            element={
              <Protected>
                <React.Suspense fallback={<Loader />}>
                  <Kyc />
                </React.Suspense>
              </Protected>
            }
          />
        </Route>
        {isAuthenticated ? (
          <Route
            path="*"
            element={
              <Protected>
                <Nav />
              </Protected>
            }
          >
            <Route path="*" element={<NotFound />} />
          </Route>
        ) : (
          <Route path="*" element={<NotFound />} />
        )}
        {/* <Route path="*" element={<p>Not found 404</p>} /> */}
      </Routes>
    </BrowserRouter>
  )
}

export default Routing
