import React, { ReactNode } from 'react'
import Loading from '../Loading'
import './styles.scss'

export interface ButtonProps {
  label: ReactNode
  type?: 'primary' | 'outline' | 'link'
  onClick?: () => void
  disabled?: boolean
  size?: 'large' | 'medium' | 'small'
  loading?: boolean
}
const Button: React.FC<ButtonProps> = ({
  label,
  type = 'primary',
  onClick,
  disabled = false,
  size = 'large',
  loading = false,
}) => {
  const renderButton = (): any => {
    switch (size) {
      case 'large':
        return (
          <button
            onClick={onClick}
            disabled={disabled || loading}
            className={`btn btn-${type}`}
          >
            {loading ? <Loading /> : label}
          </button>
        )
      case 'small':
        return (
          <button
            onClick={onClick}
            disabled={disabled || loading}
            className={`btn btn-${type} small`}
          >
            {loading ? <Loading /> : label}
          </button>
        )
      case 'medium':
        return (
          <button
            onClick={onClick}
            disabled={disabled || loading}
            className={`btn btn-${type} medium`}
          >
            {loading ? <Loading /> : label}
          </button>
        )
    }
  }
  return <div className="Button">{renderButton()}</div>
}

export default Button
