import React, {
  createContext,
  useReducer,
  ReactNode,
  FC,
  useEffect,
} from 'react'
import GlobalReducer from './reducer'
import Api from '../../network/api'
import { useErrorState } from '../errorContext'
import { useAuthState } from '../authContext'
import { IError } from '../../interface/error'
import { EGlobal, IGLobalState } from '../../interface/global'
import { ISettingsResponse } from '../../interface/settings'
import { IWalletResponse } from '../../interface/wallet'

const initialState: IGLobalState = {
  settings: {
    wireCredential: null,
    cryptoWallet: null,
    fracxnFees: null,
    supportedFiat: null,
    supportedCrypto: null,
  },
  wallet: null,
}
interface IGlobalDispatch {
  getSettings: (token?: string) => Promise<ISettingsResponse>
  getWallet: () => Promise<IWalletResponse | null>
}

const contextError = 'Invalid dispatch request'

export const GlobalContext = createContext<IGLobalState>(initialState)
export const GlobalDispatch = createContext<IGlobalDispatch>({
  getSettings: async (token) => {
    throw new Error(contextError)
  },
  getWallet: async () => {
    throw new Error(contextError)
  },
})

export const GlobalProvider: FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode
}) => {
  const [state, dispatch] = useReducer(GlobalReducer, initialState)
  const { addError } = useErrorState()
  const { isAuthenticated, user } = useAuthState()

  useEffect(() => {
    if (isAuthenticated) {
      getSettings()
      getWallet()
    }
  }, [])

  const getSettings = async (token?: string): Promise<ISettingsResponse> => {
    try {
      const resp = await Api.settings(token)
      dispatch({
        type: EGlobal.GET_SETTINGS,
        payload: resp.data,
      })
      return resp
    } catch (error) {
      const err = error as IError
      addError({ message: err.message, status: err.status })
      throw error
    }
  }

  const getWallet = async (): Promise<IWalletResponse | null> => {
    try {
      if (user && user.kycLevel >= 2) {
        const resp = await Api.getWallet()
        dispatch({
          type: EGlobal.GET_WALLET,
          payload: resp.data,
        })
        return resp
      }
      return null
    } catch (error) {
      const err = error as IError
      addError({ message: err.message, status: err.status })
      throw error
    }
  }

  return (
    <GlobalContext.Provider
      value={{
        settings: state.settings,
        wallet: state.wallet,
      }}
    >
      <GlobalDispatch.Provider
        value={{
          getSettings,
          getWallet,
        }}
      >
        {children}
      </GlobalDispatch.Provider>
    </GlobalContext.Provider>
  )
}

function useGlobalState(): IGLobalState {
  const context = React.useContext(GlobalContext)
  if (context === undefined) {
    throw new Error('useAuthState must be used with a AuthProvider')
  }
  return context
}

function useGlobalDispatch(): IGlobalDispatch {
  const context = React.useContext(GlobalDispatch)
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used with a AuthProvider')
  }
  return context
}

export { useGlobalDispatch, useGlobalState }
