import React, { FC } from 'react'
import { Icon, IconProps } from '../Icons'
import './styles.scss'

interface ISignupButtonProps {
  label?: string
  icon: IconProps
  onClick: () => void
}

const SignupButton: FC<ISignupButtonProps> = ({ label, icon, ...props }) => {
  return (
    <div>
      <button
        onClick={() => props.onClick()}
        className="btn btn-outline Sign-up-button"
      >
        <span>
          <Icon name={icon.name} />
        </span>
        {label}
      </button>
    </div>
  )
}
export default SignupButton
