import React, { forwardRef, ForwardRefRenderFunction } from 'react'
import Button from '../Button'
import { Icon } from '../Icons'
import Modal, { IModal } from '../Modal'
import './styles.scss'
import { useGlobalState } from '../../contexts/globalContext'
import { toast } from '../Toast'

interface ModalProps {
  toggle: () => void
}
const BeneficiaryDetailsModal: ForwardRefRenderFunction<IModal, ModalProps> = (
  { ...props },
  ref
) => {
  const { settings } = useGlobalState()

  const nextStep = (): void => {
    props.toggle()
  }
  const prevStep = (): void => {
    props.toggle()
  }

  const copy = (text?: string): void => {
    if (text) {
      navigator.clipboard.writeText(text)
      toast.success('Copied to clipboard')
    }
  }

  return (
    <>
      <Modal ref={ref}>
        <div className="Beneficiary">
          <div>
            <div className="Modal-header">
              <div className="d-flex justify-content-between">
                <Icon name="back-arrow" onClick={prevStep} />
                <Icon name="close" onClick={props.toggle} />
              </div>
              <h3 className="text-center">Beneficiary Details </h3>
            </div>
            {settings && (
              <div className="Beneficiary-body p-3">
                <div className="Item d-flex justify-content-between align-items-center mb-4">
                  <div>
                    <p className="Item-title mb-2">Beneficiary name:</p>
                    <p className="Item-body mb-0">
                      {settings.wireCredential?.beneficiaryName}
                    </p>
                  </div>
                  <div
                    className="Copy"
                    onClick={() =>
                      copy(settings.wireCredential?.beneficiaryName)
                    }
                  >
                    <Icon name="copy" />
                  </div>
                </div>

                <div className="Item d-flex justify-content-between align-items-center mb-4">
                  <div>
                    <p className="Item-title mb-2">Bank name:</p>
                    <p className="Item-body mb-0">
                      {settings.wireCredential?.bankName}
                    </p>
                  </div>
                  <div
                    className="Copy"
                    onClick={() =>
                      copy(settings.wireCredential?.beneficiaryName)
                    }
                  >
                    <Icon name="copy" />
                  </div>
                </div>

                <div className="Item d-flex justify-content-between align-items-center mb-4">
                  <div>
                    <p className="Item-title mb-2">IBAN:</p>
                    <p className="Item-body mb-0">
                      {' '}
                      {settings.wireCredential?.iban}
                    </p>
                  </div>
                  <div
                    className="Copy"
                    onClick={() =>
                      copy(settings.wireCredential?.beneficiaryName)
                    }
                  >
                    <Icon name="copy" />
                  </div>
                </div>

                <div className="Item d-flex justify-content-between align-items-center mb-4">
                  <div>
                    <p className="Item-title mb-2">Account number (AED):</p>
                    <p className="Item-body mb-0">
                      {' '}
                      {settings.wireCredential?.accountNumber}
                    </p>
                  </div>
                  <div
                    className="Copy"
                    onClick={() =>
                      copy(settings.wireCredential?.beneficiaryName)
                    }
                  >
                    <Icon name="copy" />
                  </div>
                </div>

                <div className="Item d-flex justify-content-between align-items-center mb-4">
                  <div>
                    <p className="Item-title mb-2">Swift code:</p>
                    <p className="Item-body mb-0">
                      {' '}
                      {settings.wireCredential?.swiftCode}
                    </p>
                  </div>
                  <div
                    className="Copy"
                    onClick={() =>
                      copy(settings.wireCredential?.beneficiaryName)
                    }
                  >
                    <Icon name="copy" />
                  </div>
                </div>

                <div className="mb-2">
                  <Button label="Continue" onClick={nextStep} />
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default forwardRef(BeneficiaryDetailsModal)
