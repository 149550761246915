import React, { FC, useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import InvestmentCard from '../../components/InvestmentCard'
import { v4 as uuidv4 } from 'uuid'
import './styles.scss'
import PageTitle from '../../components/PageTitle'
import API from '../../network/api'
import { useErrorState } from '../../contexts/errorContext'
import { IError } from '../../interface/error'
import { toast } from '../../components/Toast'
import Loader from '../../components/Loader'
import Card from '../../components/Card'
// import { IInvestmentResponse } from '../../interface/bids'
import { PROPERTIES } from '../../routing/navigation'
import { useNavigate } from 'react-router-dom'

const Investment: FC = () => {
  const route = useNavigate()
  const { addError } = useErrorState()

  // const [investments, setInvestments] = useState<IInvestmentResponse['data']>()
  const [investments, setInvestments] = useState<any>()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getBids()
  }, [])

  const getBids = async (): Promise<void> => {
    setLoading(true)
    try {
      const allInvestments = await API.getInvestments()
      console.log(allInvestments)
      setInvestments(allInvestments.data)
    } catch (error) {
      const err = error as IError
      toast.error(err.message || 'Can not load investment at the moment')
      addError(err)
    }
    setLoading(false)
  }
  return (
    <>
      {loading && <Loader />}
      <div
        className="Investments mb-5"
        style={!investments ? { height: '100vh' } : {}}
      >
        <div className="container">
          {investments && investments.length >= 1 ? (
            <div>
              <PageTitle title="My Investments" />
              {/* <div className="row"> */}
              {investments.map((investment: any) => (
                <div className="Investment" key={uuidv4()}>
                  <InvestmentCard
                    onClick={() =>
                      route(`/${PROPERTIES}/${investment.auction._id}`)
                    }

                    title={investment.asset.title}
                    address={`${investment.asset.locationInfo.street} ${investment.asset.locationInfo.state} ${investment.asset.locationInfo.country}`}
                    images={investment.asset.image.map(
                      (img: any) => img.thumbnail.url
                    )}
                    // summary={investment.asset.type}
                    amountInvested={Number(investment.amount)}
                    currency={investment.asset.currency}
                    price={investment.asset.price}
                    ownership={
                      (100 * investment.amount) / Number(investment.asset.price)
                    }
                    status={investment.status}
                    fundingPercentage={
                      (investment.auction.totalBid * 100) /
                      Number(investment.asset.price)
                    }
                    rentPerMonth={Number(investment.asset.rentPerMonth || 0)}
                    txHash={investment.asset.transactionHash}
                    network={investment.asset.network}
                    // distributionFrequency={
                    //   investment.property.distributionFrequency
                    // }
                    // unlocksAt={fullDateString(investment.unlockAt)}
                  />
                </div>
              ))}
            </div>
          ) : (
            !loading && (
              <Card>
                <div
                  style={{ height: '300px' }}
                  className="card-body d-flex justify-content-center align-items-center"
                >
                  <h4 className="text-secondary">No Investment found</h4>
                </div>
              </Card>
            )
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Investment
