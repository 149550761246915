import React, { FC } from 'react'
import './styles.scss'
import Card from '../Card'
import { Icon } from '../Icons'

export interface IInvestmentProps {
  images: string[]
  // summary: string
  amountInvested: number
  currency: string
  price: any
  rentPerMonth: number
  fundingPercentage: number
  status: string
  // distributionFrequency: string
  onClick: () => void
  ownership: number
  title: string
  address: string
  txHash: string
  network: string
  // unlocksAt: string
}

const InvestmentCard: FC<IInvestmentProps> = ({ ...props }) => {
  // const handleClick = (): void => {
  //   // const event = e as Event
  //   // event.stopImmediatePropagation()
  //   props.onClick()
  // }

  function getStatusClass(status: string): string {
    switch (status) {
      case 'CLOSE':
        return 'success'
      case 'PENDING':
        return 'warning'
      case 'CANCELLED':
        return 'danger'
      default:
        return 'danger'
    }
  }

  return (
    <div className="Investment-card">
      <Card>
        <div className="row" onClick={props.onClick && props.onClick}>
          <div className="col-lg-2">
            <div className="Img-container">
              <img src={props.images[0]} alt="investment image" />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="Title ps-lg-4">
              <p>
                {props.title} in {props.address}
              </p>
            </div>

            <div className="Details ps-lg-4">
              <div className="d-lg-flex ">
                <div className="Flex-item Flex-1">
                  <small>Monthly rent</small>
                  <p>
                    {props.currency} {props.rentPerMonth}
                  </p>
                </div>

                <div className="Flex-item">
                  <small>Property value</small>
                  <p>{props.price.toLocaleString()}</p>
                </div>
              </div>

              <div className="d-flex">
                <div className="Flex-item">
                  <small>Amount invested</small>
                  <p>
                    {props.currency} {props.amountInvested.toLocaleString()}
                  </p>
                </div>

                <div className="Flex-item">
                  <small>Share holding</small>
                  <p>{parseFloat(props.ownership.toString()).toFixed(2)}%</p>
                </div>

                <div className="Flex-item-last">
                  <small>Funding method</small>
                  <p>Fiat(BE) </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 Status">
            <small>Funding status</small>
            <div className="row">
              <div className="col-7">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-label="investment-progress"
                    style={{ width: `${props.fundingPercentage.toFixed(2)}%` }}
                    aria-valuenow={Number(props.fundingPercentage.toFixed(2))}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
              <div className="col-5">
                <p style={{ fontSize: 12, marginTop: 4 }}>
                  {props.fundingPercentage.toFixed(2)}%
                </p>
              </div>
            </div>

            <div className={`Status-bar ${getStatusClass(props.status)}`}>
              <small>{props.status}</small>
            </div>
            <div className="Forward">
              <Icon name="caret-right" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-10">
            <div className="Buttons d-flex align-items-center justify-content-between">
              <div className="Block">
                <a
                  href={`${
                    process.env.REACT_APP_CRYPTO_TX_URL ??
                    'https://mumbai.polygonscan.com'
                  }/tx/${props.txHash}?network=${props.network}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-link"
                >
                  <Icon name="open-new-tab" />
                  <span>View on block explorer</span>
                </a>
              </div>
              <div className="Nft">
                <a href="" className="btn btn-outline-primary">
                  <Icon name="eye" />
                  <span>View NFT</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default InvestmentCard
