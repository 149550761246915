import React from 'react'
import './styles.scss'
import Footer from '../../components/Footer'
import { Icon } from '../../components/Icons'
import Button from '../../components/Button'
import { useAuthState } from '../../contexts/authContext'
import { useNavigate } from 'react-router-dom'
import { HOME } from '../../routing/navigation'

const NotFound: React.FC = () => {
  const { isAuthenticated } = useAuthState()
  const route = useNavigate()

  function handleGohome(): void {
    if (isAuthenticated) {
      route(`/${HOME}`)
    } else {
      route('/')
    }
  }
  return (
    <div className="Not-found">
      <div className="container">
        <div className="Not-found-items d-flex justify-content-center">
          <div className="mt-5">
            <Icon name="insufficientCaution" />
            <h3 className="mt-3 mb-3">Page not found</h3>
            <Button label="Go Home" onClick={handleGohome} size="medium" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default NotFound
