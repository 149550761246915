import React, { useEffect, useRef, useState } from 'react'
import Card from '../../components/Card'
import InnerNav from '../../components/InnerNav'
import './styles.scss'
import Button from '../../components/Button'
import PageTitle from '../../components/PageTitle'
import { useAuthDispatch, useAuthState } from '../../contexts/authContext'
import { toast } from '../../components/Toast'
import Loader from '../../components/Loader'
import Footer from '../../components/Footer'
import UpdatePhoneModal from './components/UpdatePhoneModal'
import { IModal } from '../../components/Modal'
import UpdateEmailModal from './components/UpdateEmailModal'
import UpdateUserNameModal from './components/UpdateUserNameModal'
import UpdatePasswordModal from './components/UpdatePasswordModal'

const Profile: React.FC = () => {
  const { getProfile, getUser } = useAuthDispatch()
  const { user } = useAuthState()

  const [profileData, setProfileData] = useState<Record<string, string>>({})
  const [loading, setLoading] = useState(false)
  const [showUpdatePhone, setShowUpdatePhone] = useState(false)
  const [showUpdateEmail, setShowUpdateEmail] = useState(false)
  const [showUpdateUserName, setShowUpdateUserName] = useState(false)
  const [showUpdatePassword, setShowUpdatePassword] = useState(false)

  const UpdatePhoneModalRef = useRef<IModal>(null)
  const UpdateEmailModalRef = useRef<IModal>(null)
  const UpdateUserNameModalRef = useRef<IModal>(null)
  const UpdatePasswordModalRef = useRef<IModal>(null)

  useEffect(() => {
    getUserData()
  }, [])

  const getUserData = async (): Promise<any> => {
    setLoading(true)
    try {
      // await getUser()
      const resp = await getProfile()
      setProfileData({ ...resp.data })
    } catch (error) {
      const err = error as Error
      toast(err.message || ' Can not get user data at the moment')
    }
    setLoading(false)
  }

  const getUserInfo = async (): Promise<any> => {
    try {
      // await getUser()
      await getUser()
    } catch (error) {
      const err = error as Error
      toast(err.message || ' Can not get user data at the moment')
    }
  }

  useEffect(() => {
    if (showUpdatePhone) UpdatePhoneModalRef.current?.toggle()
  }, [showUpdatePhone])

  useEffect(() => {
    if (showUpdateEmail) UpdateEmailModalRef.current?.toggle()
  }, [showUpdateEmail])

  useEffect(() => {
    if (showUpdateUserName) UpdateUserNameModalRef.current?.toggle()
  }, [showUpdateUserName])

  useEffect(() => {
    if (showUpdatePassword) UpdatePasswordModalRef.current?.toggle()
  }, [showUpdatePassword])

  const togglePhoneUpdate = (): void => {
    UpdatePhoneModalRef.current?.toggle()
    setShowUpdatePhone(false)
  }

  const toggleEmailUpdate = (): void => {
    UpdateEmailModalRef.current?.toggle()
    setShowUpdateEmail(false)
  }

  const toggleUserNameUpdate = (): void => {
    UpdateUserNameModalRef.current?.toggle()
    setShowUpdateUserName(false)
  }

  const togglePasswordUpdate = (): void => {
    UpdatePasswordModalRef.current?.toggle()
    setShowUpdatePassword(false)
  }

  return (
    <>
      <InnerNav>
        <div className="Profile">
          {loading && <Loader />}

          <PageTitle title="My Profile" />

          {user && (
            <div className="mb-5">
              <div className="row mb-5">
                <div className="col-lg-8">
                  <Card>
                    <div className="card-body">
                      <div className="row mb-5">
                        <div className="col-12">
                          <div className="Profile-header mb-4">
                            <h6>Personal Details </h6>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div>
                            <div className="Label">
                              <p>First Name</p>
                            </div>

                            <div className="Value">
                              <p>{profileData?.firstName || '-'}</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 d-flex justify-content-between">
                          <div>
                            <div className="Label">
                              <p>Last Name</p>
                            </div>

                            <div className="Value">
                              <p>{profileData?.lastName || '-'}</p>
                            </div>
                          </div>
                          <div className="me-5 align-self-end">
                            <Button
                              type="link"
                              label="Update"
                              onClick={() => {
                                setShowUpdateUserName(true)
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mb-5">
                        <div className="col-md-4">
                          <div>
                            <div className="Label">
                              <p>Email</p>
                            </div>

                            <div className="Value">
                              <p>{user.email}</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-8 d-flex align-items-end">
                          <Button
                            type="link"
                            label="Update"
                            onClick={() => {
                              setShowUpdateEmail(true)
                            }}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <div>
                            <div className="Label">
                              <p>Phone Number</p>
                            </div>

                            <div className="Value">
                              <p>{user.phone}</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-8 d-flex align-items-end">
                          <Button
                            type="link"
                            label="Update"
                            onClick={() => {
                              setShowUpdatePhone(true)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-8">
                  <Card>
                    <div className="card-body">
                      <div className="row mb-5">
                        <div className="col-12">
                          <div className="Profile-header mb-4">
                            <h6>Password </h6>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div>
                            <div className="Label">
                              <p>Current password</p>
                            </div>

                            <div className="Value">
                              <p>***************</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-8 d-flex align-items-end">
                          <Button
                            type="link"
                            label="Update"
                            onClick={() => {
                              setShowUpdatePassword(true)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          )}
        </div>
      </InnerNav>
      <Footer />

      {showUpdatePhone && (
        <UpdatePhoneModal
          ref={UpdatePhoneModalRef}
          toggle={() => togglePhoneUpdate()}
          callback={getUserInfo}
        />
      )}

      {showUpdateEmail && (
        <UpdateEmailModal
          ref={UpdateEmailModalRef}
          toggle={() => toggleEmailUpdate()}
          callback={getUserInfo}
        />
      )}

      {showUpdateUserName && (
        <UpdateUserNameModal
          ref={UpdateUserNameModalRef}
          toggle={() => toggleUserNameUpdate()}
          callback={getUserData}
          profileData={profileData}
        />
      )}

      {showUpdatePassword && (
        <UpdatePasswordModal
          ref={UpdatePasswordModalRef}
          toggle={() => togglePasswordUpdate()}
        />
      )}
    </>
  )
}

export default Profile
