import React, { FormEvent, useEffect, useState } from 'react'
import SignupTitleAndDesc from '../../components/SignupTitleAndDesc'
import './styles.scss'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthDispatch } from '../../contexts/authContext'
import { toast } from '../../components/Toast'
import { registrationValidations, validateInput } from '../../utils/validations'
import { HOME } from '../../routing/navigation'
import { useErrorState } from '../../contexts/errorContext'
import { useGlobalDispatch } from '../../contexts/globalContext'
import { TOKEN } from '../../constants/variables'

const Login: React.FC = () => {
  const { login } = useAuthDispatch()
  const { removeError } = useErrorState()
  const { getSettings } = useGlobalDispatch()
  const route = useNavigate()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ [key: string]: string }>({})
  const [validated, setValidated] = useState(false)
  const [validationResult, setValidationResult] = useState<{
    [key: string]: string
  }>({})
  useEffect(() => {
    removeError()
    localStorage.removeItem(TOKEN)
  }, [])
  const password = (data: Record<string, string>): string => {
    if (!data.password) return 'Password is required'
    return ''
  }
  const validations = {
    email: registrationValidations.email,
    password,
  }

  const validate = (onSubmit = true): boolean => {
    const validationResponse = validateInput({
      data,
      onSubmit,
      validations,
    })
    setValidationResult((prev) => {
      return { ...prev, ...validationResponse.validationResultData }
    })
    return validationResponse.validated
  }
  useEffect(() => {
    let delayDebounceFn: any = null

    delayDebounceFn = setTimeout(() => {
      const result = validate(false)
      setValidated(result)
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [data])

  const onInputChange = (name: string, value: string): void => {
    setData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      }
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loadSettings = async (token: string): Promise<void> => {
    try {
      await getSettings(token)
    } catch (error) {
      toast.error('Unable to load configuration')
    }
  }

  const handleLogin = async (): Promise<void> => {
    setLoading(true)
    removeError()
    try {
      const response = await login({
        email: data.email,
        password: data.password,
      })

      toast.success("You've successfully logged in")
      await loadSettings(response.data.accessToken)
      route(`/${HOME}`)
    } catch (error) {
      const err = error as Error
      toast.error(err.message)
    }
    setLoading(false)
  }

  const submit = (e: FormEvent): void => {
    e.preventDefault()
    handleLogin()
  }

  return (
    <div className="Login">
      <>
        <SignupTitleAndDesc
          title="Welcome Back!"
          description="Don’t have a account yet?"
          linkName="Sign up"
          type="STD1"
          link="/signup"
        />
        <form onSubmit={submit}>
          <TextInput
            label="Email"
            placeholder="Enter your email address"
            id="email-login"
            name="email"
            type="text"
            onChange={onInputChange}
            errorText={validationResult.email}
            valid={!validationResult.email}
            isRequired
          />
          <TextInput
            label="Password"
            placeholder="Enter password"
            id="password-login"
            name="password"
            type="password"
            onChange={onInputChange}
            errorText={validationResult.password}
            valid={!validationResult.password}
            isRequired
          />
          <Button disabled={!validated} loading={loading} label="Login" />
          <div className="Forgot-password-link">
            <Link
              style={{
                fontSize: '14px',
                textDecoration: 'none',
                marginTop: 10,
              }}
              to="/forgot-password"
            >
              Forgot password
            </Link>
          </div>
        </form>
      </>
    </div>
  )
}

export default Login
