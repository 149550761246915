import React, {
  forwardRef,
  ForwardRefRenderFunction,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Modal } from 'bootstrap'
import './styles.scss'

export interface IModal {
  toggle: () => void
}

interface props {
  onClose?: () => void
  backdrop?: boolean | 'static'
  children?: ReactElement
  size?: 'small' | 'normal'
}

const Alert: ForwardRefRenderFunction<IModal, props> = (
  { children, onClose, backdrop = 'static', size = 'normal' },
  ref
) => {
  const modalToggleRef = useRef<HTMLButtonElement>(null)
  const modalRef = useRef<HTMLDivElement>(null)
  const [isShown, setIsShown] = useState(false)
  useEffect(() => {
    const modal = modalRef.current

    const close = (): void => {
      onClose?.()
    }

    modal?.addEventListener('hidden.bs.modal', close)

    return () => {
      return modal?.removeEventListener('hidden.bs.modal', close)
    }
  }, [modalRef.current])

  const showModal = (): void => {
    setIsShown(true)
    const modalEle = modalRef.current
    if (modalEle) {
      const bsModal = new Modal(modalEle, {
        backdrop,
        keyboard: false,
      })
      bsModal.show()
    }
  }

  const hideModal = (): void => {
    setIsShown(false)
    const modalEle = modalRef.current
    if (modalEle) {
      const bsModal = Modal.getInstance(modalEle)
      bsModal?.hide()
    }
  }

  const toggle = (): void => {
    if (isShown) {
      hideModal()
    } else {
      showModal()
    }
  }

  useImperativeHandle(ref, () => ({
    toggle,
  }))

  return (
    <div className="Modal">
      <div
        className="modal fade"
        id="exampleModalToggle"
        ref={modalRef}
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div
            className={`modal-content ${size === 'small' ? 'Small-width' : ''}`}
          >
            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>
      <button ref={modalToggleRef} className="d-none" data-bs-toggle="modal">
        Open first modal
      </button>
    </div>
  )
}

export default forwardRef(Alert)
