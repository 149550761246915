import {
  EStatus,
  IAuctionResponse,
  IBidData,
  IBidResponse,
  ISaveAuctionResponse,
  ISingleAuctionResponse
} from '../interface/auction'
import {
  IEmailReg,
  IGoogleLogin,
  ILogin,
  ILoginResponse,
  IMetaLoginResponse,
  IMetaMaskLogin,
  IMetaMaskRequest,
  IMetaMaskRequestResponse,
  IReqestPassword,
  IReqestPasswordResponse,
  IResetPassword,
  IUpdatePassword,
  IValidatePasswordResetResponse
} from '../interface/auth'
import {
  IBeneficiaryResponse,
  ICreateBeneficiaryData,
  ICreateBeneficiayResponse
} from '../interface/beneficiary'
import {
  IInvestmentResponse,
  IBidResponse as BidResponse
} from '../interface/bids'
import { ICardDepositData, ICardDepositResponse } from '../interface/card'
import {
  IConvertAnyCurrencyResponse,
  IConvertCurrencyResponse,
  ICryptoRateResponse
} from '../interface/currencies'
import { IKycSessionData, IKycSessionResponse } from '../interface/kyc'
import { INetworkResponse } from '../interface/network'
import { ISettingsResponse } from '../interface/settings'
import {
  IGetTransactions,
  ITransactionsResponse,
  IVerifyCardTransaction,
  IWireDepositData,
  IWireWithdrawalData
} from '../interface/transaction'
import {
  IGetProfileResponseData,
  IUpdateEmail,
  IUpdateProfile,
  IUser,
  IVerifyEmail,
  IVerifyOtp,
  IVerifyOTPResponse,
  IVerifyPhone,
  IVerifyPhoneResponse
} from '../interface/user'
import { IWalletResponse } from '../interface/wallet'
import endpoints from './endpoints'
import { requestMaker } from './request'

async function requestMetaMask(
  data: IMetaMaskRequest
): Promise<IMetaMaskRequestResponse> {
  return requestMaker({
    type: 'POST',
    route: endpoints.REQUEST_METAMASK_LOGIN,
    data
  })
}

async function loginMetaMask(
  data: IMetaMaskLogin
): Promise<IMetaLoginResponse> {
  return requestMaker({
    type: 'POST',
    route: endpoints.METAMASK_LOGIN,
    data
  })
}

async function loginGoogle(data: IGoogleLogin): Promise<any> {
  return requestMaker({
    type: 'POST',
    route: endpoints.GOOGLE_LOGIN,
    data
  })
}

async function emailReg(data: IEmailReg): Promise<any> {
  return requestMaker({
    type: 'POST',
    route: endpoints.EMAIL_REGISTRATION,
    data
  })
}

async function login(data: ILogin): Promise<ILoginResponse> {
  return requestMaker({
    type: 'POST',
    route: endpoints.LOGIN,
    data
  })
}

async function logout(): Promise<any> {
  return requestMaker({
    type: 'POST',
    route: endpoints.LOGOUT,
    isSecure: true,
    data: {}
  })
}

async function requestPasswordReset(
  data: IReqestPassword
): Promise<IReqestPasswordResponse> {
  return requestMaker({
    type: 'POST',
    route: endpoints.REQUEST_PASSWORD_RESET,
    data
  })
}

async function validatePasswordReset(
  token: string
): Promise<IValidatePasswordResetResponse> {
  return requestMaker({
    type: 'GET',
    route: endpoints.VALIDATE_PASSWORD_RESET(token)
  })
}

async function resetPassword(
  data: IResetPassword
): Promise<IValidatePasswordResetResponse> {
  return requestMaker({
    type: 'PUT',
    route: endpoints.RESET_PASSWORD,
    data
  })
}

async function getUser(): Promise<IUser> {
  return requestMaker({
    type: 'GET',
    route: endpoints.GET_USER,
    isSecure: true
  })
}

async function updatePassword(data: IUpdatePassword): Promise<string> {
  return requestMaker({
    type: 'PUT',
    route: endpoints.UPDATE_PASSWORD,
    isSecure: true,
    data
  })
}

async function verifyPhone(data: IVerifyPhone): Promise<IVerifyPhoneResponse> {
  return requestMaker({
    type: 'PUT',
    route: endpoints.VERIFY_PHONE,
    isSecure: true,
    data
  })
}

async function verifyOtp(data: IVerifyOtp): Promise<IVerifyOTPResponse> {
  return requestMaker({
    type: 'POST',
    route: endpoints.VERIFY_OTP,
    isSecure: true,
    data
  })
}

async function updateEmail(data: IUpdateEmail): Promise<any> {
  return requestMaker({
    type: 'PUT',
    route: endpoints.UPDATE_EMAIL,
    isSecure: true,
    data
  })
}

async function verifyEmail(data: IVerifyEmail): Promise<any> {
  return requestMaker({
    type: 'GET',
    route: endpoints.VERIFY_EMAIL(data.token, data.email),
    isSecure: true,
    data
  })
}

async function getAutions(status: EStatus): Promise<IAuctionResponse> {
  return requestMaker({
    type: 'GET',
    route: endpoints.AUCTION(status),
    isSecure: true
  })
}

async function getActiveAutions(query: { assetType: string }): Promise<IAuctionResponse> {
  return requestMaker({
    type: 'GET',
    route: endpoints.ACTIVE_AUCTION,
    isSecure: true,
    queryParams: query
  })
}

async function getFundedAutions(query: { assetType: string }): Promise<IAuctionResponse> {
  return requestMaker({
    type: 'GET',
    route: endpoints.FUNDED_AUCTION,
    isSecure: true,
    queryParams: query
  })
}

async function getAnAutions(id: string): Promise<ISingleAuctionResponse> {
  return requestMaker({
    type: 'GET',
    route: endpoints.SINGLE_AUCTION(id),
    isSecure: true
  })
}

async function makeAbid(data: IBidData, id: string): Promise<IBidResponse> {
  return requestMaker({
    type: 'POST',
    route: endpoints.MAKE_A_BID(id),
    isSecure: true,
    data
  })
}

async function getAllTransactions(
  query: IGetTransactions
): Promise<ITransactionsResponse> {
  return requestMaker({
    type: 'GET',
    route: endpoints.TRANSACTIONS(query),
    isSecure: true
  })
}

async function getBids(): Promise<BidResponse> {
  return requestMaker({
    type: 'GET',
    route: endpoints.BIDS,
    isSecure: true
  })
}

async function getInvestments(): Promise<IInvestmentResponse> {
  return requestMaker({
    type: 'GET',
    route: endpoints.INVESTMENTS,
    isSecure: true
  })
}

async function saveAuction(id: string): Promise<ISaveAuctionResponse> {
  return requestMaker({
    type: 'POST',
    route: endpoints.SAVE_AUCTION(id),
    isSecure: true
  })
}

async function unSaveAuction(id: string): Promise<INetworkResponse> {
  return requestMaker({
    type: 'DELETE',
    route: endpoints.UNSAVE_AUCTION(id),
    isSecure: true
  })
}

async function savedAuction(status: EStatus): Promise<IAuctionResponse> {
  return requestMaker({
    type: 'GET',
    route: endpoints.SAVED_AUCTION(),
    isSecure: true,
    queryParams: { status, assetType: 'Property' }

  })
}

async function getProfile(): Promise<IGetProfileResponseData> {
  return requestMaker({
    type: 'GET',
    route: endpoints.PROFILE,
    isSecure: true
  })
}

async function updateProfile(
  data: IUpdateProfile
): Promise<IGetProfileResponseData> {
  return requestMaker({
    type: 'PUT',
    route: endpoints.PROFILE,
    isSecure: true,
    data
  })
}

async function createVerificationSession(
  data: IKycSessionData
): Promise<IKycSessionResponse> {
  return requestMaker({
    type: 'POST',
    route: endpoints.CREATE_VERIFICATION_SESSION,
    isSecure: true,
    data
  })
}

async function settings(token?: string): Promise<ISettingsResponse> {
  return requestMaker({
    type: 'GET',
    route: endpoints.SETTINGS,
    isSecure: true,
    ...(token ? { headers: { Authorization: token } } : {})
  })
}

async function getWallet(): Promise<IWalletResponse> {
  return requestMaker({
    type: 'GET',
    route: endpoints.WALLETS,
    isSecure: true
  })
}

async function cardDeposit(
  data: ICardDepositData
): Promise<ICardDepositResponse> {
  return requestMaker({
    type: 'POST',
    route: endpoints.CARD_DEPOSIT,
    isSecure: true,
    data
  })
}

async function verifyCardDeposit(
  reference: string
): Promise<IVerifyCardTransaction> {
  return requestMaker({
    type: 'GET',
    route: endpoints.VERIFY_CARD_DEPOSIT(reference),
    isSecure: true
  })
}

async function getBeneficiaries(): Promise<IBeneficiaryResponse> {
  return requestMaker({
    type: 'GET',
    route: endpoints.BENEFICIARY,
    isSecure: true
  })
}

async function updateBeneficiary(
  reference: string,
  data: Partial<ICreateBeneficiaryData>
): Promise<any> {
  return requestMaker({
    type: 'PATCH',
    route: endpoints.BENEFICIARY + '/' + reference,
    data,
    isSecure: true
  })
}

async function deleteBeneficiary(reference: string): Promise<any> {
  return requestMaker({
    type: 'DELETE',
    route: endpoints.BENEFICIARY + '/' + reference,
    isSecure: true
  })
}

async function createBeneficiaries(
  data: ICreateBeneficiaryData
): Promise<ICreateBeneficiayResponse> {
  return requestMaker({
    type: 'POST',
    route: endpoints.BENEFICIARY,
    isSecure: true,
    data
  })
}

async function wireDeposit(data: IWireDepositData): Promise<any> {
  return requestMaker({
    type: 'POST',
    route: endpoints.WIRE_DEPOSIT,
    isSecure: true,
    data
  })
}

async function wireWithdraw(data: IWireWithdrawalData): Promise<any> {
  return requestMaker({
    type: 'POST',
    route: endpoints.WIRE_WITHDRAWAL,
    isSecure: true,
    data
  })
}

async function kycDecision(sessionId: string): Promise<any> {
  return requestMaker({
    type: 'GET',
    route: endpoints.KYC_DECISION(sessionId),
    isSecure: true
  })
}

async function convertFiatCurrency(
  from: string,
  to: string,
  amount: number
): Promise<IConvertCurrencyResponse> {
  return requestMaker({
    type: 'GET',
    route: endpoints.CONVERT_FIAT_CURRENCY(from, to, amount),
    isSecure: true
  })
}

async function getCryptoRate(
  from: string,
  to: string
): Promise<ICryptoRateResponse> {
  return requestMaker({
    type: 'GET',
    route: endpoints.GET_CRYPTO_RATE(from, to),
    isSecure: true
  })
}

async function convertAnyCurrency(
  from: string,
  to: string,
  amount: number
): Promise<IConvertAnyCurrencyResponse> {
  return requestMaker({
    type: 'GET',
    route: endpoints.CONVERT_ANY(from, to, amount),
    isSecure: true
  })
}

export default {
  requestMetaMask,
  loginMetaMask,
  loginGoogle,
  emailReg,
  login,
  logout,
  requestPasswordReset,
  validatePasswordReset,
  resetPassword,
  getUser,
  updatePassword,
  verifyPhone,
  verifyOtp,
  updateEmail,
  verifyEmail,
  getAutions,
  getActiveAutions,
  getFundedAutions,
  getAnAutions,
  makeAbid,
  getAllTransactions,
  getBids,
  getInvestments,
  saveAuction,
  unSaveAuction,
  savedAuction,
  getProfile,
  updateProfile,
  createVerificationSession,
  settings,
  getWallet,
  cardDeposit,
  verifyCardDeposit,
  getBeneficiaries,
  createBeneficiaries,
  updateBeneficiary,
  deleteBeneficiary,
  wireDeposit,
  kycDecision,
  convertFiatCurrency,
  getCryptoRate,
  convertAnyCurrency,
  wireWithdraw
}
