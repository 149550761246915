import { ethers } from 'ethers'
import erc20Abi from '../abi/erc20.json'
import networks from '../constants/network'

class CryptoService {
  ethereum: any
  provider: any
  signer!: any
  defaultNetwork: string | undefined
  networkObject: any

  constructor() {
    this.defaultNetwork = process.env.REACT_APP_DEFAULT_NETWORK

    this.networkObject = networks[this.defaultNetwork as keyof typeof networks]

    this.ethereum = (window as any).ethereum

    this.provider = new ethers.providers.JsonRpcProvider(this.networkObject.rpc)
  }

  async getEthBalance(address: string): Promise<any> {
    let balance: any = await this.provider.getBalance(address)
    balance = ethers.utils.formatEther(balance)
    return balance
  }

  async getNetwork(): Promise<typeof networks[keyof typeof networks]> {
    const chainName = this.networkObject.chainName
    const networkObjectData = networks[chainName as keyof typeof networks]

    // this.provider.on('network', (newNetwork, oldNetwork) => {
    // 	if (oldNetwork) {
    // 		window.location.reload()
    // 	}
    // })
    return networkObjectData
  }

  async getUsdcInfo(address?: string): Promise<{
    usdcAddress: string
    usdcContract: ethers.Contract
    usdcBalance: any
  }> {
    const usdcAddress = this.networkObject.currencies.usdc.contractAddress
    // if (address) console.log(await this.provider.getCode(address))
    this.signer = this.provider.getSigner(address)
    const usdcContract = new ethers.Contract(usdcAddress, erc20Abi, this.signer)
    let usdcBalance = await usdcContract.balanceOf(address)
    usdcBalance = ethers.utils.formatEther(usdcBalance)

    return {
      usdcAddress,
      usdcContract,
      usdcBalance,
    }
  }

  async getUsdtInfo(address?: string): Promise<{
    usdtAddress: string
    usdtContract: ethers.Contract
    usdtBalance: any
  }> {
    const usdtAddress = this.networkObject.currencies.usdt.contractAddress
    // if (address) console.log(await this.provider.getCode(address))

    this.signer = this.provider.getSigner(address)
    const usdtContract = new ethers.Contract(usdtAddress, erc20Abi, this.signer)
    let usdtBalance = await usdtContract.balanceOf(address)
    usdtBalance = ethers.utils.formatEther(usdtBalance)

    return {
      usdtAddress,
      usdtContract,
      usdtBalance,
    }
  }
}

export default new CryptoService()
