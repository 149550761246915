import React, { FC } from 'react'
import './styles.scss'

const PageTitle: FC<{ title: string }> = ({ title }) => {
  return (
    <div className="Title-header">
      <h1>{title}</h1>
    </div>
  )
}
export default PageTitle
