import React, { useEffect, useState } from 'react'
import SignupButton from '../../components/SignupButton'
import SignupTitleAndDesc from '../../components/SignupTitleAndDesc'
import './styles.scss'
import { useNavigate } from 'react-router-dom'
import Web3 from 'web3'
import { GoogleLogin } from 'react-google-login'
import { gapi } from 'gapi-script'
import Loader from '../../components/Loader'
import { useAuthDispatch } from '../../contexts/authContext'
import chainIds from '../../constants/chainIds'
// import { useGoogleLogin } from '@react-oauth/google'
import toast from 'react-hot-toast'
import { HOME, SIGNIN } from '../../routing/navigation'
import networks from '../../constants/network'
const ethereum = (window as any).ethereum

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

const Getstarted: React.FC = () => {
  const route = useNavigate()
  const { requestMetamaskLogin, metamaskLogin, googleLogin } = useAuthDispatch()

  useEffect(() => {
    ethereum.on('chainChanged', handleChainChanged)

    const initClient = (): void => {
      gapi.client.init({
        clientId,
        scope: '',
      })
    }
    gapi.load('client:auth2', initClient)
  }, [])

  const [loading, setLoading] = useState(false)
  let web3: Web3 | undefined

  const handleLoginWithMetaMask = (): void => {
    LoginWithMetaMask()
  }

  const LoginWithMetaMask = async (): Promise<void> => {
    try {
      if (!ethereum) {
        alert('Install metamask extension!!')
        return
      }
      web3 = new Web3((window as any).ethereum)

      let chainName = chainIds[ethereum.networkVersion]
      if (!chainName) {
        const defaultNetwork = process.env.REACT_APP_DEFAULT_NETWORK
        if (!defaultNetwork) {
          return alert('Unsupported blockchain!')
        }
        const networkObject = networks[defaultNetwork as keyof typeof networks]
        const { chainId } = networkObject
        chainName = networkObject.chainName

        try {
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.toHex(chainId) }],
          })
        } catch (err: any) {
          // This error code indicates that the chain has not been added to MetaMask
          if (err.code === 4902) {
            await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainName,
                  chainId: web3.utils.toHex(chainId),
                  nativeCurrency: {
                    name: networkObject.currencyName,
                    decimals: 18,
                    symbol: networkObject.currencySymbol,
                  },
                  rpcUrls: [networkObject.rpc],
                },
              ],
            })
          }
        }
      }

      const metamaskAddresses = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      if (metamaskAddresses && !metamaskAddresses.length) {
        // display toast here
      }

      setLoading(true)
      const publicAddress = metamaskAddresses[0]
      const { data: loginRequest } = await requestMetamaskLogin({
        publicAddress,
      })

      const signature = await web3.eth.personal.sign(
        loginRequest.loginMessage,
        publicAddress,
        ''
      )
      await metamaskLogin({
        chain: chainName,
        publicAddress,
        signature,
        message: loginRequest.loginMessage,
      })
      toast.success("You've successfully logged in")
      // TODO: handle chain change event
      route(`/${HOME}`)
    } catch (error: unknown) {
      const err = error as Error
      toast.error(err.message)
    }
    setLoading(false)
  }

  // const login = useGoogleLogin({
  //   onSuccess: (tokenResponse) => {
  //     loginWithGoogle(tokenResponse.access_token)
  //   },
  //   onError: (error) => {
  //     console.log(error)
  //   },
  //   // flow: 'auth-code',
  //   // ux_mode: 'popup'
  // })

  const loginWithGoogle = async (tokenId: string): Promise<void> => {
    setLoading(true)
    try {
      await googleLogin({ token: tokenId, provider: 'google' })
      route(`/${HOME}`)
    } catch (error) {
      const err = error as Error
      toast.error(err.message || 'Unable to login with google at the moment')
    }
    setLoading(false)
  }
  const onSuccess = (res: any): void => {
    loginWithGoogle(res.tokenId)
  }
  const onFailure = (err: any): void => {
    console.log('failed:', err)
  }

  const handleChainChanged = (): void => window.location.reload()

  return (
    <>
      {loading && <Loader />}
      <>
        <div className="Signup-container">
          <SignupTitleAndDesc
            title="Get Started"
            description="Don’t have a account yet?"
            type="STD1"
            linkName="Sign up"
            link="/signup"
          />

          <div className="d-flex flex-column flex-md-row justify-content-md-between Buttons">
            <div className="mb-4 Button-container">
              {/* <SignupButton
                onClick={() => login()}
                label="Log in with Google"
                icon={{ name: 'google' }}
              /> */}
              <GoogleLogin
                clientId={clientId ?? ''}
                buttonText="Sign in with Google"
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={false}
                className="Google"
              />
            </div>
            <div className="Button-container">
              <SignupButton
                onClick={() => route(`/${SIGNIN}`)}
                label="Log in with Email"
                icon={{ name: 'email' }}
              />
            </div>
          </div>

          <div className="Divider">
            <span>OR</span>
          </div>

          <div className="mt-5">
            <SignupTitleAndDesc
              title="Crypto Users."
              description="Connect a metamask wallet or create one."
              type="STD2"
            />
          </div>

          <div className="d-md-flex justify-content-md-between mb-2">
            <div className="Button-container">
              <SignupButton
                onClick={handleLoginWithMetaMask}
                icon={{ name: 'metamask' }}
              />
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export default Getstarted
