import React from 'react'
import { Icon } from '../Icons'
import { useNavigate } from 'react-router-dom'
import './styles.scss'

export interface BackNavProps {
  label?: string
  color?: string
  className?: string
  link?: string
}
const BackNav: React.FC<BackNavProps> = ({
  label = 'Back',
  className = '',
  link,
}) => {
  const route = useNavigate()
  const onClickButton = (): void => {
    if (link) return route(link)
    route(-1)
  }
  return (
    <button
      onClick={onClickButton}
      className={`btn btn-link Back-nav ${className}`}
    >
      <Icon name="caret-left" color="#2788e2" />
      {label}
    </button>
  )
}

export default BackNav
