/* eslint-disable no-new */
/* eslint-disable comma-dangle */
import React from 'react'
import './styles.scss'
import GoogleMapReact from 'google-map-react'
import LocationPin from '../LocationPin'

interface Imap {
  location: {
    address: string
    lat: number
    lng: number
  }
  zoomLevel: number
}
const Map: React.FC<Imap> = ({ location, zoomLevel }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY ?? ''

  // const initStreetView = (map: any, maps: any): any => {
  //   const sv = new maps.StreetViewService()
  //   const panorama = new maps.StreetViewPanorama(document.getElementById('map'))

  //   function processSVData(data: any, _status: any): any {
  //     new maps.Marker({
  //       position: data.location.latLng,
  //       map,
  //       title: data.location.description,
  //     })
  //     panorama.setPano(data.location.pano)
  //     panorama.setPov({
  //       heading: 270,
  //       pitch: 0,
  //     })
  //     panorama.setVisible(true)
  //   }
  //   sv.getPanorama(
  //     { location: { lat: 37.869, lng: -122.2547 }, radius: 50 },
  //     processSVData
  //   )
  // }
  // const handleApiLoaded = (map: any, maps: any): void => {
  //   // use map and maps objects
  //   console.log(map, maps)
  //   initStreetView(map, maps)
  // }

  return (
    <div className="Map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        defaultCenter={{ lat: location.lat, lng: location.lng }}
        defaultZoom={zoomLevel}
        // yesIWantToUseGoogleMapApiInternals
        // onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        <LocationPin
          lat={location.lat}
          lng={location.lng}
          text={location.address}
        />
      </GoogleMapReact>
    </div>
  )
}

export default Map
