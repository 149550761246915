import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useState,
  useEffect,
} from 'react'
import Button from '../../../../components/Button'
import { Icon } from '../../../../components/Icons'
import Modal, { IModal } from '../../../../components/Modal'
import PayOptionButton from '../../../../components/PayOptionButton'
import TextInputGroup from '../../../../components/TextInputGroup'
import { v4 as uuidv4 } from 'uuid'
import './styles.scss'
import TextInput from '../../../../components/TextInput'
import QRCode from 'qrcode'
import { toast } from '../../../../components/Toast'

interface ModalProps {
  toggle: () => void
  address: string
}
const CryptoDepositModal: ForwardRefRenderFunction<IModal, ModalProps> = (
  { ...props },
  ref
) => {
  const [step, setStep] = useState(1)
  const [payMode, setPayMode] = useState<'card' | 'wire' | 'connectBank'>(
    'card'
  )
  const [newBank, setNewBank] = useState(false)
  const [bankAdded, setBankAdded] = useState(false)
  const [method, setMethod] = useState<'fiat' | 'wallet'>()
  const [addressQr, setAddressQr] = useState<string>()

  const style = { borderRadius: 3, borderColor: '#c4c4c4' }

  useEffect(() => {
    generateQR()
  }, [props.address])

  const nextStep = (): void => {
    if (step === 5) {
      setStep(1)
      return props.toggle()
    }
    setStep((prev) => prev + 1)
    generateQR()
  }
  const prevStep = (): void => {
    setStep((prev) => {
      if (prev === 1) {
        props.toggle()
        return prev
      }
      return prev - 1
    })
  }

  const generateQR = async (): Promise<void> => {
    try {
      const qr = await QRCode.toDataURL(props.address)
      setAddressQr(qr)
    } catch (err) {
      console.error(err)
    }
  }
  const copyAddress = (): void => {
    navigator.clipboard.writeText(props.address)
    toast.success('Copied to clipboard')
  }

  const DepositMethod = (): JSX.Element => (
    <>
      <div className="Modal-header">
        <div className="d-flex justify-content-between">
          <Icon name="back-arrow" onClick={prevStep} />
          <Icon name="close" onClick={props.toggle} />
        </div>
        <h3 className="text-center">Deposit Method </h3>
        <p className="text-center">
          Please select how you would like to send money to your wallet
        </p>
      </div>
      <div className="ms-5 me-5">
        <div
          className="Deposit-options d-flex align-items-center p-3"
          onClick={() => {
            setMethod(() => 'wallet')
            nextStep()
          }}
        >
          <div className="Icon-container me-3">
            <Icon name="white-bank" />
          </div>
          <p>Wallet address</p>
        </div>
      </div>

      <div className="ms-5 me-5">
        <div
          className="Deposit-options d-flex align-items-center p-3"
          onClick={() => {
            // setMethod(() => 'fiat')
            // nextStep()
          }}
        >
          <div className="Icon-container me-3">
            <Icon name="wallet" />
          </div>
          <p>Fiat Transfer</p>
          <div className="Coming">
            <p>Coming soon</p>
          </div>
        </div>
      </div>
    </>
  )

  const DepositAddress = (): JSX.Element => (
    <>
      <div className="Modal-header">
        <div className="d-flex justify-content-between">
          <Icon name="back-arrow" onClick={prevStep} />
          <Icon name="close" onClick={props.toggle} />
        </div>
        <h3 className="text-center">Deposit Address </h3>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="mt-3 mb-2">
          <img src={addressQr} alt="qr-code" />
        </div>
      </div>
      <div style={{ fontSize: 12, color: '#616D7B' }}>
        <p className="text-center">Scan the address above to deposit</p>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <div
          className="Copy-address d-flex justify-content-between align-items-center"
          style={{ cursor: 'pointer' }}
          onClick={copyAddress}
        >
          <p>{`${props.address.substring(0, 4)}....${props.address.substring(
            props.address.length - 5,
            props.address.length
          )}`}</p>
          <Icon name="copy" />
        </div>
      </div>
    </>
  )

  const EnterAmount = (): JSX.Element => (
    <>
      <div className="Modal-header">
        <div className="d-flex justify-content-between">
          <Icon name="back-arrow" onClick={prevStep} />
          <Icon name="close" onClick={props.toggle} />
        </div>
        <h3 className="text-center">Enter deposit amount </h3>
      </div>
      <form className="p-3 Enter-amount">
        <div className="mb-3">
          <label htmlFor="amount-123">Amount</label>

          <TextInputGroup
            groupType="amount"
            onChange={(name, val) => console.log(name, val)}
            name="amount"
            id="amount-123"
          >
            <div>
              <Icon name="normal-dubai-flag" key={uuidv4()} />
            </div>
          </TextInputGroup>
        </div>
        <div className="mb-2">
          <Button label="Continue" onClick={nextStep} />
        </div>
        <div className="text-center">
          <small>
            By depositing money, you agree to our{' '}
            <span className="text-primary">Terms & Conditions</span>
          </small>
        </div>
      </form>
    </>
  )

  const card = (): JSX.Element => (
    <>
      <div className="row mt-4 mb-3">
        <div className="col-12">
          <TextInput
            label="Card number"
            name="cardNumber"
            placeholder="card number"
            onChange={() => console.log('more love')}
            id={uuidv4()}
            style={style}
          />
        </div>

        <div className="col-md-6">
          <TextInput
            label="Expiry date"
            name="expiryDate"
            placeholder="MM/YY"
            onChange={() => console.log('more love')}
            id={uuidv4()}
            style={style}
          />
        </div>

        <div className="col-md-6">
          <TextInput
            label="CVV"
            name="cvv"
            placeholder="cvv"
            onChange={() => console.log('more love')}
            id={uuidv4()}
            style={style}
          />
        </div>
      </div>
      <Button label="Continue" onClick={nextStep} />
    </>
  )
  const addBank = (): JSX.Element => (
    <div>
      {!bankAdded ? (
        <div className="New-bank mt-4 mb-4 p-3">
          <h6>New Bank Account</h6>
          <div className="row">
            <div className="col-lg-6">
              <TextInput
                label="Account name"
                name="accountName"
                placeholder=" "
                onChange={() => console.log('more love')}
                id={uuidv4()}
                style={style}
              />
            </div>
            <div className="col-lg-6">
              <TextInput
                label="Bank name"
                name="bankName"
                placeholder=" "
                onChange={() => console.log('more love')}
                id={uuidv4()}
                style={style}
              />
            </div>
            <div className="col-lg-6">
              <TextInput
                label="Branch name"
                name="branchName"
                placeholder=" "
                onChange={() => console.log('more love')}
                id={uuidv4()}
                style={style}
              />
            </div>
            <div className="col-lg-6">
              <TextInput
                label="Country"
                name="country"
                placeholder=" "
                onChange={() => console.log('more love')}
                id={uuidv4()}
                style={style}
              />
            </div>
            <div className="col-lg-12">
              <TextInput
                label="Account number"
                name="accountNumber"
                placeholder=" "
                onChange={() => console.log('more love')}
                id={uuidv4()}
                style={style}
              />
            </div>
            <div className="col-lg-12">
              <TextInput
                label="IBAN"
                name="iban"
                placeholder=" "
                onChange={() => console.log('more love')}
                id={uuidv4()}
                style={style}
              />
            </div>
            <div className="col-lg-12">
              <TextInput
                label="Swift Code"
                name="swiftCode"
                placeholder=" "
                onChange={() => console.log('more love')}
                id={uuidv4()}
                style={style}
              />
            </div>
          </div>
          <div className="row mt-2">
            <Button
              label="Add bank account"
              onClick={() => setBankAdded(true)}
            />
          </div>
        </div>
      ) : (
        <div className="Bank-added  mt-4 mb-4 ">
          <div className="p-3 Check d-flex align-items-center">
            <div className="form-check">
              <input
                className="form-check-input me-3"
                type="radio"
                name="checkBank"
                id="checkBank"
              />
              <label
                className="form-check-label d-flex align-items-center"
                htmlFor="checkBank"
              >
                <div className="me-2">
                  <Icon name="bank" />
                </div>
                <div className="me-5">
                  <p>ENBD</p>
                  <p style={{ fontSize: 10 }}>**** **** **** 1</p>
                </div>
                <div>
                  <p>Darshan Rajendra Thakker</p>
                </div>
              </label>
            </div>
          </div>
          <div className="p-3 d-flex align-items-center New-bank-button">
            <Button
              type="link"
              onClick={() => setNewBank(true)}
              label="Add a new bank account"
            />
          </div>
        </div>
      )}
    </div>
  )

  const wire = (): JSX.Element => (
    <>
      {!newBank ? (
        <div className="Wire-start d-flex align-items-center ps-2 pe-2 mt-4 mb-5">
          <Button
            type="link"
            onClick={() => setNewBank(true)}
            label="Add a new bank account"
          />
        </div>
      ) : (
        addBank()
      )}
      <Button label="Continue" onClick={nextStep} />
    </>
  )
  const SelectPaymentMode = (): JSX.Element => (
    <>
      <div className="Modal-header">
        <Icon name="back-arrow" onClick={prevStep} />
        <h3 className="text-center">Select payment mode </h3>
      </div>
      <div className="Payment-mode p-3">
        <small>Payment methods</small>
        <div className="Pay-buttons mt-3 pb-2 d-flex flex-wrap justify-content-between">
          <div className="mb-3">
            <PayOptionButton
              icon={{ name: 'cards-small' }}
              label="Debit card"
              onClick={() => setPayMode('card')}
            />
          </div>

          <div className="mb-3">
            <PayOptionButton
              icon={{ name: 'bank' }}
              label="Wire transfer"
              onClick={() => setPayMode('wire')}
            />
          </div>
          <div className="mb-3">
            <PayOptionButton
              // icon={{ name: 'bank' }}
              label="Connect Bank Account"
              onClick={() => setPayMode('connectBank')}
            />
          </div>
        </div>
        <div>
          {payMode === 'card' ? card() : payMode === 'wire' ? wire() : null}
        </div>
      </div>
    </>
  )

  const TotalDeposit = (): JSX.Element => (
    <>
      <div className="Modal-header">
        <Icon name="back-arrow" onClick={prevStep} />
        <h3 className="text-center">Total Deposit Amount </h3>
      </div>
      <div className="Total-deposit p-3">
        <div className="Total-deposit-container d-flex justify-content-between align-items-center mb-4 ms-3 me-3">
          <div>
            <small>Deposit amount </small>
          </div>
          <div>
            <p className="mb-0">
              AED <strong>100.00</strong>
            </p>
          </div>
        </div>
        <Button label="continue" onClick={() => nextStep()} />
      </div>
    </>
  )

  const TransactionReference = (): JSX.Element => (
    <>
      <div className="Modal-header">
        <Icon name="back-arrow" onClick={prevStep} />
        <h3 className="text-center">Transaction reference</h3>
      </div>
      <div className="Total-deposit p-3">
        <div className="Total-deposit-container d-flex justify-content-between align-items-center mb-4 ms-3 me-3 pe-3 ps-3">
          <div>
            <small>Reference no. </small>
          </div>
          <div>
            <p className="mb-0">FRXUSDT01</p>
          </div>
          <div>
            <Icon name="copy" />{' '}
          </div>
        </div>
        <div className="mt-2 mb-2 text-center">
          <small style={{ color: '#AEAEAE' }}>
            Note: Please do not mention crypto or fracxn while depositing money
            into account instead add the reference number.
          </small>
        </div>
        <Button label="continue" onClick={() => nextStep()} />
      </div>
    </>
  )

  const depositFlow: { [key: number]: JSX.Element } = {
    1: DepositMethod(),
    2: method === 'fiat' ? EnterAmount() : DepositAddress(),
    3: SelectPaymentMode(),
    4: TotalDeposit(),
    5: TransactionReference(),
  }
  return (
    <Modal ref={ref}>
      <div className="Fiat-Deposit">{depositFlow[step]}</div>
    </Modal>
  )
}

export default forwardRef(CryptoDepositModal)
