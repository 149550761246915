import React, { FC, useEffect, useRef, useState } from 'react'
import InnerNav from '../../components/InnerNav'
import './styles.scss'
import { v4 as uuidv4 } from 'uuid'
import PropertyCard from '../../components/PropertyCard'
import { PROPERTIES } from '../../routing/navigation'
import { useNavigate } from 'react-router-dom'
import PageTitle from '../../components/PageTitle'
import Footer from '../../components/Footer'
import { EStatus, IAuctionResponseData } from '../../interface/auction'
import API from '../../network/api'
import { useErrorState } from '../../contexts/errorContext'
import toast from 'react-hot-toast'
import { getDaysDiff } from '../../utils/date'
import Loader from '../../components/Loader'
import Card from '../../components/Card'

const SavedProperties: FC = () => {
  const route = useNavigate()
  const { addError } = useErrorState()

  const isMounted = useRef(false)
  const [auctions, setAuctions] = useState<IAuctionResponseData[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const getSavedAuctions = (status: EStatus): void => {
    setLoading(true)

    API.savedAuction(status)
      .then((autionsResponse) => {
        if (isMounted.current) {
          setLoading(false)
          setAuctions(autionsResponse.data)
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setLoading(false)
        }
        addError(err)
        toast.error(err.message || 'Unable to get properties at the moment')
      })
  }

  useEffect(() => {
    isMounted.current = true
    getSavedAuctions(EStatus.ACTIVE)
    return () => {
      isMounted.current = false
    }
  }, [])

  const onClickFav = (id: string): void => {
    // const confirm = window.confirm(
    //   'Are you sure you want to remove this item from the saved item'
    // )
    // if (confirm) {
    let newAuction = auctions
    newAuction = newAuction.map((auction) => {
      if (auction._id === id) {
        auction.favorite = !auction.favorite
      }
      return auction
    })
    setAuctions(() => newAuction.filter((auction) => auction.favorite))
    // }
  }
  return (
    <>
      {loading && <Loader />}
      <InnerNav>
        <div className="Saved mb-5">
          <PageTitle title="Saved Items" />
          {auctions?.length ? (
            <div className="row">
              {auctions.map((auction) => (
                <div className="col-md-6 col-lg-4" key={uuidv4()}>
                  <PropertyCard
                    onClickFavorite={() => onClickFav(auction._id)}
                    id={auction._id}
                    onClick={() => route(`/${PROPERTIES}/${auction._id}`)}
                    sold={!auction.asset.forSale}
                    favorite={auction.favorite}
                    country={auction.asset.locationInfo.country?.toLowerCase()}
                    images={auction.asset.image.map((img) => img.thumbnail.url)}
                    status={auction.asset.status}
                    summary={auction.asset.type}
                    price={Number(auction.asset?.price)}
                    currency={auction.asset.currency}
                    percentageSold={auction.percentageFunded}
                    projectedReturn={auction.asset.projectedReturn}
                    grossYield={auction.asset.grossYield}
                    daysLeft={getDaysDiff(auction.startTime, auction.endTime)}
                    rentPerMonth={Number(auction.asset.rentPerMonth || 0)}
                    distributionFrequency={auction.asset.distributionFrequency}
                    askBeforeDelete={true}
                  />
                </div>
              ))}
            </div>
          ) : (
            !loading && (
              <Card>
                <div
                  style={{ height: '300px' }}
                  className="card-body d-flex justify-content-center align-items-center"
                >
                  <h4 className="text-secondary">No Saved Property found</h4>
                </div>
              </Card>
            )
          )}
        </div>
      </InnerNav>
      <Footer />
    </>
  )
}

export default SavedProperties
