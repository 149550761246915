import React, { ChangeEvent, CSSProperties, ReactNode, useState } from 'react'
import { Icon, IconProps } from '../Icons'
import { v4 as uuidv4 } from 'uuid'
import './styles.scss'
import { IWalletResponse } from '../../interface/wallet'

export interface TextInputProps {
  placeholder?: string
  id: string
  name: string
  onChange: (name: string, val: string) => void
  type?: string
  isRequired?: boolean
  style?: CSSProperties
  errorText?: string
  valid?: boolean
  defaultValue?: string
  groupType?: 'amount'
  children?: ReactNode
  disableInputDropdown?: boolean
  currentCurrency?: string
  currencies?: IWalletResponse['data']['0']['currency'][] | string[]
  onChangeCurrency?: (currency: string) => void
}

const TextInputGroup: React.FC<TextInputProps> = ({
  placeholder = 'Enter here',
  id,
  name,
  onChange,
  type = 'text',
  isRequired = false,
  valid = true,
  errorText = '',
  style = {},
  defaultValue,
  disableInputDropdown = false,
  currencies = ['USDT', 'USDC'],
  currentCurrency = 'USDT',

  ...props
}) => {
  const [show, setShow] = useState(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange(name, e.target.value)
  }
  const handleCurrencyChange = (currency: string): void => {
    if (props.onChangeCurrency) props.onChangeCurrency(currency)
  }

  const toggle = (): void => {
    if (!disableInputDropdown) setShow((prev) => !prev)
  }

  return (
    <div>
      <div className="mb-4 input-group Input-with-Group">
        {!props.children ? (
          <div onClick={() => toggle()}>
            <button
              className="btn btn-outline-secondary dropdown-toggle"
              type="button"
              disabled={disableInputDropdown}
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Icon name={currentCurrency.toLowerCase() as IconProps['name']} />
              <span className="ms-1 me-1">{currentCurrency}</span>
              <Icon name="small-caret-down" />
            </button>
            <ul className={`dropdown-menu ${show ? 'show' : ''}`}>
              {currencies.map((currency, i) => (
                <div key={uuidv4()}>
                  <li>
                    <button
                      className="btn btn-link dropdown-item"
                      onClick={() =>
                        handleCurrencyChange(
                          typeof currency === 'string'
                            ? currency
                            : currency.code
                        )
                      }
                    >
                      {typeof currency === 'string' ? currency : currency.code}
                    </button>
                  </li>
                  {currencies.length !== i + 1 && (
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                  )}
                </div>
              ))}
            </ul>
          </div>
        ) : (
          <div className="input-group-text">{props.children}</div>
        )}

        <input
          onChange={handleChange}
          type={type}
          className="form-control"
          id={id}
          style={{ ...style, ...(!valid ? { borderColor: '#ff0066' } : {}) }}
          name={name}
          placeholder={placeholder}
          required={isRequired}
          defaultValue={defaultValue}
          disabled={disableInputDropdown}
        />
        <div className={`invalid-feedback ${!valid ? 'd-block' : ''}`}>
          {errorText}
        </div>
      </div>
    </div>
  )
}

export default React.memo(TextInputGroup)
