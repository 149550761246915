/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import BeneficiaryDetailsModal from '../../../../components/BeneficiaryDetailsModal'
import Button from '../../../../components/Button'
import Card from '../../../../components/Card'
import { Icon } from '../../../../components/Icons'
import { IModal } from '../../../../components/Modal'
import CryptoRentalWithdrawal from '../CryptoRentalWithdrawal'
import CryptoSuccessModal from '../../../../components/CryptoSuccessModal'
import WalletModal from '../CryptoDepositModal'
import CryptoSendModal from '../CryptoSendModal'
import { useAuthState } from '../../../../contexts/authContext'
import { Tooltip } from 'bootstrap'
import './styles.scss'
import { toast } from '../../../../components/Toast'
import { ethers } from 'ethers'
import cryptoService from '../../../../service/crypto'
import { setAmount } from '../../../../utils/number'
// const cryptoService = new CryptoService()

const WalletComponent: React.FC = () => {
  const { user } = useAuthState()

  const [showDeposit, setShowCryptoDeposit] = useState(false)
  const [showCryptoWithdrawal, setShowCryptoWithdrawal] = useState(false)
  const [showSendModal, setShowSendModal] = useState(false)
  const [showBeneficiary, setShowBeneficiary] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [successCurrency, setSuccessCurrency] = useState('')
  const [successAmount, setSuccessAmount] = useState('')
  const [copyText, setCopyText] = useState('Copy to clip board')
  const [address, setAddress] = useState('')
  const [ethBalance, setEthBalance] = useState<string>()
  const [usdcBalance, setusdcBalance] = useState<string>()
  const [usdtBalance, setusdtBalance] = useState<string>()
  const [USDCContract, setUSDCContract] = useState<ethers.Contract>()
  const [USDTContract, setUSDTContract] = useState<ethers.Contract>()
  const [providerSigner, setProviderSigner] =
    useState<ethers.providers.JsonRpcSigner>()
  const [senderCurrency, setSenderCurrency] = useState('')

  const ModalRef = useRef<IModal>(null)
  const CryptoWithdrawalModalRef = useRef<IModal>(null)
  const BeneficiaryModalRef = useRef<IModal>(null)
  const CryptoSendModalRef = useRef<IModal>(null)
  const CryptoSuccessModalRef = useRef<IModal>(null)

  const tooltipRef = useRef(null)
  const tooltipInfoRef = useRef(null)

  useEffect(() => {
    ;(async function () {
      if (USDCContract) {
        const filter = USDCContract.filters.Transfer(null, address)
        const filterFrom = USDCContract.filters.Transfer(address, null)
        // TEMP, remove this and take it to a global component
        USDCContract.on(filter, (from, to, amount, event) => {
          // The to will always be "address"
          console.log(`I got ${ethers.utils.formatEther(amount)} from ${from}.`)
          updateBalance()
          setShowSuccessModal(true)
          setSuccessMessage(
            `The transfer of ${amount} has been made to your wallet address from ${from}`
          )
          setSuccessCurrency('USDC')
          setSuccessAmount(ethers.utils.formatEther(amount))
        })
        USDCContract.on(filterFrom, (from, to, amount, event) => {
          // The to will always be "address"
          console.log(
            `I transferred ${ethers.utils.formatEther(amount)} to ${to}.`
          )
          updateBalance()
          setShowSuccessModal(true)
          setSuccessMessage(
            `The transfer of ${ethers.utils.formatEther(
              amount
            )} has been made out of your wallet address to ${to}`
          )
          setSuccessCurrency('USDC')
          setSuccessAmount(ethers.utils.formatEther(amount))
        })
      }

      if (USDTContract) {
        const filter = USDTContract.filters.Transfer(null, address)
        const filterFrom = USDTContract.filters.Transfer(address, null)

        // TEMP, remove this and take it to a global component
        USDTContract.on(filter, (from, to, amount, event) => {
          // The to will always be "address"
          console.log(`I got ${ethers.utils.formatEther(amount)} from ${from}.`)
          updateBalance()
          setShowSuccessModal(true)
          setSuccessMessage('The transfer has been made to your wallet address')
          setSuccessCurrency('USDC')
          setSuccessAmount(ethers.utils.formatEther(amount))
        })
        USDTContract.on(filterFrom, (from, to, amount, event) => {
          // The to will always be "address"
          console.log(
            `I transferred ${ethers.utils.formatEther(amount)} to ${to}.`
          )
          updateBalance()
          setShowSuccessModal(true)
          setSuccessMessage(
            'The transfer has been made out of your wallet address'
          )
          setSuccessCurrency('USDT')
          setSuccessAmount(ethers.utils.formatEther(amount))
        })
      }
    })()

    // return () => {
    // 	if (showSuccessModal) {
    // 		toggleCryptoSuccess()
    // 	}
    // }
  }, [USDCContract, USDTContract])

  const updateBalance = async (): Promise<void> => {
    if (USDCContract) {
      let USDCbalance = await USDCContract.balanceOf(address)
      USDCbalance = ethers.utils.formatEther(USDCbalance)
      setusdcBalance(USDCbalance)
    }

    if (USDTContract) {
      let USDTbalance = await USDTContract.balanceOf(address)
      USDTbalance = ethers.utils.formatEther(USDTbalance)
      setusdtBalance(USDTbalance)
    }
  }

  useEffect(() => {
    const tooltip = new Tooltip(tooltipRef.current ?? '', {
      title: copyText,
      placement: 'top',
      trigger: 'hover',
    })

    const tooltip2 = new Tooltip(tooltipInfoRef.current ?? '', {
      title:
        'Fracxn currently only supports Polygon (MATIC) transactions and sending funds via any other network will result in loss of funds.',
      placement: 'right',
      trigger: 'hover',
    })
    getAddress()
  }, [])

  useEffect(() => {
    if (showDeposit) ModalRef.current?.toggle()
  }, [showDeposit])

  useEffect(() => {
    if (showCryptoWithdrawal) CryptoWithdrawalModalRef.current?.toggle()
  }, [showCryptoWithdrawal])

  useEffect(() => {
    if (showBeneficiary) BeneficiaryModalRef.current?.toggle()
  }, [showBeneficiary])

  useEffect(() => {
    if (showSendModal) CryptoSendModalRef.current?.toggle()
  }, [showSendModal])

  useEffect(() => {
    if (showSuccessModal) CryptoSuccessModalRef.current?.toggle()
  }, [showSuccessModal])

  const getAddress = async (): Promise<void> => {
    try {
      const publicAddress = user.publicAddress
      if (!publicAddress) {
        return
      }
      setAddress(publicAddress)
      const signer = cryptoService.provider.getSigner(publicAddress)
      setProviderSigner(signer)
      const [usdcInfo, usdtInfo] = await Promise.all([
        cryptoService.getUsdcInfo(publicAddress),
        cryptoService.getUsdtInfo(publicAddress),
      ])

      const usdcContract = usdcInfo.usdcContract
      const usdcBalance = usdcInfo.usdcBalance

      const usdtContract = usdtInfo.usdtContract
      const usdtbalance = usdtInfo.usdtBalance
      setusdcBalance(usdcBalance)
      setusdtBalance(usdtbalance)
      setUSDCContract(usdcContract)
      setUSDTContract(usdtContract)
    } catch (error: any) {
      toast.error(error.message || 'Unable to load balance(s)')
    }
  }

  const toggleDeposit = (): void => {
    ModalRef.current?.toggle()
    setShowCryptoDeposit(false)
  }

  const toggleCryptoWithDrawal = (): void => {
    CryptoWithdrawalModalRef.current?.toggle()
    setShowCryptoWithdrawal(false)
  }

  const toggleCryptoSendModal = (): void => {
    CryptoSendModalRef.current?.toggle()
    setShowSendModal(false)
  }

  const toggleBeneficiary = (): void => {
    BeneficiaryModalRef.current?.toggle()

    setShowBeneficiary(false)
  }

  const toggleCryptoSuccess = (): void => {
    CryptoSuccessModalRef.current?.toggle()

    setShowSuccessModal(false)
  }

  const copyAddress = (): void => {
    navigator.clipboard.writeText(address)
    toast.success('Copied to clipboard')
  }

  return (
    <>
      <div className="Wallet-component">
        <div className="row Rental-wallet mb-5">
          <div className="col-lg-6 mb-5 mb-lg-0">
            <Card>
              <div className="row">
                <div className="col-12">
                  <div className="Wallet-header mb-4">
                    <div className="row">
                      <div className="col-12">
                        <h6>Crypto Wallet Address</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row Crypto-address">
                <div className="col-12">
                  <div className="Crypto-address-Container d-flex justify-content-between align-items-center p-3 ps-4">
                    <div className="me-2">
                      <Icon name="ETH" />
                    </div>
                    <p className="mb-0 me-2">{address}</p>
                    <button
                      type="button"
                      className="btn btn-link"
                      style={{ cursor: 'pointer' }}
                      ref={tooltipRef}
                      onClick={copyAddress}
                    >
                      <Icon name="copy" />
                    </button>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="Network mt-3">
                        Network: Polygon (MATIC){' '}
                        <span className="ms-2">
                          <Icon name="polygon" />
                        </span>
                      </p>
                    </div>
                    <button
                      className="mt-3 me-4 btn btn-link"
                      ref={tooltipInfoRef}
                    >
                      <Icon name="info" />
                    </button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="Crypto-list">
          <div className="row">
            <div className="col-12 col-lg-7">
              <div className="row mt-2 mb-5">
                <div className="col-lg-4 mb-2">
                  <div className="Crypto-list-container p-3">
                    <div className="text-center">
                      <Icon name="coin-icon" />
                      <p className="mt-3 mb-4">
                        {setAmount(Number(usdcBalance ?? 0)) ?? 'loading'}{' '}
                        <span style={{ fontSize: '12px' }}>USDC</span>
                      </p>
                    </div>

                    <div className="d-flex justify-content-center">
                      <div className="me-2">
                        <div
                          className="Action-container"
                          onClick={() => {
                            if (user?.kycLevel < 2) {
                              document.getElementById('kyc-button')?.click()

                              return
                            }
                            // setShowFiatWithDrawal(false)
                            setShowCryptoDeposit(true)
                            setShowCryptoWithdrawal(false)
                            setShowSendModal(false)
                          }}
                        >
                          <Icon name="deposit" />
                        </div>
                        <small className="text-primary">Deposit</small>
                      </div>
                      <div className="me-2">
                        <div
                          className="Action-container"
                          onClick={() => {
                            if (user?.kycLevel < 2) {
                              document.getElementById('kyc-button')?.click()

                              return
                            }
                            // setShowFiatWithDrawal(false)
                            setShowCryptoDeposit(false)
                            setShowCryptoWithdrawal(false)
                            setShowSendModal(true)
                            setSenderCurrency('USDC')
                          }}
                        >
                          <Icon name="send" />
                        </div>
                        <small className="text-primary">Send</small>
                      </div>
                      <div className="me-2">
                        <div
                          className="Action-container"
                          onClick={() => {
                            if (user?.kycLevel < 2) {
                              document.getElementById('kyc-button')?.click()

                              return
                            }
                            // setShowFiatWithDrawal(false)
                            setShowCryptoDeposit(false)
                            setShowCryptoWithdrawal(false)
                            setShowSendModal(true)
                            setSenderCurrency('USDC')
                          }}
                        >
                          <Icon name="withdraw" />
                        </div>
                        <small className="text-primary">Withdraw</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-2">
                  <div className="Crypto-list-container p-3">
                    <div className="text-center">
                      <Icon name="coin-icon" />
                      <p className="mt-3 mb-4">
                        {setAmount(Number(usdtBalance ?? 0)) ?? 'loading'}{' '}
                        <span style={{ fontSize: '12px' }}>USDT</span>
                      </p>
                    </div>

                    <div className="d-flex justify-content-center">
                      <div className="me-2">
                        <div
                          className="Action-container"
                          onClick={() => {
                            if (user?.kycLevel < 2) {
                              document.getElementById('kyc-button')?.click()

                              return
                            }
                            // setShowFiatWithDrawal(false)
                            setShowCryptoDeposit(true)
                            setShowCryptoWithdrawal(false)
                            setShowSendModal(false)
                          }}
                        >
                          <Icon name="deposit" />
                        </div>
                        <small className="text-primary">Deposit</small>
                      </div>
                      <div className="me-2">
                        <div
                          className="Action-container"
                          onClick={() => {
                            if (user?.kycLevel < 2) {
                              document.getElementById('kyc-button')?.click()

                              return
                            }
                            // setShowFiatWithDrawal(false)
                            setShowCryptoDeposit(false)
                            setShowCryptoWithdrawal(false)
                            setShowSendModal(true)
                            setSenderCurrency('USDT')
                          }}
                        >
                          <Icon name="send" />
                        </div>
                        <small className="text-primary">Send</small>
                      </div>
                      <div className="me-2">
                        <div
                          className="Action-container"
                          onClick={() => {
                            if (user?.kycLevel < 2) {
                              document.getElementById('kyc-button')?.click()

                              return
                            }
                            // setShowFiatWithDrawal(false)
                            setShowCryptoDeposit(false)
                            setShowCryptoWithdrawal(false)
                            setShowSendModal(true)
                            setSenderCurrency('USDT')
                          }}
                        >
                          <Icon name="withdraw" />
                        </div>
                        <small className="text-primary">Withdraw</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row Rental-wallet">
          <div className="col-lg-6 mb-5 mb-lg-0">
            <Card>
              <div className="row">
                <div className="col-12">
                  <div className="Wallet-header mb-4">
                    <div className="row">
                      <div className="col-6">
                        <h6>Rental Wallet </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-4 pb-4">
                <div className="col-lg-6 Balance d-flex align-items-end ">
                  <div>
                    <h4 className="text-primary mb-3">
                      USD <span className="h4 text-dark ms-4">0</span>
                    </h4>
                    <p className="mb-0">Available Balance</p>
                  </div>
                </div>
                <div className="col-lg-6 d-flex  align-items-end">
                  {/* <div>
                    <Button
                      label="Withdraw"
                      size="small"
                      onClick={() => {
                        // setShowFiatWithDrawal(false)
                        setShowCryptoDeposit(false)
                        setShowCryptoWithdrawal(true)
                        setShowSendModal(false)
                      }}
                    />
                  </div> */}
                </div>
              </div>
            </Card>
          </div>
        </div>
        {showBeneficiary && (
          <BeneficiaryDetailsModal
            ref={BeneficiaryModalRef}
            toggle={() => toggleBeneficiary()}
          />
        )}
        {showDeposit && (
          <WalletModal
            ref={ModalRef}
            toggle={() => toggleDeposit()}
            address={address}
          />
        )}

        {/* {showFiatWithDrawal && (
          <FiatWithdrawalModal
            ref={FiatWithdrawalModalRef}
            toggle={() => toggleWithDrawal()}
          />
        )} */}

        {showCryptoWithdrawal && (
          <CryptoRentalWithdrawal
            ref={CryptoWithdrawalModalRef}
            toggle={() => toggleCryptoWithDrawal()}
          />
        )}
        {showSuccessModal && (
          <CryptoSuccessModal
            ref={CryptoSuccessModalRef}
            toggle={() => toggleCryptoSuccess()}
            message={successMessage}
            amount={successAmount}
            currency={successCurrency}
          />
        )}
        {showSendModal && (
          <CryptoSendModal
            ref={CryptoSendModalRef}
            toggle={() => toggleCryptoSendModal()}
            currency={senderCurrency}
            usdtContract={USDTContract}
            usdcContract={USDCContract}
            ethBalance={ethBalance}
            usdcBalance={usdcBalance}
            usdtBalance={usdtBalance}
            signer={providerSigner}
          />
        )}
      </div>
    </>
  )
}

export default WalletComponent
