import React, { useEffect, useRef, useState } from 'react'
import Button from '../../components/Button'
import Card from '../../components/Card'
import { Icon, IconName } from '../../components/Icons'
import './styles.scss'
import PaymentOptionCard from '../../components/PaymentOptionCard'
import { v4 as uuidv4 } from 'uuid'
import PropertyCard from '../../components/PropertyCard'
import { PROPERTIES } from '../../routing/navigation'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from '../../components/Footer'
import API from '../../network/api'
import { IAuctionResponseData } from '../../interface/auction'
import { useErrorState } from '../../contexts/errorContext'
import { toast } from '../../components/Toast'
import Loader from '../../components/Loader'
import moment from 'moment'
import { getDaysDiff } from '../../utils/date'
import Map from '../../components/Map'
import { IError } from '../../interface/error'
import PropertyPhotoList from '../../components/PropertyPhotoList'

const PropertyDetails: React.FC = () => {
  const route = useNavigate()
  const { id } = useParams<{ id: string }>()
  const { addError } = useErrorState()

  const [loading, setLoading] = useState(true)
  const [auction, setAuction] = useState<IAuctionResponseData>()
  const [similarAuctions, setSimilarAuctions] = useState<any[]>()
  const isMounted = useRef(false)
  const scrollRef = useRef<HTMLDivElement>(null)

  const getAuction = (): void => {
    setLoading(true)
    if (id) {
      API.getAnAutions(id)
        .then((autionsResponse) => {
          if (isMounted.current) {
            setLoading(false)
            setAuction(autionsResponse.data)
            setSimilarAuctions(autionsResponse.data.relatedAuctions)
          }
        })
        .catch((err) => {
          if (isMounted.current) {
            setLoading(false)
          }
          addError(err)
          toast.error(err.message || 'Unable to get properties at the moment')
        })
    }
  }

  useEffect(() => {
    isMounted.current = true
    getAuction()
    window.scrollTo(0, 0)
    return () => {
      isMounted.current = false
    }
  }, [id])

  // const imageLoader = (images: any[]): number => {
  //   const result = Math.floor(Math.random() * images.length)
  //   return result
  // }

  const handleScrollRight = (): void => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 358
    }
  }
  const handleScrollLeft = (): void => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= 358
    }
  }
  const onClickFav = (id: string): void => {
    if (auction?.relatedAuctions) {
      let relatedAuction = auction?.relatedAuctions
      relatedAuction = relatedAuction?.map((auction) => {
        if (auction._id === id) {
          auction.favorite = !auction.favorite
        }
        return auction
      })
      setAuction({ ...auction, relatedAuctions: relatedAuction })
    }
  }

  const handleFavorite = async (): Promise<void> => {
    // e.stopPropagation()
    if (auction) {
      try {
        if (!auction?.favorite) {
          await API.saveAuction(auction?._id)
        } else {
          await API.unSaveAuction(auction?._id)
        }
      } catch (error) {
        const err = error as IError
        toast.error(err.message || 'An error occured, please try later')
        addError(err)
      }
      getAuction()
    }
  }

  const handleFavoriteClick = (): void => {
    handleFavorite()
  }

  return (
    <>
      {loading && <Loader />}

      <div
        className="Property-details pb-5"
        style={!auction ? { height: '100vh' } : {}}
      >
        {auction && (
          <div className="container">
            <PropertyPhotoList images={auction.asset.image} />

            <div className="row">
              <div className="Save-photo col-12 d-flex justify-content-end mt-4">
                <div className="me-4">
                  <Button
                    type="outline"
                    onClick={handleFavoriteClick}
                    size="small"
                    label={
                      <>
                        <Icon
                          name="love"
                          color={auction.favorite ? '#ff0066' : 'tranparent'}
                        />
                        <span className="p ms-2">
                          {auction.favorite ? 'Saved' : 'Save'}
                        </span>
                      </>
                    }
                  />
                </div>
                <div className="ms-4">
                  <Button
                    type="primary"
                    onClick={() =>
                      route(`/${PROPERTIES}/${auction._id}/photos`, {
                        state: { images: auction.asset.image },
                      })
                    }
                    size="small"
                    label="Photos"
                  />
                </div>
              </div>
            </div>
            <section className="Detail-section">
              <h2>
                {auction.asset.title || auction.asset.type}{' '}
                <span
                  className="ps-3"
                  style={{ cursor: 'pointer', fontSize: 14, opacity: 0.7 }}
                  onClick={() => {
                    window.open(
                      `${
                        process.env.REACT_APP_CRYPTO_TX_URL ??
                        'https://mumbai.polygonscan.com'
                      }/address/${auction.asset.contractAddress}`,
                      '_blank'
                    )
                  }}
                >
                  View on Blockchain
                </span>
              </h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  {auction.asset.specification.map((spec) => (
                    <li
                      key={spec._id}
                      className="breadcrumb-item active"
                      aria-current="page"
                    >
                      {spec.value} {spec.name}
                    </li>
                  ))}

                  <li className="breadcrumb-item active" aria-current="page">
                    {auction.asset.area}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {auction.asset.locationInfo.state}
                  </li>
                </ol>
              </nav>

              <div className="row">
                <div className="col-lg-8">
                  <div className="mb-5">
                    <Card>
                      <div className="card-body d-flex">
                        <div className="align-self-center me-4">
                          <Icon name="dubai-large" />
                        </div>
                        <div>
                          <h3>
                            {auction.asset.locationInfo.country},{' '}
                            {auction.asset.locationInfo.state}
                          </h3>
                          <small>
                            A high demand real estate market with great return
                            on investment.
                          </small>
                        </div>
                      </div>
                      {auction.asset.status === 'rent' && (
                        <div className="card-body d-flex">
                          <div className="align-self-center me-4">
                            <Icon name="rented" />
                          </div>
                          <div>
                            <h3>{auction.asset.status}</h3>
                            <small>For know rented and managed by us.</small>
                          </div>
                        </div>
                      )}
                      <div className="card-body d-flex">
                        <div className="align-self-center me-4">
                          <Icon name="coins" />
                        </div>
                        <div>
                          <h3>
                            Current rent is {auction.asset.currency}{' '}
                            {Number(auction.asset.price).toLocaleString()} per
                            month{' '}
                          </h3>
                          <small>
                            Distributed motnly among the investors after
                            deducting the charges and fees
                          </small>
                        </div>
                      </div>
                    </Card>
                  </div>

                  <div className="Prop-details">
                    <h2 className="mb-4">Property Details</h2>
                    <p>{auction.asset.description}</p>
                  </div>

                  <div className="Location">
                    <h2 className="mb-3">Location</h2>
                    <Map
                      location={{
                        lat: auction.asset.locationInfo.latitude,
                        lng: auction.asset.locationInfo.longitude,
                        address: `${auction.asset.locationInfo.street} ${auction.asset.locationInfo.city} ${auction.asset.locationInfo.state} ${auction.asset.locationInfo.country}`,
                      }}
                      zoomLevel={20}
                    />
                    <p>
                      {`${auction.asset.locationInfo.street} ${auction.asset.locationInfo.city} ${auction.asset.locationInfo.state} ${auction.asset.locationInfo.country}`}
                    </p>
                  </div>

                  <div className="Amenities">
                    <h2 className="mb-3">Amenities</h2>
                    <div className="d-flex align-items-center flex-wrap">
                      {auction.asset.amenity.map((amenity) => (
                        <div className="Flex mb-5" key={amenity._id}>
                          <Icon name={amenity.icon as IconName['name']} />
                          <span>{amenity.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {auction.status === 'ACTIVE' && (
                  <div className="col-lg-4">
                    <PaymentOptionCard
                      id={id ?? ''}
                      percentageSold={Number(
                        auction.percentageFunded?.toFixed(2) || 0
                      )}
                      endTime={moment(auction.endTime).format('Do MMM, YYYY')}
                      currency={auction.asset.currency}
                      price={Number(auction.asset.price)}
                      callBack={() => window.location.reload()}
                      totalInvestors={auction.investorsCount}
                    />
                  </div>
                )}
              </div>

              <div className="Similar">
                <h2 className="mb-3">Similar Properties</h2>
                <div className="d-flex Similar-list" ref={scrollRef}>
                  {similarAuctions &&
                    similarAuctions.length >= 1 &&
                    similarAuctions.map((similarAuction) => (
                      <div
                        className="col-12 col-lg-4 ps-2 pe-2"
                        key={uuidv4()}
                        style={{ backgroundColor: '#ffffff' }}
                      >
                        <PropertyCard
                          id={similarAuction._id}
                          onClickFavorite={() => onClickFav(similarAuction._id)}
                          onClick={() =>
                            route(
                              `/${PROPERTIES}/${similarAuction._id as string}`
                            )
                          }
                          sold={!similarAuction.properties[0].forSale}
                          favorite={similarAuction.favorite}
                          country={similarAuction.properties[0].locationInfo.country?.toLowerCase()}
                          images={similarAuction.properties[0].image.map(
                            (img: any) => img.thumbnail.url
                          )}
                          status={similarAuction.properties[0].status}
                          summary={
                            similarAuction.properties[0].title ||
                            similarAuction.properties[0].type
                          }
                          price={Number(similarAuction.properties[0]?.price)}
                          currency={similarAuction.properties[0].currency}
                          percentageSold={
                            (similarAuction.totalBid /
                              Number(similarAuction.properties[0].price)) *
                            100
                          }
                          projectedReturn={
                            similarAuction.properties[0].projectedReturn
                          }
                          grossYield={similarAuction.properties[0].grossYield}
                          daysLeft={getDaysDiff(
                            similarAuction.startTime,
                            similarAuction.endTime
                          )}
                          rentPerMonth={Number(
                            similarAuction.properties[0].rentPerMonth || 0
                          )}
                          distributionFrequency={
                            similarAuction.properties[0].distributionFrequency
                          }
                        />
                      </div>
                    ))}
                  <div className="Less" onClick={handleScrollLeft}>
                    <Button
                      type="link"
                      label={
                        <Icon name="caret-right" rotate={180} color="#ffffff" />
                      }
                    />
                  </div>
                  <div className="More" onClick={handleScrollRight}>
                    <Button
                      type="link"
                      label={<Icon name="caret-right" color="#ffffff" />}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>

      <Footer />
    </>
  )
}

export default PropertyDetails
