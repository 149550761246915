import React, { ChangeEvent, forwardRef, ForwardRefRenderFunction } from 'react'
import './styles.scss'

export interface PhoneInputProps {
  id: string
  name: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  isRequired?: boolean
}

const PhoneInput: ForwardRefRenderFunction<any, PhoneInputProps> = (
  { id, name, onChange, isRequired = false },
  ref
) => {
  return (
    <div className="Phone-input">
      <input
        id={id}
        ref={ref}
        type="tel"
        name={name}
        onChange={onChange}
        className="form-control"
        required={isRequired}
      />
    </div>
  )
}

export default forwardRef(PhoneInput)
