import React, { FormEvent, useEffect, useState } from 'react'
import SignupTitleAndDesc from '../../components/SignupTitleAndDesc'
import './styles.scss'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import { useAuthDispatch } from '../../contexts/authContext'
import { useNavigate } from 'react-router-dom'
import { toast } from '../../components/Toast'
import { validateInput, registrationValidations } from '../../utils/validations'
import { SIGNIN } from '../../routing/navigation'

const Signup: React.FC = () => {
  const route = useNavigate()
  const { emailRegistration } = useAuthDispatch()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ [key: string]: string }>({})
  const [validationResult, setValidationResult] = useState<{
    [key: string]: string
  }>({})
  const [validated, setValidated] = useState(false)

  const validate = (onSubmit = true): boolean => {
    const validationResponse = validateInput({
      data,
      onSubmit,
      validations: registrationValidations,
    })
    setValidationResult((prev) => {
      return { ...prev, ...validationResponse.validationResultData }
    })
    return validationResponse.validated
  }

  useEffect(() => {
    let delayDebounceFn: any = null

    delayDebounceFn = setTimeout(() => {
      const result = validate(false)
      setValidated(result)
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [data])

  const onInputChange = (name: string, value: string): void => {
    setData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      }
    })
  }

  const register = async (): Promise<void> => {
    setLoading(true)

    try {
      if (validate()) {
        await emailRegistration({
          email: data.email,
          password: data.password,
          confirmPassword: data.confirmPassword,
        })

        toast.success('Registration successful')
        route(`/${SIGNIN}`)
      }
    } catch (error) {
      const err = error as Error
      toast.error(err.message)
    }
    setLoading(false)
  }

  const submit = (e: FormEvent): void => {
    e.preventDefault()
    register()
  }
  return (
    <>
      <SignupTitleAndDesc
        title="Sign up"
        description="Already have an account?"
        linkName="Log in"
        type="STD1"
        link="/signin"
      />
      <form onSubmit={submit} className="needs-validation invalid" noValidate>
        <TextInput
          label="Email"
          placeholder="Enter your email address"
          id="email-signup"
          name="email"
          type="email"
          valid={!validationResult.email}
          errorText={validationResult.email}
          onChange={onInputChange}
          isRequired
        />
        <TextInput
          label="Password"
          placeholder="Enter password"
          id="password-signup"
          name="password"
          type="password"
          valid={!validationResult.password}
          errorText={validationResult.password}
          onChange={onInputChange}
          isRequired
        />
        <TextInput
          label="Confirm password"
          placeholder="Enter password"
          id="confirm-password-signup"
          name="confirmPassword"
          type="password"
          valid={!validationResult.confirmPassword}
          errorText={validationResult.confirmPassword}
          onChange={onInputChange}
          isRequired
        />
        <Button
          disabled={!validated}
          loading={loading}
          label="Create account"
        />
      </form>
    </>
  )
}

export default Signup
