import { IWalletResponse } from './wallet'

export interface GlobalAction {
  type: EGlobal
  payload?: any
}

export enum EGlobal {
  GET_SETTINGS = 'GET_SETTINGS',
  GET_WALLET = 'GET_WALLET',
}

export interface IGLobalState {
  settings: {
    wireCredential: {
      beneficiaryName: string
      bankName: string
      iban: string
      accountNumber: string
      currency: string
      swiftCode: string
      _id: string
    } | null
    cryptoWallet: {
      chainName: string
      chainId: string
      address: string
      _id: string
    } | null
    fracxnFees: {
      deposit: number
      withdrawal: number
      bid: number
      _id: string
    } | null
    supportedFiat: Array<{
      name: string
      code: string
      symbol: string
      _id: string
    }> | null
    supportedCrypto: {
      name: string
      code: string
      symbol: string
      _id: string
    } | null
  }
  wallet: IWalletResponse['data'] | null
}
