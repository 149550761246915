import React, { FC, useState, useEffect } from 'react'
import { Carousel } from 'react-responsive-carousel'
import './styles.scss'
import { Icon, IconName } from '../Icons'
import { v4 as uuidv4 } from 'uuid'
import API from '../../network/api'
import { toast } from '../Toast'
import { IError } from '../../interface/error'
import { useErrorState } from '../../contexts/errorContext'

export interface IPropertyProps {
  id: string
  sold: boolean
  favorite?: boolean
  onClickFavorite?: () => void
  country: string
  images: string[]
  summary: string
  price: number
  currency: string
  percentageSold: number
  projectedReturn: string
  grossYield: string
  daysLeft: number
  rentPerMonth: number
  distributionFrequency: string
  status?: string
  onClick: () => void
  askBeforeDelete?: boolean
}

const PropertyCard: FC<IPropertyProps> = ({
  sold,
  favorite = false,
  onClickFavorite,
  askBeforeDelete = false,
  ...props
}) => {
  const { addError } = useErrorState()
  const [fav, setFavorite] = useState(() => favorite)

  useEffect(() => {
    setFavorite(favorite)
  }, [favorite])

  const handleClick = (): void => {
    props.onClick()
  }

  const handleFavorite = async (): Promise<void> => {
    // e.stopPropagation()
    try {
      if (!fav) {
        setFavorite((prev) => !prev)
        if (onClickFavorite) onClickFavorite()
        await API.saveAuction(props.id)
      } else {
        if (askBeforeDelete) {
          const confirm = window.confirm(
            'Are you sure you want to remove this item from the saved item'
          )
          if (confirm) {
            setFavorite((prev) => !prev)
            if (onClickFavorite) onClickFavorite()
            await API.unSaveAuction(props.id)
          }
          return
        }
        setFavorite((prev) => !prev)
        if (onClickFavorite) onClickFavorite()
        await API.unSaveAuction(props.id)
      }
    } catch (error) {
      setFavorite((prev) => !prev)
      const err = error as IError
      toast.error(err.message || 'An error occured, please try later')
      addError(err)
    }
  }

  const handleFavoriteClick = (): void => {
    handleFavorite()
  }

  return (
    <div className="Property-card">
      <div className="card">
        <div className="Country-status">
          <div className="Country d-flex justify-content-around align-items-center">
            <Icon name={props.country as IconName['name']} />
            <p>{props.country}</p>
          </div>
          {!sold && (
            <div className="Status  d-flex justify-content-around align-items-center">
              <Icon name="profile" />
              {props.status && (
                <p>
                  {`${props.status?.charAt(0).toUpperCase()}${props.status
                    ?.substring(1)
                    .toLowerCase()}`}
                </p>
              )}
            </div>
          )}
        </div>
        <>
          {!sold ? (
            <div className="Favorite">
              <div className="Fav-container d-flex justify-content-around align-items-center">
                <Icon
                  name="love"
                  onClick={handleFavoriteClick}
                  color={fav ? '#ff0066' : 'tranparent'}
                />
              </div>
            </div>
          ) : (
            <div className="Sold">
              <div className="Sold-container d-flex justify-content-around align-items-center">
                <p>sold</p>
              </div>
            </div>
          )}
        </>
        <Carousel showThumbs={false} showStatus={false} autoPlay infiniteLoop>
          {props.images.map((image) => (
            <div key={uuidv4()} className="Slide-image">
              <img src={image} />
            </div>
          ))}
        </Carousel>
        <div className="card-body" onClick={handleClick}>
          <p className="Summary">{props.summary}</p>
          <div className="Price-status d-flex justify-content-between ">
            <p className="Price">
              {props.currency.toUpperCase()} {props.price.toLocaleString()}
            </p>
            <div className="progress align-self-end">
              <div
                className="progress-bar"
                role="progressbar"
                aria-label="investment-progress"
                style={{ width: `${props.percentageSold}%` }}
                aria-valuenow={props.percentageSold}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>

            <p className="Sold-status">
              {parseInt(props.percentageSold.toString())}% sold
            </p>
          </div>
          <div className="Info d-flex justify-content-between align-items-center">
            <div>
              <p className="Percentage">{props.projectedReturn}</p>
              <p className="P-label">projected return</p>
            </div>
            <div>
              <p className="Percentage">{props.grossYield}</p>
              <p className="P-label">gross yield</p>
            </div>
            <div>
              <p className="Percentage">{props.daysLeft}</p>
              <p className="P-label">days left</p>
            </div>
          </div>
          <div className="Basic-info d-flex justify-content-between align-items-center">
            <p>
              Rent{' '}
              <strong>
                {props.currency} {props.rentPerMonth.toLocaleString()}/month
              </strong>
            </p>
            <p>
              Distributed{' '}
              <strong>
                {props.distributionFrequency.charAt(0).toUpperCase() +
                  props.distributionFrequency.substring(1).toLowerCase()}
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyCard
