import React, {
  FormEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useState,
} from 'react'
import Button from '../../../../components/Button'
import { Icon } from '../../../../components/Icons'
import Modal, { IModal } from '../../../../components/Modal'
import './styles.scss'
import { useAuthDispatch, useAuthState } from '../../../../contexts/authContext'
import { toast } from '../../../../components/Toast'
import TextInput from '../../../../components/TextInput'
import {
  registrationValidations,
  validateInput,
} from '../../../../utils/validations'
import Otp from '../../../../components/Otp'

interface ModalProps {
  toggle: () => void
  callback?: () => any
}
const UpdateEmailModal: ForwardRefRenderFunction<IModal, ModalProps> = (
  { ...props },
  ref
) => {
  const [step, setStep] = useState(1)
  const { updateEmail } = useAuthDispatch()
  const { user } = useAuthState()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ [key: string]: string }>({})
  const [validated, setValidated] = useState(false)
  const [validationResult, setValidationResult] = useState<{
    [key: string]: string
  }>({})
  const [otpValid, setOtpValid] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [otp, setOtp] = useState('')

  const nextStep = (): void => {
    if (step === 4) {
      setStep(1)
      return props.toggle()
    }
    setStep((prev) => prev + 1)
  }

  const closeModal = (): void => {
    if (props.callback) props.callback()
    props.toggle()
  }

  const validations = {
    email: registrationValidations.email,
  }

  const validate = (onSubmit = true): boolean => {
    const validationResponse = validateInput({
      data,
      onSubmit,
      validations,
    })
    setValidationResult((prev) => {
      return { ...prev, ...validationResponse.validationResultData }
    })
    return validationResponse.validated
  }
  useEffect(() => {
    let delayDebounceFn: any = null

    delayDebounceFn = setTimeout(() => {
      const result = validate(false)
      setValidated(result)
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [data])

  const onInputChange = (name: string, value: string): void => {
    setData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      }
    })
  }

  const handleSubmit = async (): Promise<void> => {
    setLoading(true)

    try {
      await updateEmail({
        email: data.email,
      })
      nextStep()
    } catch (error) {
      const err = error as Error
      toast.error(err.message)
    }
    setLoading(false)
  }

  const submit = (e: FormEvent): void => {
    e.preventDefault()
    handleSubmit()
  }

  const resendCode = async (): Promise<void> => {
    setLoading(true)

    try {
      await updateEmail({
        email: data.email,
      })
    } catch (error) {
      const err = error as Error
      toast.error(err.message)
    }
    setLoading(false)
  }

  const resend = (): void => {
    resendCode()
  }

  const onSubmitOtp = (): void => {
    nextStep()
  }

  const onChange = (value: string): void => {
    if (value.length === 6) {
      setOtp(value)
      setOtpValid(true)
    } else {
      setOtpValid(false)
    }
  }

  const step1 = (): JSX.Element => (
    <div className="UpdatePhoneBody">
      <div className="mb-4 text-center">
        <Icon name="update-email" />
      </div>
      <div className="mb-4 text-center">
        <p style={{ fontWeight: 600 }}>Update your Email</p>
        <small style={{ fontWeight: 500, color: '#979797' }}>
          Enter your new email address to be updated below
        </small>
      </div>

      <form className="mb-4" onSubmit={submit}>
        <div className="mb-5">
          <TextInput
            label="Email address"
            placeholder="Enter new email"
            id="update-email"
            name="email"
            type="text"
            onChange={onInputChange}
            errorText={validationResult.email}
            valid={!validationResult.email}
            defaultValue={user.email || ''}
            isRequired
          />
        </div>
        <div>
          <Button loading={loading} disabled={!validated} label="Send code" />
        </div>
      </form>
    </div>
  )

  const step2 = (): JSX.Element => (
    <div className="UpdatePhoneBody">
      <div className="mb-4 text-center">
        <Icon name="update-email" />
      </div>
      <div className="mb-4 text-center">
        <p style={{ fontWeight: 600 }}>Check your Email</p>
        <small style={{ fontWeight: 500, color: '#979797' }}>
          A verification code has been sent to your new email id
        </small>
      </div>

      <div className="mb-4">
        <div className="OTP-button">
          <Button label="Check Email App" onClick={nextStep} />
        </div>
      </div>
      <div className="mb-4 d-flex justify-content-center align-items-center Resend">
        <small style={{ fontWeight: 500, color: '#979797', marginRight: 10 }}>
          Didn’t receive a code ?{' '}
        </small>
        <Button label="Resend code" type="link" onClick={resend} />
      </div>
    </div>
  )

  const step3 = (): JSX.Element => (
    <div className="UpdatePhoneBody">
      <div className="mb-4 text-center">
        <Icon name="phone" />
      </div>
      <div className="mb-4 text-center">
        <p style={{ fontWeight: 600 }}>Update your email address</p>
        <small style={{ fontWeight: 500, color: '#979797' }}>
          Please enter the OTP sent to your email address below
        </small>
      </div>

      <form className="mb-4">
        <div className="mb-5">
          <Otp onChange={onChange} length={6} otpValid={otpValid} />
        </div>
        <div className="OTP-button">
          <Button
            onClick={onSubmitOtp}
            loading={loading}
            label="Confirm code"
            disabled={!otpValid}
          />
        </div>
      </form>
      <div className="mb-4 d-flex justify-content-center align-items-center Resend">
        <small style={{ fontWeight: 500, color: '#979797', marginRight: 10 }}>
          Didn’t receive a code ?{' '}
        </small>
        <Button label="Resend code" type="link" onClick={resend} />
      </div>
    </div>
  )

  const step4 = (): JSX.Element => (
    <div className="UpdatePhoneBody">
      <div className="mb-4 text-center">
        <Icon name="phone-updated" />
      </div>
      <div className="mb-4 text-center">
        <p style={{ fontWeight: 600 }}>Email address updated</p>
        <small style={{ fontWeight: 500, color: '#979797' }}>
          Your email address has been successfully updated to following{' '}
          {data.email}
        </small>
      </div>

      <div className="mb-4">
        <div>
          <Button onClick={closeModal} label="Back to profile" />
        </div>
      </div>
    </div>
  )

  const UpdatePhoneFlow: { [key: number]: JSX.Element } = {
    1: step1(),
    2: step2(),
    3: step3(),
    4: step4(),
  }
  return (
    <>
      <Modal ref={ref}>
        <div className="UpdatePhone">
          <div className="UpdatePhoneHeader d-flex justify-content-end">
            <Icon
              name="close"
              onClick={() => {
                // setStep(1)
                props.toggle()
              }}
            />
          </div>
          <>{UpdatePhoneFlow[step]}</>
        </div>
      </Modal>
    </>
  )
}

export default forwardRef(UpdateEmailModal)
