import React, { FC } from 'react'

const Loading: FC = () => {
  return (
    <div
      className="spinner-border"
      style={{ width: '1.5rem', height: '1.5rem' }}
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  )
}

export default Loading
