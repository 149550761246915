/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import './styles.scss'
import { NavLink } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { Icon, IconName } from '../Icons'

export interface NavDropdownProps {
  path: string
  toggle: (to: string, linkname: string) => void
  data: any
  allData: any[]
}
const NavDropdown: React.FC<NavDropdownProps> = ({
  path,
  toggle,
  data,
  allData,
}) => {
  const toggleDropdown = (to: string, linkName: string): void => {
    toggle(to, linkName)
  }

  return (
    <div className="Nav-Dropdown">
      <button
        className={`Dropdown-btn ${
          path.includes(data.to) ? 'Dropdown-active' : ''
        }`}
        onClick={() => toggleDropdown(data.to, data.linkName)}
      >
        <Icon
          color={path.includes(data.to) ? '#0b5ca8' : undefined}
          name={data.icon as IconName['name']}
        />{' '}
        <div
          className="d-flex justify-content-between"
          style={{ width: '100%', marginLeft: 12 }}
        >
          <>{data.linkName}</>
          <div className="Caret-down">
            <Icon
              name="caret-down"
              color={path.includes(data.to) ? '#0b5ca8' : undefined}
              rotate={data.active ? 180 : 0}
            />
          </div>
        </div>
      </button>
      {data.active &&
        data.dropdownRoutes.map((route: any) => (
          <div key={uuidv4()} className="Dropdown-container">
            <NavLink
              className={({ isActive }) =>
                isActive ? 'nav-link nav-link-active' : 'nav-link'
              }
              end
              aria-current="page"
              to={route.to}
              state={{ sideNav: allData }}
            >
              {route.linkName}
            </NavLink>
          </div>
        ))}
    </div>
  )
}

export default NavDropdown
