/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import BeneficiaryDetailsModal from '../../../../components/BeneficiaryDetailsModal'
import Button from '../../../../components/Button'
import Card from '../../../../components/Card'
import { IModal } from '../../../../components/Modal'
import WalletModal from '../FiatDepositModal'
import FiatWithdrawalModal from '../FiatWithdrawalModal'
import { useGlobalState } from '../../../../contexts/globalContext'
import { useAuthState } from '../../../../contexts/authContext'
import './styles.scss'
import { toast } from '../../../../components/Toast'
import Loader from '../../../../components/Loader'
import { IWalletResponse } from '../../../../interface/wallet'
import { useNavigate, useSearchParams } from 'react-router-dom'
import API from '../../../../network/api'
import { IError } from '../../../../interface/error'
import { PROFILE } from '../../../../routing/navigation'

const WalletComponent: React.FC<{
  getWallet?: () => Promise<void>
  cardSuccess?: boolean
  amount?: string
  paymentCurrency?: string
  showDeposit?: boolean
  setShowFiatDeposit?: (value: boolean) => void
}> = ({ ...props }) => {
  const route = useNavigate()

  const { wallet } = useGlobalState()
  const { user } = useAuthState()

  // const [searchParams, setSearchParams] = useSearchParams()

  // const cardAuthCode = searchParams.get('authcode')
  // const cardTransactionType = searchParams.get('type')

  const [loading, setLoading] = useState(false)
  const [showFiatWithDrawal, setShowFiatWithDrawal] = useState(false)
  const [showDeposit, setShowFiatDeposit] = useState(false)
  const [showBeneficiary, setShowBeneficiary] = useState(false)
  const [currentWallet, setCurrentWallet] =
    useState<IWalletResponse['data'][0]>()
  const [cardSuccess, setCardSuccess] = useState(false)
  const [paymentCurrency, setPaymentCurrency] = useState('')
  const [data, setData] = useState<{ [key: string]: string }>({ amount: '' })
  const [showCurrency, setShowCurrency] = useState(false)
  const ModalRef = useRef<IModal>(null)
  const FiatWithdrawalModalRef = useRef<IModal>(null)
  const BeneficiaryModalRef = useRef<IModal>(null)

  // useEffect(() => {
  //   // const timer = setTimeout(() => {
  //   //   getTransactionStatus()
  //   // }, 1000)

  //   // return () => {
  //   //   clearTimeout(timer)
  //   // }
  // }, [])

  const openCurrency = (): void => {
    setShowCurrency((prev) => !prev)
  }

  const onSelectWallet = (data: IWalletResponse['data'][0]): void => {
    setCurrentWallet(data)
    openCurrency()
  }

  useEffect(() => {
    const activeCurrency = 'USD'
    const activeWallet = wallet?.filter(
      (purse) => purse.currency.code === activeCurrency
    )

    if (activeWallet) setCurrentWallet(activeWallet[0])
  }, [wallet])

  // const getTransactionStatus = async (): Promise<void> => {
  //   if (cardAuthCode && cardTransactionType) {
  //     if (cardTransactionType === 'accountFunding') {
  //       try {
  //         const response = await API.verifyCardDeposit(cardAuthCode)
  //         if (response?.data?.status?.toLowerCase() === 'success') {
  //           setCardSuccess(true)
  //           setData({ ...data, amount: response.data.amount.toFixed(2) })

  //           setPaymentCurrency(response.data.currency)
  //           setShowFiatDeposit(true)
  //         } else {
  //           toast.error('Transaction might have failed')
  //         }
  //       } catch (err) {
  //         const error = err as IError
  //         toast.error(error.message || 'Deposit failed')
  //       }
  //       searchParams.delete('authcode')
  //       searchParams.delete('type')
  //       setSearchParams(searchParams)
  //     }
  //   }
  // }

  useEffect(() => {
    if (showFiatWithDrawal) FiatWithdrawalModalRef.current?.toggle()
  }, [showFiatWithDrawal])

  useEffect(() => {
    if (props.showDeposit || showDeposit) ModalRef.current?.toggle()
  }, [props.showDeposit, showDeposit])

  useEffect(() => {
    if (showBeneficiary) BeneficiaryModalRef.current?.toggle()
  }, [showBeneficiary])

  // const toggleDeposit = useCallback((): void => {
  //  ModalRef.current?.toggle()
  //     setShowFiatDeposit(false)
  // 	}, [])
  const toggleDeposit = (val?: boolean): void => {
    ModalRef.current?.toggle()
    setShowFiatDeposit(false)
    if (props.setShowFiatDeposit) {
      props.setShowFiatDeposit(false)
    }
  }

  const toggleWithDrawal = (): void => {
    FiatWithdrawalModalRef.current?.toggle()
    setShowFiatWithDrawal(false)
  }

  const toggleBeneficiary = (): void => {
    BeneficiaryModalRef.current?.toggle()

    setShowBeneficiary(false)
  }

  return (
    <>
      {loading && <Loader />}
      <div className="Wallet-component">
        <div className="row">
          <div className="col-12 mb-5">
            <Card>
              <div className="row">
                <div className="col-12">
                  <div className="Wallet-header mb-4">
                    <h6>Fiat Wallet </h6>
                  </div>
                </div>
              </div>
              <div className="row pt-4 pb-4">
                <div className="col-lg-3 Balance d-flex align-items-end ">
                  <div>
                    {currentWallet ? (
                      <div className="d-flex">
                        <div className="dropdown" style={{ marginTop: -12 }}>
                          <button
                            className="btn btn-link dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={openCurrency}
                          >
                            {currentWallet.currency.code}
                          </button>

                          <ul
                            className={`dropdown-menu ${
                              showCurrency ? 'show' : ''
                            }`}
                          >
                            <div>
                              {wallet?.length &&
                                wallet.map((purse) => (
                                  <li
                                    key={purse.id}
                                    className={`Currency-list ${
                                      purse.id === currentWallet.id
                                        ? 'Selected'
                                        : ''
                                    }`}
                                    onClick={() => onSelectWallet(purse)}
                                  >
                                    {purse.currency.code}
                                  </li>
                                ))}
                            </div>
                          </ul>
                        </div>
                        <h4 className="text-primary mb-3">
                          {/* {currentWallet?.currency?.code}{' '} */}
                          <span className="h4 text-dark ms-4">
                            {Number(currentWallet?.available_balance).toFixed(
                              2
                            )}
                          </span>
                        </h4>
                      </div>
                    ) : (
                      user?.kycLevel < 3 && (
                        <Button
                          type="link"
                          label="Update profile"
                          onClick={() => route(`/${PROFILE}`)}
                        />
                      )
                    )}
                    {currentWallet && <p className="mb-0">Available Balance</p>}
                  </div>
                </div>
                <div className="col-lg-9 d-flex  align-items-end">
                  <div className="me-4">
                    <Button
                      label="Deposit"
                      size="small"
                      onClick={() => {
                        if (user?.kycLevel < 2) {
                          document.getElementById('kyc-button')?.click()

                          return
                        }
                        setShowFiatDeposit(true)
                        setShowFiatWithDrawal(false)
                        // setShowCryptoWithdrawal(false)
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      label="Withdraw"
                      type="outline"
                      size="small"
                      onClick={() => {
                        if (user?.kycLevel < 2) {
                          document.getElementById('kyc-button')?.click()

                          return
                        }
                        setShowFiatWithDrawal(true)
                        setShowFiatDeposit(false)
                        // setShowCryptoWithdrawal(false)
                      }}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="row Rental-wallet">
          <div className="col-lg-6 mb-5 mb-lg-0">
            <Card>
              <div className="row">
                <div className="col-12">
                  <div className="Wallet-header mb-4">
                    <div className="row">
                      <div className="col-6">
                        <h6>Rental Wallet </h6>
                      </div>
                      {/* <div className="col-6">
                        <div className="row">
                          <Toggle labelOne="Fiat" labelTwo="Crypto" />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-4 pb-4">
                <div className="col-lg-6 Balance d-flex align-items-end ">
                  <div>
                    <h4 className="text-primary mb-3">
                      USD <span className="h4 text-dark ms-4">0</span>
                    </h4>
                    <p className="mb-0">Available Balance</p>
                  </div>
                </div>
                <div className="col-lg-6 d-flex  align-items-end">
                  {/* <div>
                    <Button
                      label="Withdraw"
                      size="small"
                      onClick={() => {
                        setShowFiatWithDrawal(false)
                        setShowFiatDeposit(false)
                        // setShowCryptoWithdrawal(true)
                      }}
                    />
                  </div> */}
                </div>
              </div>
            </Card>
          </div>
        </div>
        {showBeneficiary && (
          <BeneficiaryDetailsModal
            ref={BeneficiaryModalRef}
            toggle={() => toggleBeneficiary()}
          />
        )}
        {showDeposit && (
          <WalletModal
            currency={currentWallet ? currentWallet.currency.code : ''}
            ref={ModalRef}
            toggle={(val) => toggleDeposit(val)}
            cardSuccess={props.cardSuccess ?? cardSuccess}
            paymentCurrency={props.paymentCurrency ?? paymentCurrency}
            amount={props.amount ?? data.amount}
            // callBack={() => window.location.reload()}
            callBack={props.getWallet}
          />
        )}

        {showFiatWithDrawal && (
          <FiatWithdrawalModal
            ref={FiatWithdrawalModalRef}
            toggle={() => toggleWithDrawal()}
            currency={currentWallet ? currentWallet.currency.code : ''}
            callback={props.getWallet}
          />
        )}

        {/* {showCryptoWithdrawal && (
          <CryptoRentalWithdrawal
            ref={CryptoWithdrawalModalRef}
            toggle={() => toggleCryptoWithDrawal()}
          />
        )} */}
      </div>
    </>
  )
}

export default React.memo(WalletComponent)
