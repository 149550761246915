import React, { useState } from 'react'

import SignupTitleAndDesc from '../../components/SignupTitleAndDesc'
import Otp from '../../components/Otp'
import './styles.scss'
import Button from '../../components/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from '../../components/Toast'
import { useAuthDispatch, useAuthState } from '../../contexts/authContext'
import { HOME, VERIFY_EMAIL } from '../../routing/navigation'

const VerifyOtp: React.FC = () => {
  const state = useLocation().state as { phone: string }
  const route = useNavigate()

  const { verifyPhone, verifyOtp } = useAuthDispatch()
  const { user } = useAuthState()

  const [otpValid, setOtpValid] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [otp, setOtp] = useState('')

  const onChange = (value: string): void => {
    if (value.length === 6) {
      setOtp(value)
      setOtpValid(true)
    } else {
      setOtpValid(false)
    }
  }
  const onClick = (): void => {
    validateOtp()
  }

  const validateOtp = async (): Promise<void> => {
    try {
      if (state?.phone) {
        setLoading(true)
        const resp = await verifyOtp({ phone: state.phone, otp })
        toast.success(resp.message)
        if (user && !user.isEmailVerified) {
          route(`/${VERIFY_EMAIL}`)
          return
        }
        // TODO route to the real landing page
        route(`/${HOME}`)
      }
    } catch (error) {
      const err = error as Error
      toast.error(err.message || 'Can not validate OTP at the moment')
    }
    setLoading(false)
  }

  const handleResend = (): void => {
    resend()
  }

  const resend = async (): Promise<void> => {
    try {
      if (state?.phone) {
        setResendLoading(true)
        await verifyPhone({ phone: state.phone })
        toast.success('Another OTP has been sent to your number')
      }
    } catch (error) {
      const err = error as Error
      toast.error(
        err.message || 'Can not resent OTP at the moment, please try later'
      )
    }
    setResendLoading(false)
  }
  return (
    <>
      <SignupTitleAndDesc
        title="Verify OTP"
        description="Enter the OTP below"
        type="STD2"
      />
      <Otp onChange={onChange} length={6} otpValid={otpValid} />
      <div className="mt-3 mb-5">
        <small className="Info">
          An OTP has been sent to your phone number {state?.phone} <br /> Enter
          the OTP to login.
        </small>
      </div>

      <div className="mb-3">
        <Button
          onClick={onClick}
          loading={loading}
          label="Login"
          disabled={!otpValid}
        />
      </div>
      <div className="mb-3">
        <Button
          onClick={handleResend}
          loading={resendLoading}
          type="outline"
          label="Resend OTP"
        />
      </div>
    </>
  )
}

export default VerifyOtp
