import React, { FC, useState, useEffect } from 'react'

import './styles.scss'

import { v4 as uuidv4 } from 'uuid'

import { IAuctionResponseData } from '../../interface/auction'

export interface IPropertyPhotoList {
  images: IAuctionResponseData['asset']['image']
}

const PropertyPhotoList: FC<IPropertyPhotoList> = ({ ...props }) => {
  const [imageList, setImageList] = useState<any[]>([])

  const formatImages = (): void => {
    const images = props.images
    let countToFive = 0
    const newImage: any[] = []

    if (images) {
      if (images?.length >= 3) {
        images.slice(0, 5).forEach((image, i) => {
          countToFive += 1

          if (countToFive < 5) {
            if (countToFive === 1) {
              newImage.push({
                images: [{ ...image }],
                class: 'col-md-5',
                secondClass: 'Image-container',
                type: countToFive,
              })
            } else if (countToFive === 2) {
              newImage.push({
                images: [{ ...image }, { ...images[i + 1] }],
                class:
                  'col-6 col-md-3 d-flex flex-column justify-content-between',
                type: countToFive,
                secondClass: 'Image-container-2',
              })
            } else if (countToFive === 4) {
              newImage.push({
                images: [{ ...image }, { ...images[i + 1] }],
                class:
                  'col-6 col-md-4  d-flex flex-column justify-content-between',
                type: countToFive,
                secondClass: 'Image-container-2',
              })
            }
          } else if (countToFive === 5) {
            countToFive = 0
          }
        })
      } else if (images?.length === 1) {
        newImage.push({
          images: [{ ...images[0] }],
          class: 'col-md-12',
          secondClass: 'Image-container-1',
        })
      } else if (images?.length === 2) {
        images.forEach((image) => {
          newImage.push({
            images: [{ ...image }],
            class: 'col-md-6',
            secondClass: 'Image-container-1',
          })
        })
      }
      setImageList(newImage)
    }
  }

  useEffect(() => {
    formatImages()
  }, [props.images])

  return (
    <div className="row Photo-list">
      {imageList.length &&
        imageList.map((image) => (
          <div className={image?.class} key={uuidv4()}>
            {image?.images.length &&
              image?.images.map(
                (img: IAuctionResponseData['asset']['image'][0]) => (
                  <>
                    {img?.thumbnail ? (
                      <div className={image?.secondClass}>
                        <img src={img?.thumbnail.url} />
                      </div>
                    ) : null}
                  </>
                )
              )}
          </div>
        ))}

      {/* <div className="col-md-5">
                <div className="Image-container">
                  <img
                    src={
                      auction.property.image[
                        imageLoader(auction.property.image)
                      ].thumbnail.url
                    }
                  />
                </div>
              </div>
              <div className="col-6 col-md-3 d-flex flex-column justify-content-between">
                <div className="Image-container-2">
                  <img
                    src={
                      auction.property.image[
                        imageLoader(auction.property.image)
                      ].thumbnail.url
                    }
                  />
                </div>
                <div className="Image-container-2">
                  <img
                    src={
                      auction.property.image[
                        imageLoader(auction.property.image)
                      ].thumbnail.url
                    }
                  />
                </div>
              </div>
              <div className="col-6 col-md-4  d-flex flex-column justify-content-between">
                <div className="Image-container-2">
                  <img
                    src={
                      auction.property.image[
                        imageLoader(auction.property.image)
                      ].thumbnail.url
                    }
                  />
                </div>
                <div className="Image-container-2">
                  <img
                    src={
                      auction.property.image[
                        imageLoader(auction.property.image)
                      ].thumbnail.url
                    }
                  />
                </div>
              </div> */}
    </div>
  )
}

export default PropertyPhotoList
