import moment from 'moment'

export const getDaysDiff = (start: string, end: string): number => {
  const startDate = moment(start)
  const endDate = moment(end)
  return endDate.diff(startDate, 'days')
}

export const fullDateString = (date: string): string => {
  return moment(date).format('DD MMMM, YYYY')
}
