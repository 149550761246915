/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { Icon } from '../Icons'
import './styles.scss'
import { NavLink, Outlet } from 'react-router-dom'
import HomeNav from './components/HomeNav'
import LandingPageNav from './components/LandingPageNav'

const Nav: React.FC<{ type?: 'landingPageNav' | 'homePageNav' }> = ({
  type = 'homePageNav',
}) => {
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false)
  const [showHomeNav, setHomeNavShow] = useState(false)

  function getNav(): JSX.Element {
    switch (type) {
      case 'homePageNav':
        return <HomeNav setLoading={setLoading} show={showHomeNav} />
      case 'landingPageNav':
        return (
          <LandingPageNav
            setLoading={setLoading}
            show={show}
            setShow={setShow}
          />
        )
    }
  }

  return (
    <>
      <nav className={`navbar navbar-expand-lg bg-light Header ${type}`}>
        <div className="container">
          <NavLink className="navbar-brand me-lg-5" to="/" end>
            <Icon name="secondary-logo" />
          </NavLink>
          {type === 'homePageNav' ? (
            <button
              className="navbar-toggler"
              type="button"
              // data-bs-toggle="collapse"
              // data-bs-target="#navbarSupportedContent"
              // aria-controls="navbarSupportedContent"
              // aria-expanded="false"
              // aria-label="Toggle navigation"
              onClick={() => setHomeNavShow((prev) => !prev)}
            >
              <span className="navbar-toggler-icon" />
            </button>
          ) : (
            <button
              className="navbar-toggler"
              type="button"
              // data-bs-toggle="collapse"
              // data-bs-target="#navbarSupportedContent"
              // aria-controls="navbarSupportedContent"
              // aria-expanded="false"
              // aria-label="Toggle navigation"
              onClick={() => setShow((prev) => !prev)}
            >
              <span className="navbar-toggler-icon" />
            </button>
          )}
          {getNav()}
        </div>
      </nav>

      {!loading && <Outlet />}
      {/* <div id="veriff-root" style={{ width: '400px' }}></div> */}
    </>
  )
}

export default Nav
