import React, { FC } from 'react'
import Toast, { Toaster } from 'react-hot-toast'
// import { Icon } from '../Icons'

const ToastComponent: FC = () => {
  return (
    <Toaster
      toastOptions={{
        position: 'bottom-left',
        duration: 6000,
        className: '',
        style: {
          padding: '16px',
          maxHeight: '300px',
          overflowY: 'auto',
        },
        success: {
          // icon: <Icon name="profile" />,
          iconTheme: {
            primary: '#ffffff',
            secondary: '#41ce8e',
          },
          style: {
            background: '#41ce8e',
            color: '#ffffff',
          },
        },
        error: {
          // icon: <Icon name="profile" />,
          iconTheme: {
            primary: '#000000',
            secondary: '#ffffff',
          },
          style: {
            background: '#ff0066',
            color: '#ffffff',
          },
        },
      }}
    />
  )
}

export const toast = Toast
export default ToastComponent
