/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useEffect, useState, useRef } from 'react'
import Button from '../../components/Button'
import './styles.scss'
import { Carousel } from 'react-responsive-carousel'
import { v4 as uuidv4 } from 'uuid'
import { Icon } from '../../components/Icons'
import FriendlyBg from '../../assets/background-images/friendlyBg1.svg'
import FriendlyBg2 from '../../assets/background-images/friendlyBg2.svg'
import Card from '../../components/Card'
import TextInput from '../../components/TextInput'
import Footer from '../../components/Footer'

const LandingPage: React.FC = () => {
  const [showCode, setShowCode] = useState(false)
  const [showCode2, setShowCode2] = useState(false)
  const [text, setText] = useState([
    'import tensorflow as tf',
    'import systems as sys',
  ])
  const timer = useRef(null)
  const carouselImages = [
    {
      image: '../../assets/images/startup-finance.webp.jpg',
      title: 'Financing of startups',
    },
    {
      image: '../../assets/images/asset-tokenization.jpeg',
      title: 'Invest in real estate',
    },
    {
      image: '../../assets/images/commodity-tokenization.jpeg',
      title: 'Commodities tokenization',
    },
    {
      image: '../../assets/images/music-tokenization.jpeg',
      title: 'Music tokenization',
    },
    {
      image: '../../assets/images/art-tokenization.jpeg',
      title: 'Art tokenization',
    },
    {
      image: '../../assets/images/perciousmetal-tokenization.jpeg',
      title: 'Precious metal tokenization',
    },
    {
      image: '../../assets/images/invest-in-bonds.jpeg',
      title: 'Invest in Bonds',
    },
  ]
  // const arrowStyles: CSSProperties = {
  //   position: 'absolute',
  //   zIndex: 2,
  //   top: 'calc(80% - 15px)',
  //   marginLeft: 30,
  //   marginRight: 30,
  //   width: 40,
  //   height: 40,
  //   cursor: 'pointer',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   border: '1px solid rgba(11, 92, 168, 0.12)',
  //   borderRadius: 20
  // }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCode(true)
    }, 4000)

    // let count = 0
    // const t = setInterval(() => {
    //   count += 1
    //   console.log(count)
    //   if (count % 4 === 0 && count <= 8) {
    //     setText((prev) => [...prev, 'import systems as sys'])
    // 	}
    // }, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setShowCode2(true)
    }, 18000)
  }, [showCode])

  return (
    <div style={{ overflowX: 'hidden' }}>
      <div className="container Landing">
        <section className="row Section-one">
          <div className="col-md-7 Part1">
            <h1 className="mb-5">
              Tokenizing real world assets using blockchain
            </h1>
            <p>
              Invest in real world assets from around the globe at the ease of
              your finger tips.
            </p>
            <Button
              size="small"
              label="Know more"
              onClick={() => console.log('know more')}
            />
          </div>
          <div className="col-md-5">
            <div className="CarouselWrapper">
              <div className="CarouselInnerWrapper">
                <Carousel
                  autoPlay
                  infiniteLoop
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        className="Carousel-button"
                        style={{ left: 15 }}
                      >
                        <Icon name="caret-right" rotate={180} />
                      </button>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        className="Carousel-button"
                        style={{ right: 15 }}
                      >
                        <Icon name="caret-right" />
                      </button>
                    )
                  }
                >
                  {carouselImages.map((img) => (
                    <div key={uuidv4()}>
                      <img src={img.image} />
                      <p className="legend">{img.title}</p>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
          <div className="SpiralIconContainer">
            <Icon name="spiral" />
          </div>
        </section>
        <section className="row Section-two">
          <div className="col-md-5">
            <div className="Harness-bg">
              <Icon name="mini-computer" />
            </div>
          </div>
          <div className="col-md-7">
            <h3 className="mb-5">
              Tokenizing real world assets using blockchain
            </h3>
            <p>
              Invest in real world assets from around the globe at the ease of
              your finger tips.
            </p>
            <p className="P-2">
              Fracxn opens up the possibility of harnessing the potential of
              tokenization for everyone. Thanks to our ecosystem, you can
              attract investors or participate in the tokenization of startups,
              real estate or luxury goods.
            </p>
          </div>
        </section>
        <section className="row Section-three d-flex">
          <div className="col-md-7 Part-1">
            <div className="">
              <img src={FriendlyBg} />
            </div>
            <div className="align-self-center ">
              <h3 className="mb-5">Users friendly tokenization ecosystem </h3>
              <p>
                Tokenization is just the beginning! With us, you will create
                your own ecosystem for investors!
              </p>
              <p className="P-2">
                Fracxn combines blockchain and decentralized applications with
                traditional assets and a broad portfolio of financial services,
                making our solutions flexible, scalable and accessible to
                everyone and anyone around the world.
              </p>
            </div>
          </div>
          <div className="col-md-5 Part-2">
            <img src={FriendlyBg2} />

            <div className="Harness-bg">
              <div className="friendly-container">
                <div className="friendly-icon-container">
                  <Icon name="friendly-icon-bg" />
                  <div className="Center-logo">
                    <div className="Rotate-1">
                      <Icon name="people-rotate" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="row Ll">
          <div className="col-md-6 Part-3">
            <div className="Bg"></div>
            <div className="Code">
              {showCode && (
                <div className="Code-content">
                  <code className="code-1">
                    {text[0]} <br />
                  </code>

                  {/* <code className="code-1">
                    import tensorflow as tf <br />
                    import systems as sys
                    <br />
                  </code> */}
                  {/* <code className="code-1">import systems as sys</code> */}
                  <br />
                </div>
              )}

              {showCode2 && (
                <div className="Code-content">
                  <code className="code-2">
                    {text[1]} <br />
                  </code>

                  {/* <code className="code-1">
                    import tensorflow as tf <br />
                    import systems as sys
                    <br />
                  </code> */}
                  {/* <code className="code-1">import systems as sys</code> */}
                  <br />
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 Part-4">
            <h3 className="mb-5">One API to tokenize your physical assets</h3>
            <p>
              Pre-built and customizable UI that is co-branded and integrated
              with your platform.
            </p>
            <p className="P-2">
              Thanks to fracxn API it is build in such a way that you don’t have
              to build a separate integration to tokenize and manage the data.
              We provide a single API according to the asset you wish to
              tokenize.
            </p>
          </div>
        </section>

        <div className="row Section-four">
          <div className="col-md-6 offset-md-3 Content">
            <h2 className="text-center">
              Learn more about the possibilities of tokenization
            </h2>

            <div className="row Action-row">
              <div className="col-6 col-md-6">
                <div className="Action mb-3 mb-md-0 ms-md-5">
                  <p>ARTWORK</p>
                </div>
              </div>
              <div className="col-6 mt-5 col-md-6 mt-md-4">
                <div className="Action text-end text-md-start">
                  <p>STARTUP</p>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>

            <div className="row Action-row">
              <div className="col-6 col-md-6">
                <div className="Action mb-3 mb-md-0">
                  <p>GOLD</p>
                </div>
              </div>
              <div className=" col-6 mt-5 mt-md-0 col-md-6">
                <div className="Action  Carbon">
                  <p>CARBON CREDITS</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-md-6">
                <div className="Action ms-5 mt-0 mt-md-5">
                  <p>GOLD</p>
                </div>
              </div>
              <div className="col-6 mt-5 mt-md-0 col-md-6">
                <div className="Action mb-4">
                  <p>COMMODITIES</p>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </div>

        <div className="Section-five">
          <div className="row">
            <div className="col-12">
              <Card>
                <div className="row">
                  <div className="col-md-6 Part-1">
                    <h1>Get started with us</h1>
                    <p>
                      Dont bother to get started us, we are ready to help you
                      24/7. Please fill in the column on the right to get
                      started
                    </p>
                  </div>
                  <div className="col-md-6 Part-2 pt-3">
                    <Card>
                      <h4>Ask a question</h4>

                      <TextInput
                        label=" "
                        placeholder="Full Name"
                        id="full***"
                        name="fullName"
                        type="text"
                        // valid={!validationResult.email}
                        // errorText={validationResult.email}
                        onChange={() => null}
                      />
                      <TextInput
                        label=" "
                        placeholder="Email"
                        id="email***"
                        name="email"
                        type="email"
                        // valid={!validationResult.email}
                        // errorText={validationResult.email}
                        onChange={() => null}
                      />
                      <TextInput
                        label=" "
                        placeholder="Description"
                        id="desc01"
                        name="description"
                        type="textArea"
                        // valid={!validationResult.email}
                        // errorText={validationResult.email}
                        onChange={() => null}
                      />
                      <Button label="Send" size="medium" />
                    </Card>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default LandingPage
