import React, {
  FormEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useState,
} from 'react'
import Button from '../../../../components/Button'
import { Icon } from '../../../../components/Icons'
import Modal, { IModal } from '../../../../components/Modal'
import './styles.scss'
import { toast } from '../../../../components/Toast'
import TextInput from '../../../../components/TextInput'
import {
  updatePasswordValidation,
  validateInput,
} from '../../../../utils/validations'
import { v4 as uuidv4 } from 'uuid'
import { useAuthDispatch } from '../../../../contexts/authContext'

interface ModalProps {
  toggle: () => void
  callback?: () => any
}
const UpdatePasswordModal: ForwardRefRenderFunction<IModal, ModalProps> = (
  { ...props },
  ref
) => {
  const { updatePassword } = useAuthDispatch()
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ [key: string]: string }>({})
  const [validated, setValidated] = useState(false)
  const [validationResult, setValidationResult] = useState<{
    [key: string]: string
  }>({})

  const nextStep = (): void => {
    if (step === 2) {
      setStep(1)
      return props.toggle()
    }
    setStep((prev) => prev + 1)
  }

  const closeModal = (): void => {
    if (props.callback) props.callback()
    props.toggle()
  }

  const validate = (onSubmit = true): boolean => {
    const validationResponse = validateInput({
      data,
      onSubmit,
      validations: updatePasswordValidation,
    })
    setValidationResult((prev) => {
      return { ...prev, ...validationResponse.validationResultData }
    })
    return validationResponse.validated
  }
  useEffect(() => {
    let delayDebounceFn: any = null

    delayDebounceFn = setTimeout(() => {
      const result = validate(false)
      setValidated(result)
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [data])

  const onInputChange = (name: string, value: string): void => {
    setData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      }
    })
  }

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault()

    submit()
  }
  const submit = async (): Promise<void> => {
    setLoading(true)
    try {
      await updatePassword({
        newPasswordConfirmation: data.newPasswordConfirmation,
        newPassword: data.newPassword,
        oldPassword: data.oldPassword,
      })
      nextStep()
    } catch (error) {
      const err = error as Error
      toast.error(err.message || 'An error occured, please try again later')
    }
    setLoading(false)
  }

  const step1 = (): JSX.Element => (
    <div className="UpdatePhoneBody">
      <div className="mb-4 text-center">
        <Icon name="update-profile" />
      </div>
      <div className="mb-4 text-center">
        <p style={{ fontWeight: 600 }}>Set new password</p>
        <small style={{ fontWeight: 500, color: '#979797' }}>
          Your new password must be different from previously used passwords
        </small>
      </div>

      <form className="mb-4" onSubmit={handleSubmit}>
        <div className="mb-5">
          <TextInput
            label="Current Password"
            name="oldPassword"
            type="password"
            onChange={onInputChange}
            id={uuidv4()}
            errorText={validationResult.oldPassword}
            valid={!validationResult.oldPassword}
          />

          <TextInput
            label="password"
            name="newPassword"
            type="password"
            onChange={onInputChange}
            id={uuidv4()}
            errorText={validationResult.newPassword}
            valid={!validationResult.newPassword}
          />

          <TextInput
            label="Confirm password"
            name="newPasswordConfirmation"
            type="password"
            onChange={onInputChange}
            id={uuidv4()}
            errorText={validationResult.newPasswordConfirmation}
            valid={!validationResult.newPasswordConfirmation}
          />
        </div>
        <div>
          <Button
            loading={loading}
            disabled={!validated}
            label="Update user name"
          />
        </div>
      </form>
    </div>
  )

  const step2 = (): JSX.Element => (
    <div className="UpdatePhoneBody">
      <div className="mb-4 text-center">
        <Icon name="phone-updated" />
      </div>
      <div className="mb-4 text-center">
        <p style={{ fontWeight: 600 }}>Password Updated</p>
        <small style={{ fontWeight: 500, color: '#979797' }}>
          Your password has been successfully updated
        </small>
      </div>

      <div className="mb-4">
        <div>
          <Button onClick={closeModal} label="Back to profile" />
        </div>
      </div>
    </div>
  )

  const UpdatePhoneFlow: { [key: number]: JSX.Element } = {
    1: step1(),
    2: step2(),
  }
  return (
    <>
      <Modal ref={ref}>
        <div className="UpdatePhone">
          <div className="UpdatePhoneHeader d-flex justify-content-end">
            <Icon
              name="close"
              onClick={() => {
                // setStep(1)
                props.toggle()
              }}
            />
          </div>
          <>{UpdatePhoneFlow[step]}</>
        </div>
      </Modal>
    </>
  )
}

export default forwardRef(UpdatePasswordModal)
