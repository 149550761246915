/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useState
} from 'react'
import Button from '../../../../components/Button'
// import Card from '../../../../components/Card'
import { Icon, IconProps } from '../../../../components/Icons'
import Modal, { IModal } from '../../../../components/Modal'
import PayOptionButton from '../../../../components/PayOptionButton'
import TextInputGroup from '../../../../components/TextInputGroup'
// import Toggle from '../../../../components/Toggle'
import { v4 as uuidv4 } from 'uuid'
import './styles.scss'
import TextInput from '../../../../components/TextInput'
import {
  beneficiaryValidations,
  validateInput
} from '../../../../utils/validations'
import { useErrorState } from '../../../../contexts/errorContext'
import { useGlobalState } from '../../../../contexts/globalContext'
import { IError } from '../../../../interface/error'
import { toast } from '../../../../components/Toast'
import API from '../../../../network/api'
import moment from 'moment'
import { IWalletResponse } from '../../../../interface/wallet'
import { IBeneficiaryResponse } from '../../../../interface/beneficiary'
import { IWireDepositResponseData } from '../../../../interface/transaction'

interface ModalProps {
  toggle: (val?: boolean) => void
  currency?: string
  paymentCurrency?: string
  paymentOption?: string
  amount?: number | string
  cardSuccess?: boolean
  callBack?: () => any
}
const WalletModalComponent: ForwardRefRenderFunction<IModal, ModalProps> = (
  { ...props },
  ref
) => {
  const { addError } = useErrorState()
  const { wallet, settings } = useGlobalState()

  const [step, setStep] = useState(1)
  const [payMode, setPayMode] = useState<'card' | 'wire' | 'connectBank'>(
    'card'
  )
  const [newBank, setNewBank] = useState(false)
  // const [bankAdded, setBankAdded] = useState(false)
  const [data, setData] = useState<{ [key: string]: string }>({ amount: '' })
  const [beneficiaryData, setBeneficiaryData] = useState<{
    [key: string]: string
  }>({})
  const [validationResult, setValidationResult] = useState<{
    [key: string]: string
  }>({})

  const [beneficiaryValidationResult, setBeneficiaryValidationResult] =
    useState<{
      [key: string]: string
    }>({})
  const [validated, setValidated] = useState(false)
  const [beneficiaryValidated, setBeneficiaryValidated] = useState(false)
  const [loadingCard, setLoadingCard] = useState(false)
  const [beneficiaries, setBeneficiaries] =
    useState<IBeneficiaryResponse['data']>()
  const [loadingBeneficiaries, setLoadingBeneficiaries] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedBank, setSelectedBank] =
    useState<IBeneficiaryResponse['data'][0]>()
  const [currentCurrency, setcurrentCurrency] = useState<string>('')
  const [fracxnBeneficiaryAccount, setFracxnBeneficiaryAccount] =
    useState<IWireDepositResponseData>()
  const [depositLoading, setDepositLoading] = useState(false)

  let backTowalletTimer: NodeJS.Timeout

  const style = { borderRadius: 3, borderColor: '#c4c4c4' }

  useEffect(() => {
    if (props.cardSuccess) setStep(3)
    return () => {
      if (backTowalletTimer) {
        clearTimeout(backTowalletTimer)
      }
    }
  }, [])

  useEffect(() => {
    setcurrentCurrency(props.currency ?? '')
  }, [props.currency])

  useEffect(() => {
    setValidated(false)
    if (payMode === 'wire' && !beneficiaries) {
      getBeneficiaries()
    }
  }, [payMode])

  const getBeneficiaries = async (): Promise<void> => {
    setLoadingBeneficiaries(true)

    try {
      const response = await API.getBeneficiaries()
      setBeneficiaries(response.data)
    } catch (error) {
      const err = error as IError
      toast.error(err.message || 'Unable to get beneficiaries')
    }
    setLoadingBeneficiaries(false)
  }

  const validateBeneficiary = (onSubmit = true): boolean => {
    setBeneficiaryValidationResult({})
    const validationResponse = validateInput({
      data: beneficiaryData,
      onSubmit,
      validations: beneficiaryValidations,
    })
    setBeneficiaryValidationResult((prev) => {
      return { ...prev, ...validationResponse.validationResultData }
    })
    return validationResponse.validated
  }

  useEffect(() => {
    let delayDebounceFn: any = null

    delayDebounceFn = setTimeout(() => {
      const result = validateBeneficiary(false)
      setBeneficiaryValidated(result)
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [beneficiaryData])

  const copy = (text?: string): void => {
    if (text) {
      navigator.clipboard.writeText(text)
      toast.success('Copied to clipboard')
    }
  }
  const handleCreateBeneficiary = (): void => {
    createBeneficiary()
  }

  const createBeneficiary = async (): Promise<void> => {
    setLoading(true)
    try {
      await API.createBeneficiaries({
        swiftCode: beneficiaryData.swiftCode,
        country: beneficiaryData.country,
        iban: beneficiaryData.iban,
        accountNumber: beneficiaryData.accountNumber,
        bankName: beneficiaryData.bankName,
        accountName: beneficiaryData.accountName,
      })
      await getBeneficiaries()
      setNewBank(false)
      toast.success('Beneficiary added successfully')
    } catch (error: unknown) {
      const err = error as IError
      toast.error(err.message || 'unable to add beneficiary at the moment')
    }
    setLoading(false)
  }

  const amount = (data: Record<string, string>): string => {
    if (!data.amount) {
      return 'Enter amount'
    }
    if (Number(data.amount) === 0) {
      return 'Amount should be greater than 0'
    }
    return ''
  }
  const validations = {
    amount,
  }

  const validate = (onSubmit = true): boolean => {
    setValidationResult({})
    const validationResponse = validateInput({
      data,
      onSubmit,
      validations,
    })
    setValidationResult((prev) => {
      return { ...prev, ...validationResponse.validationResultData }
    })
    return validationResponse.validated
  }

  useEffect(() => {
    let delayDebounceFn: any = null

    delayDebounceFn = setTimeout(() => {
      const result = validate(false)
      setValidated(result)
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [data.amount])

  const onInputChange = useCallback(
    (name: string, value: string): void => {
      setData((prevData) => {
        return {
          ...prevData,
          [name]: value,
        }
      })
    },
    [data.amount]
  )

  const onBeneficiaryChange = useCallback(
    (name: string, value: string): void => {
      setBeneficiaryData((prevData) => {
        return {
          ...prevData,
          [name]: value,
        }
      })
    },
    [beneficiaryData]
  )

  const nextStep = (): void => {
    if (step === 4) {
      setStep(1)
      props.toggle()
      return
    }
    setStep((prev) => prev + 1)
  }
  const prevStep = (): void => {
    setStep((prev) => {
      if (prev === 1) {
        props.toggle()
        return prev
      }
      return prev - 1
    })
  }

  const handleCardDeposit = (): void => {
    launchCardPayment()
  }
  const launchCardPayment = async (): Promise<void> => {
    try {
      setLoadingCard(true)
      if (data.amount && props.currency) {
        const response = await API.cardDeposit({
          cancelUrl: `${window.location.href}?cancel=true`,
          responseUrl: `${window.location.href}?type=accountFunding`,
          amount: Number(data.amount),
          currencyCode: currentCurrency,
        })
        window.open(response.data.redirectURL, '_self')
      }
    } catch (error: unknown) {
      const err = error as IError
      toast.error(err.message || 'Unable to make card payment at the moment')
      addError(err)
    }
    setLoadingCard(false)
  }

  const wireDeposit = async (): Promise<any> => {
    try {
      setDepositLoading(true)
      if (selectedBank) {
        const wireData = {
          from: {
            swiftCode: selectedBank.swiftCode,
            country: selectedBank.country,
            iban: selectedBank.iban,
            accountNumber: selectedBank.accountNumber,
            bankName: selectedBank.swiftCode,
            accountName: selectedBank.swiftCode,
          },
          amount: Number(data.amount || 0),
          currencyCode: currentCurrency,
        }
        const response = await API.wireDeposit(wireData)
        setFracxnBeneficiaryAccount(response.data)
        toast.success('Action successful')
      }
    } catch (error: unknown) {
      const err = error as IError
      toast.error(err.message || 'An error occured, Please try again later')
    }
    setDepositLoading(false)
  }

  const selectBank = (e: ChangeEvent<HTMLInputElement>, value: any): void => {
    setSelectedBank(value)
  }

  const onChangeCurrency = (currency: string): void => {
    setcurrentCurrency(currency)
  }

  const EnterAmount = (): JSX.Element => (
    <>
      <div className="Modal-header">
        <div className="d-flex justify-content-between">
          <Icon name="back-arrow" onClick={prevStep} />
          <Icon
            name="close"
            onClick={() => {
              props.toggle(false)
              if (props.callBack) props.callBack()
            }}
          />
        </div>
        <h3 className="text-center">Enter deposit amount </h3>
      </div>
      <form className="p-3 Enter-amount">
        <div className="mb-3">
          <label htmlFor="amount-123">Amount</label>

          <TextInputGroup
            groupType="amount"
            name="amount"
            id="amount-123"
            onChange={onInputChange}
            errorText={validationResult.amount}
            valid={!validationResult.amount}
            currencies={wallet?.map((wal) => wal.currency.code)}
            onChangeCurrency={onChangeCurrency}
            currentCurrency={currentCurrency}
          />

          {/* <div>
              <Icon
                name={
                  props.currency
                    ? (props.currency.toLowerCase() as IconProps['name'])
                    : 'normal-dubai-flag'
                }
                key={uuidv4()}
              />
            </div> */}
          {/* </TextInputGroup> */}
          <div className="Payment-sum">
            <div className="d-flex justify-content-center align-items-center">
              <p className="mt-3 me-3">Fees:</p>
              <p className="mt-3  text-dark">
                {settings?.fracxnFees?.deposit ?? 0}
              </p>
            </div>
          </div>
        </div>
        <div className="mb-2">
          <Button label="Continue" disabled={!validated} onClick={nextStep} />
        </div>
        <div className="text-center">
          <small>
            By depositing money, you agree to our{' '}
            <span className="text-primary">Terms & Conditions</span>
          </small>
        </div>
      </form>
    </>
  )

  const card = (): JSX.Element => (
    <div className="Card-details" style={{ height: 297 }}>
      <div className="Mini-loader">
        {loadingCard && (
          <div>
            <div className="d-flex justify-content-center mb-3">
              <Icon name="loader" />
            </div>

            <p>Redirecting to our partner website</p>
          </div>
        )}
      </div>
      <Button
        label="Continue"
        loading={loadingCard}
        disabled={payMode !== 'card'}
        onClick={() => handleCardDeposit()}
      />
    </div>
  )
  const addBank = (): JSX.Element => (
    <>
      <div>
        <div className="New-bank mt-4 mb-4 p-3">
          <h6>New Bank Account</h6>
          <div className="row">
            <div className="col-lg-6">
              <TextInput
                label="Account name"
                name="accountName"
                placeholder=" "
                onChange={onBeneficiaryChange}
                id={uuidv4()}
                style={style}
                errorText={beneficiaryValidationResult.accountName}
                valid={!beneficiaryValidationResult.accountName}
              />
            </div>
            <div className="col-lg-6">
              <TextInput
                label="Bank name"
                name="bankName"
                placeholder=" "
                onChange={onBeneficiaryChange}
                id={uuidv4()}
                style={style}
                errorText={beneficiaryValidationResult.bankName}
                valid={!beneficiaryValidationResult.bankName}
              />
            </div>
            <div className="col-lg-6">
              <TextInput
                label="Account number"
                name="accountNumber"
                placeholder=" "
                onChange={onBeneficiaryChange}
                id={uuidv4()}
                style={style}
                errorText={beneficiaryValidationResult.accountNumber}
                valid={!beneficiaryValidationResult.accountNumber}
              />
            </div>
            <div className="col-lg-6">
              <TextInput
                label="Country"
                name="country"
                placeholder=" "
                onChange={onBeneficiaryChange}
                id={uuidv4()}
                style={style}
                errorText={beneficiaryValidationResult.country}
                valid={!beneficiaryValidationResult.country}
              />
            </div>

            <div className="col-lg-12">
              <TextInput
                label="IBAN"
                name="iban"
                placeholder=" "
                onChange={onBeneficiaryChange}
                id={uuidv4()}
                style={style}
                errorText={beneficiaryValidationResult.iban}
                valid={!beneficiaryValidationResult.iban}
              />
            </div>
            <div className="col-lg-12">
              <TextInput
                label="Swift Code"
                name="swiftCode"
                placeholder=" "
                onChange={onBeneficiaryChange}
                id={uuidv4()}
                style={style}
                errorText={beneficiaryValidationResult.swiftCode}
                valid={!beneficiaryValidationResult.swiftCode}
              />
            </div>
          </div>
          <div className="row mt-2">
            <Button
              label="Add bank account"
              onClick={() => handleCreateBeneficiary()}
              disabled={!beneficiaryValidated}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  )

  const wire = (): JSX.Element => (
    <div className="Wire">
      {loadingBeneficiaries && (
        <div className="Mini-loader">
          <div>
            <div className="d-flex justify-content-center mb-3">
              <Icon name="loader" />
            </div>

            <p>Bank list loading</p>
          </div>
        </div>
      )}

      {!newBank && !loadingBeneficiaries ? (
        <div className="Bank-added  mt-4 mb-4 ">
          {beneficiaries && (
            <>
              {beneficiaries &&
                beneficiaries.length >= 1 &&
                beneficiaries.map((beneficiary) => (
                  <div
                    className="p-3 Check d-flex align-items-center"
                    key={uuidv4()}
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        name="checkBank"
                        id={uuidv4()}
                        checked={
                          selectedBank?.reference === beneficiary.reference
                        }
                        onChange={(e) => selectBank(e, beneficiary)}
                      />
                      <label
                        className="form-check-label d-flex align-items-center"
                        htmlFor="checkBank"
                      >
                        <div className="me-2">
                          <Icon name="bank" />
                        </div>
                        <div className="me-5">
                          <p>{beneficiary.bankName?.toUpperCase()}</p>
                          <p
                            style={{ fontSize: 10 }}
                          >{`${beneficiary.accountNumber.substring(
                            0,
                            4
                          )} ${Array(beneficiary.accountNumber.length - 4)
                            .fill('*')
                            .join(' ')}`}</p>
                        </div>
                        <div>
                          <p>{beneficiary.accountName}</p>
                        </div>
                      </label>
                    </div>
                  </div>
                ))}
            </>
          )}
          <div className="p-3 d-flex align-items-center New-bank-button">
            <Button
              type="link"
              onClick={() => setNewBank(true)}
              label="Add a new bank account"
            />
          </div>
        </div>
      ) : (
        !loadingBeneficiaries && addBank()
      )}
      {!loadingBeneficiaries && (
        <Button label="Continue" disabled={!selectedBank} onClick={nextStep} />
      )}
    </div>
  )
  const SelectPaymentMode = (): JSX.Element => (
    <>
      <div className="Modal-header">
        <div className="d-flex justify-content-between">
          <Icon name="back-arrow" onClick={prevStep} />
          <Icon
            name="close"
            onClick={() => {
              props.toggle(false)
              if (props.callBack) props.callBack()
            }}
          />
        </div>
        <h3 className="text-center">Select payment mode </h3>
      </div>
      <div className="Payment-mode p-3">
        <small>Payment methods</small>
        <div className="Pay-buttons mt-3 pb-2 d-flex flex-wrap justify-content-between">
          <div className="mb-3">
            <PayOptionButton
              icon={{ name: 'cards-small' }}
              label="Debit card"
              isActive={payMode === 'card'}
              onClick={() => setPayMode('card')}
            />
          </div>

          <div className="mb-3">
            <PayOptionButton
              icon={{ name: 'bank' }}
              label="Wire transfer"
              isActive={payMode === 'wire'}
              onClick={() => setPayMode('wire')}
            />
          </div>
          <div className="mb-3">
            <PayOptionButton
              // icon={{ name: 'bank' }}
              isActive={payMode === 'connectBank'}
              label="Connect Bank Account"
              onClick={() => setPayMode('connectBank')}
            />
          </div>
        </div>
        <div>
          {payMode === 'card' ? card() : payMode === 'wire' ? wire() : null}
        </div>
      </div>
    </>
  )

  const TotalDeposit = (): JSX.Element => (
    <>
      <div className="Modal-header">
        <div className="d-flex justify-content-between">
          <Icon name="back-arrow" onClick={prevStep} />
          <Icon
            name="close"
            onClick={() => {
              props.toggle(false)
              if (props.callBack) props.callBack()
            }}
          />
        </div>
        <h3 className="text-center">Total Deposit Amount </h3>
      </div>
      <div className="Total-deposit p-3">
        <div className="Total-deposit-container d-flex justify-content-between mb-4 ms-3 me-3">
          <div>
            <small>Deposit amount </small>
          </div>
          <div>
            <p>
              {currentCurrency}{' '}
              <strong>{Number(data.amount || 0).toFixed(2)}</strong>
            </p>
          </div>
        </div>
        <Button
          label="continue"
          loading={depositLoading}
          onClick={() => {
            wireDeposit()
              .then((response) => {
                nextStep()
                // if (props.callBack) props.callBack()
                // backTowalletTimer = setTimeout(() => {
                //   props.toggle()
                // }, 3000)
              })
              .catch((err) => {
                console.log(err)
              })
          }}
        />
      </div>
    </>
  )

  const Success = (): JSX.Element => (
    <div className="Success">
      <div className="Modal-header">
        <div className="d-flex justify-content-end">
          <Icon
            name="close"
            onClick={() => {
              props.toggle(false)
              if (props.callBack) props.callBack()
            }}
          />
        </div>
        <h3 className="text-center">Deposit Successful </h3>
      </div>
      <div className="text-center mt-3">
        <Icon name="sucess-icon" />
        <p className="mt-3 mb-2 Amount">
          {props.paymentCurrency ?? props.paymentCurrency} {props.amount ?? 0}
        </p>
        <p className="Success-message">Your deposit has been completed</p>

        <div className="d-flex justify-content-between Ts-date">
          <p className="Date-label">Date</p>
          <p className="Date">{moment().format('MMMM Do, YYYY')}</p>
        </div>
        <Button
          label="Continue"
          onClick={() => {
            if (props.callBack) props.callBack()
            props.toggle(false)
          }}
        />
      </div>
    </div>
  )

  const WirePending = (): JSX.Element => (
    <div className="Success">
      <div className="Modal-header">
        <div className="d-flex justify-content-end">
          <Icon
            name="close"
            onClick={() => {
              props.toggle(false)
              if (props.callBack) props.callBack()
            }}
          />
        </div>
        <h3 className="text-center">Deposit Pending </h3>
      </div>
      <div className="text-center mt-3">
        <Icon name="pending-icon" />
        <p className="mt-3 mb-2 Amount">
          {props.paymentCurrency ?? props.paymentCurrency} {props.amount ?? 0}
        </p>
        <p className="Success-message">
          Wallet will be updated once transfer has been received
        </p>
        {settings?.wireCredential && (
          <div>
            <div className="d-flex justify-content-between Ref">
              <p>Reference no.</p>
              <div className="d-flex">
                <p className="bold me-2">
                  {fracxnBeneficiaryAccount?.paymentReference}
                </p>
                <Icon
                  name="copy"
                  onClick={() =>
                    copy(`FRC${currentCurrency}${data.amount || 0}}`)
                  }
                />
              </div>
            </div>
            <div className="d-flex justify-content-between Ref">
              <p>Beneficiary name</p>
              <div className="d-flex">
                <p className="bold me-2">
                  {fracxnBeneficiaryAccount?.to.accountName}
                </p>
                <Icon
                  name="copy"
                  onClick={() => copy(fracxnBeneficiaryAccount?.to.accountName)}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between Ref">
              <p>Account number</p>
              <div className="d-flex">
                <p className="bold me-2">
                  {fracxnBeneficiaryAccount?.to.accountNumber}
                </p>
                <Icon
                  name="copy"
                  onClick={() =>
                    copy(fracxnBeneficiaryAccount?.to.accountNumber)
                  }
                />
              </div>
            </div>

            <div className="d-flex justify-content-between Ref">
              <p>Bank name</p>
              <div className="d-flex">
                <p className="bold me-2">
                  {fracxnBeneficiaryAccount?.to.bankName}
                </p>
                <Icon
                  name="copy"
                  onClick={() => copy(fracxnBeneficiaryAccount?.to.bankName)}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between Ref">
              <p>IBAN</p>
              <div className="d-flex">
                <p className="bold me-2">{fracxnBeneficiaryAccount?.to.iban}</p>
                <Icon
                  name="copy"
                  onClick={() => copy(fracxnBeneficiaryAccount?.to.iban)}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between Ref">
              <p>Swift code</p>
              <div className="d-flex">
                <p className="bold me-2">
                  {fracxnBeneficiaryAccount?.to.swiftCode}
                </p>
                <Icon
                  name="copy"
                  onClick={() => copy(fracxnBeneficiaryAccount?.to.swiftCode)}
                />
              </div>
            </div>
          </div>
        )}

        <div className="Info mt-3 mb-4" style={{ textAlign: 'left' }}>
          <p>
            <strong>Note:</strong> Please do not mention crypto or fracxn while
            depositing money into account instead add the reference number.
          </p>
        </div>
        <Button
          label="Back to wallet"
          disabled={!data.amount || !selectedBank}
          onClick={() => {
            if (props.callBack) props.callBack()
            nextStep()
          }}
        />
      </div>
    </div>
  )

  const DepositReceived = (): JSX.Element => (
    <>
      <div className="Modal-header">
        <div className="d-flex justify-content-between">
          <Icon name="back-arrow" onClick={prevStep} />
          <Icon
            name="close"
            onClick={() => {
              props.toggle(false)
              if (props.callBack) props.callBack()
            }}
          />
        </div>
        <h3 className="text-center">Deposit Request Received </h3>
      </div>
      <div className="Deposit-received p-3">
        <div className="text-center Desc pb-3">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium ut
            sollicitudin posuere pellentesque. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Pretium ut sollicitudin posuere
            pellentesque.
          </p>
        </div>
        <div className="Deposit-details">
          <div className="Detail-title">
            <p>Bank Details </p>
          </div>
          <div className="Detail-body">
            <div>
              <p className="Body-title">Account Name </p>
              <p className="Body-value">
                Lorem ipsum dolor sit amet, consectetur{' '}
              </p>
            </div>
            <div>
              <p className="Body-title">Account No. </p>
              <p className="Body-value">0123456789</p>
            </div>
            <div>
              <p className="Body-title">IBAN No. </p>
              <p className="Body-value">AE0123456789203259954454</p>
            </div>
            <div>
              <p className="Body-title">Swift Code </p>
              <p className="Body-value">EBNDAED</p>
            </div>
            <div>
              <p className="Body-title">Bank Name </p>
              <p className="Body-value">Emirates NBD </p>
            </div>
            <div>
              <p className="Body-title">Beneficiary </p>
              <p className="Body-value">Emirates NBD </p>
            </div>
            <div>
              <p className="Body-title">Address </p>
              <p className="Body-value">B-105 Design House, Gargash Park </p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between Action">
          <Button size="small" label="Print" onClick={() => null} />
          <Button size="small" label="Email" onClick={() => nextStep()} />
        </div>
      </div>
    </>
  )

  const depositFlow: { [key: number]: JSX.Element } = {
    1: EnterAmount(),
    2: SelectPaymentMode(),
    3: props.cardSuccess ? Success() : TotalDeposit(),
    4: WirePending(), // DepositReceived(),
  }

  return (
    <Modal
      ref={ref}
      size={
        props.cardSuccess === true || (payMode === 'wire' && step === 4)
          ? 'small'
          : 'normal'
      }
    >
      <div className="Fiat-Deposit">{depositFlow[step]}</div>
    </Modal>
  )
}

export default forwardRef(WalletModalComponent)
