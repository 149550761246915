import React, { forwardRef, ForwardRefRenderFunction } from 'react'
import { useNavigate } from 'react-router-dom'
import { IGetProfileResponseData } from '../../interface/user'
import { KYC } from '../../routing/navigation'
import Button from '../Button'
import { Icon } from '../Icons'
import Modal, { IModal } from '../Modal'

interface KycModalProps {
  toggle: () => void
  onClick?: () => void
  text?: string
  buttonLabel?: string
  userData?: IGetProfileResponseData['data']
}
const KycModal: ForwardRefRenderFunction<IModal, KycModalProps> = (
  { ...props },
  ref
) => {
  const route = useNavigate()
  function handleClick(): void {
    if (props.onClick) props.onClick()
    if (!props.onClick) {
      route(`/${KYC}`, { state: { userData: props.userData } })
    }
    props.toggle()
  }
  return (
    <Modal ref={ref}>
      <div>
        <div onClick={() => props.toggle()}>
          <Icon name="back-arrow" />
        </div>
        <h3 className="text-center">Verify your identity</h3>

        <div className="text-center ms-3 me-3 mb-2">
          <Icon name="verify" />
          <p className="mt-3 mb-3">
            {props.text ??
              'In order to invest and use all features please complete your KYC'}
          </p>

          <Button
            label={props.buttonLabel ?? 'Complete KYC'}
            onClick={handleClick}
          />
        </div>
      </div>
    </Modal>
  )
}
export default forwardRef(KycModal)
