import React, { useEffect } from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PROPERTIES, GET_STARTED, SIGNIN } from '../../../routing/navigation'
import { NavLink, useNavigate } from 'react-router-dom'
import Button from '../../Button'
import { Icon } from '../../Icons'
import { v4 as uuidv4 } from 'uuid'

interface ILandingPageNavProps {
  setLoading: (value: boolean) => void
  show: boolean
  setShow: (show: boolean) => void
}
const LandingPageNav: React.FC<ILandingPageNavProps> = ({
  setLoading,
  show,
  setShow,
}) => {
  useEffect(() => {
    setLoading(false)
  }, [])

  const route = useNavigate()
  const productRoutes = [
    {
      title: 'Real-Estate',
      to: '/',
    },
    {
      title: 'Commodities',
      to: '/',
    },
    {
      title: 'Carbon credits',
      to: '/',
    },
    {
      title: 'Gold',
      to: '/',
    },
    {
      title: 'Artwork',
      to: '/',
    },
    {
      title: 'Others',
      to: '/',
    },
  ]
  return (
    <div className={`navbar-collapse ms-lg-5 LandingNav ${show ? 'show' : ''}`}>
      <div className="Close d-lg-none" onClick={() => setShow(false)}>
        <Icon name="close" />
      </div>
      <ul className="navbar-nav ms-lg-5 me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          {/* <NavLink
            className={({ isActive }) =>
              isActive ? 'nav-link nav-link-active' : 'nav-link'
            }
            aria-current="page"
            to={`/${PROPERTIES}`}
          >
            Products
          </NavLink> */}
          <div className="dropdown">
            <button
              className="btn btn-outline-secondary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Products
              <Icon name="small-caret-down" color="#222222" />
            </button>
            <ul className="dropdown-menu">
              {productRoutes.map((routes) => (
                <li key={uuidv4()}>
                  <button className="btn btn-link dropdown-item">
                    <div className="d-flex justify-content-between">
                      <div>{routes.title}</div>
                      <div>
                        <Icon name="caret-right" color="#222222" />
                      </div>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) =>
              isActive ? 'nav-link nav-link-active' : 'nav-link'
            }
            end
            to={'/sssdd'}
          >
            Blogs
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) =>
              isActive ? 'nav-link nav-link-active' : 'nav-link'
            }
            end
            to="/ss"
          >
            Contact
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) =>
              isActive ? 'nav-link nav-link-active' : 'nav-link'
            }
            end
            to="/ss"
          >
            About
          </NavLink>
        </li>
      </ul>

      <div className="mb-lg-0  ms-lg-3 Login">
        <Button
          label="Login"
          size="small"
          onClick={() => route(`/${SIGNIN}`)}
        />
      </div>
      <div className="mb-lg-0 ms-lg-3">
        <Button
          type="outline"
          label="Register"
          size="small"
          onClick={() => route(`/${GET_STARTED}`)}
        />
      </div>
      {/* </form> */}
    </div>
  )
}
export default React.memo(LandingPageNav)
