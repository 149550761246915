import React, { useEffect } from 'react'
import './App.scss'
import { AuthProvider } from './contexts/authContext'
import Routing from './routing'
// import { GoogleOAuthProvider } from '@react-oauth/google'
import ToastComponent, { toast } from './components/Toast'
import { ErrorProvider } from './contexts/errorContext'
import { GlobalProvider } from './contexts/globalContext'

const App: React.FC = () => {
  const handleOnline = (): string => toast.success('Connected')
  const handleOffline = (): string =>
    toast('Check your internet connection', { position: 'bottom-left' })

  useEffect(() => {
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)
    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  })
  return (
    // <GoogleOAuthProvider
    //   clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}
    // >
    <ErrorProvider>
      <AuthProvider>
        <GlobalProvider>
          <Routing />
          <ToastComponent />
        </GlobalProvider>
      </AuthProvider>
    </ErrorProvider>
    // </GoogleOAuthProvider>
  )
}

export default App
