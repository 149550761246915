import React, { FC } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthState } from '../contexts/authContext'
import { HOME, SIGNIN } from './navigation'

export const Protected: FC<{ children: JSX.Element }> = ({ children }) => {
  const { isAuthenticated } = useAuthState()
  const location = useLocation()

  if (!isAuthenticated) {
    return <Navigate to={`/${SIGNIN}`} state={{ from: location }} replace />
  } else {
    return children
  }
}

export const UnProtected: FC<{ children: JSX.Element }> = ({ children }) => {
  const { isAuthenticated } = useAuthState()

  if (isAuthenticated) {
    return <Navigate to={`/${HOME}`} replace />
  } else {
    return children
  }
}
