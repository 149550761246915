import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useRef,
  useState,
} from 'react'
import Button from '../../../../components/Button'
import { Icon } from '../../../../components/Icons'
import Modal, { IModal } from '../../../../components/Modal'
import './styles.scss'
import PhoneInput from '../../../../components/PhoneInput'
import { useAuthDispatch, useAuthState } from '../../../../contexts/authContext'
import intlTelInput, { Plugin } from 'intl-tel-input'
import { toast } from '../../../../components/Toast'
import Otp from '../../../../components/Otp'

interface ModalProps {
  toggle: () => void
  callback?: () => any
}
const UpdatePhoneModal: ForwardRefRenderFunction<IModal, ModalProps> = (
  { ...props },
  ref
) => {
  const [step, setStep] = useState(1)
  const { verifyPhone, verifyOtp } = useAuthDispatch()
  const { user } = useAuthState()
  const [valid, setValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [otpValid, setOtpValid] = useState(false)
  const [phone, setPhone] = useState('')
  const [otp, setOtp] = useState('')

  const phoneInput = useRef<Plugin>()
  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value.length >= 7) setValid(true)
  }

  const handleClick = (): void => {
    onClick()
  }

  useEffect(() => {
    if (phoneInput.current) {
      phoneInput.current.setNumber = user.phone || ''
    }
  }, [])

  const onClick = async (): Promise<void> => {
    try {
      if (phoneInput?.current) {
        setLoading(true)
        const phoneNumber = phoneInput.current.getNumber()
        await verifyPhone({ phone: phoneNumber })

        setPhone(phoneNumber)
        nextStep()
      }
    } catch (error) {
      const err = error as Error
      toast.error(err.message || 'An error occured please try again later')
    }
    setLoading(false)
  }
  useEffect(() => {
    const input = document.querySelector('#phone')
    if (input) {
      phoneInput.current = intlTelInput(input, {
        // any initialisation options go here
        utilsScript:
          'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
      })
    }
  }, [])

  const onChange = (value: string): void => {
    if (value.length === 6) {
      setOtp(value)
      setOtpValid(true)
    } else {
      setOtpValid(false)
    }
  }

  const onSubmitOtp = (): void => {
    validateOtp()
  }

  const validateOtp = async (): Promise<void> => {
    try {
      if (phone) {
        setLoading(true)
        await verifyOtp({ phone, otp })

        nextStep()
      }
    } catch (error) {
      const err = error as Error
      toast.error(err.message || 'Can not validate OTP at the moment')
    }
    setLoading(false)
  }

  const handleResend = (): void => {
    resend()
  }

  const resend = async (): Promise<void> => {
    try {
      if (phone) {
        await verifyPhone({ phone })
        toast.success('Another OTP has been sent to your number')
      }
    } catch (error) {
      const err = error as Error
      toast.error(
        err.message || 'Can not resent OTP at the moment, please try later'
      )
    }
  }

  const nextStep = (): void => {
    if (step === 3) {
      setStep(1)
      return props.toggle()
    }
    setStep((prev) => prev + 1)
  }
  // const prevStep = (): void => {
  //   setStep((prev) => {
  //     if (prev === 1) {
  //       props.toggle()
  //       return prev
  //     }
  //     return prev - 1
  //   })
  // }

  const closeModal = (): void => {
    if (props.callback) props.callback()
    props.toggle()
  }

  const step1 = (): JSX.Element => (
    <div className="UpdatePhoneBody">
      <div className="mb-4 text-center">
        <Icon name="phone" />
      </div>
      <div className="mb-4 text-center">
        <p style={{ fontWeight: 600 }}>Update your phone number</p>
        <small style={{ fontWeight: 500, color: '#979797' }}>
          We”ll send you a code via SMS to your new phone number
        </small>
      </div>

      <form className="mb-4">
        <div className="mb-5">
          <label className="mb-2">Enter new phone number* </label>
          <PhoneInput
            id="phone"
            name="phone"
            onChange={onInputChange}
            ref={phoneInput}
          />
        </div>
        <div>
          <Button
            loading={loading}
            onClick={handleClick}
            label="Send code"
            disabled={!valid}
          />
        </div>
      </form>
    </div>
  )

  const step2 = (): JSX.Element => (
    <div className="UpdatePhoneBody">
      <div className="mb-4 text-center">
        <Icon name="phone" />
      </div>
      <div className="mb-4 text-center">
        <p style={{ fontWeight: 600 }}>Update your phone number</p>
        <small style={{ fontWeight: 500, color: '#979797' }}>
          Please enter the OTP sent to your phone number below
        </small>
      </div>

      <form className="mb-4">
        <div className="mb-5">
          <Otp onChange={onChange} length={6} otpValid={otpValid} />
        </div>
        <div className="OTP-button">
          <Button
            onClick={onSubmitOtp}
            loading={loading}
            label="Confirm code"
            disabled={!otpValid}
          />
        </div>
      </form>
      <div className="mb-4 d-flex justify-content-center align-items-center Resend">
        <small style={{ fontWeight: 500, color: '#979797', marginRight: 10 }}>
          Didn’t receive a code ?{' '}
        </small>
        <Button label="Resend code" type="link" onClick={handleResend} />
      </div>
    </div>
  )

  const step3 = (): JSX.Element => (
    <div className="UpdatePhoneBody">
      <div className="mb-4 text-center">
        <Icon name="phone-updated" />
      </div>
      <div className="mb-4 text-center">
        <p style={{ fontWeight: 600 }}>Phone number updated </p>
        <small style={{ fontWeight: 500, color: '#979797' }}>
          Your phone number has been successfully updated to following
          {phone}
        </small>
      </div>

      <div className="mb-4">
        <div>
          <Button onClick={closeModal} label="Back to profile" />
        </div>
      </div>
    </div>
  )

  const UpdatePhoneFlow: { [key: number]: JSX.Element } = {
    1: step1(),
    2: step2(),
    3: step3(),
  }
  return (
    <>
      <Modal ref={ref}>
        <div className="UpdatePhone">
          <div className="UpdatePhoneHeader d-flex justify-content-end">
            <Icon
              name="close"
              onClick={() => {
                // setStep(1)
                props.toggle()
              }}
            />
          </div>
          <>{UpdatePhoneFlow[step]}</>
        </div>
      </Modal>
    </>
  )
}

export default forwardRef(UpdatePhoneModal)
