import React, { ChangeEvent, CSSProperties } from 'react'
import { v4 as uuidv4 } from 'uuid'
import './styles.scss'

export interface TextInputProps {
  label: string
  placeholder?: string
  id: string
  name: string
  onChange: (name: string, val: string) => void
  type?: string
  isRequired?: boolean
  passwordScheme?: { [key: string]: (val: string) => boolean }
  style?: CSSProperties
  errorText?: string
  valid?: boolean
  value?: string
  defaultValue?: string
  disabled?: boolean
  selectOptions?: Array<{
    label: string
    value: any
  }>
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  placeholder = 'Enter here',
  id,
  name,
  onChange,
  type = 'text',
  isRequired = false,
  passwordScheme,
  valid = true,
  errorText = '',
  style = {},
  value = '',
  defaultValue,
  disabled = false,
  selectOptions,
}) => {
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ): void => {
    onChange(name, e.target.value)
  }

  const renderInput = (): JSX.Element => {
    if (type === 'textArea') {
      return (
        <div>
          <div className="mb-5 Input-with-label">
            <label htmlFor={id} className="form-label">
              {label}
              {isRequired && <span className="Required">*</span>}
            </label>
            <textarea
              onChange={handleChange}
              className="form-control"
              id={id}
              style={{
                ...style,
                ...(!valid ? { borderColor: '#ff0066' } : {}),
              }}
              name={name}
              placeholder={placeholder}
              required={isRequired}
              defaultValue={defaultValue}
            ></textarea>
            <div className={`invalid-feedback ${!valid ? 'd-block' : ''}`}>
              {errorText}
            </div>
          </div>
        </div>
      )
    }

    if (type === 'select') {
      return (
        <div>
          <div className="mb-5 Input-with-label">
            <label htmlFor={id} className="form-label">
              {label}
              {isRequired && <span className="Required">*</span>}
            </label>
            <select
              onChange={handleChange}
              className="form-select"
              aria-label="Select country"
            >
              {!defaultValue && !value && (
                <option selected>{'Select Country'}</option>
              )}
              {value && <option selected>{value}</option>}
              {selectOptions &&
                selectOptions.length > 0 &&
                selectOptions?.map((option) => (
                  <option key={uuidv4()} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
            <div className={`invalid-feedback ${!valid ? 'd-block' : ''}`}>
              {errorText}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className="mb-5 Input-with-label">
          <label htmlFor={id} className="form-label">
            {label}
            {isRequired && <span className="Required">*</span>}
          </label>
          <input
            onChange={handleChange}
            type={type}
            className="form-control"
            id={id}
            style={{ ...style, ...(!valid ? { borderColor: '#ff0066' } : {}) }}
            name={name}
            placeholder={placeholder}
            required={isRequired}
            defaultValue={defaultValue}
            disabled={disabled}
          />
          <div className={`invalid-feedback ${!valid ? 'd-block' : ''}`}>
            {errorText}
          </div>
        </div>
        {type === 'password' && passwordScheme && (
          <div
            className="d-flex"
            style={{
              flexWrap: 'wrap',
              marginTop: '-24px',
              marginBottom: '24px',
            }}
          >
            {Object.keys(passwordScheme).map((key) => (
              <div className="me-2 mb-2 Scheme" key={uuidv4()}>
                <p
                  style={{
                    whiteSpace: 'nowrap',
                    ...(passwordScheme[key](value) ? { color: '#41ce8e' } : {}),
                  }}
                >
                  {key}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      {renderInput()}
      {/* <div className="mb-5 Input-with-label">
        <label htmlFor={id} className="form-label">
          {label}
          {isRequired && <span className="Required">*</span>}
        </label>
        <input
          onChange={handleChange}
          type={type}
          className="form-control"
          id={id}
          style={{ ...style, ...(!valid ? { borderColor: '#ff0066' } : {}) }}
          name={name}
          placeholder={placeholder}
          required={isRequired}
          defaultValue={defaultValue}
        />
        <div className={`invalid-feedback ${!valid ? 'd-block' : ''}`}>
          {errorText}
        </div>
      </div>
      {type === 'password' && passwordScheme && (
        <div
          className="d-flex"
          style={{ flexWrap: 'wrap', marginTop: '-24px', marginBottom: '24px' }}
        >
          {Object.keys(passwordScheme).map((key) => (
            <div className="me-2 mb-2 Scheme" key={uuidv4()}>
              <p
                style={{
                  whiteSpace: 'nowrap',
                  ...(passwordScheme[key](value) ? { color: '#41ce8e' } : {}),
                }}
              >
                {key}
              </p>
            </div>
          ))}
        </div>
      )} */}
    </div>
  )
}

export default TextInput
