import React from 'react'
import { Outlet } from 'react-router-dom'
import BackNav from '../BackNav'
import { Icon } from '../Icons'
import './styles.scss'

interface SignupWrapperProps {
  showBackButton?: boolean
  backButtonLabel?: string
  backButtonLink?: string
}

const SignupWrapper: React.FC<SignupWrapperProps> = ({
  showBackButton,
  backButtonLabel,
  backButtonLink,
}) => {
  return (
    <div className="container-fluid">
      <div className="row" style={{ height: '100vh', overflow: 'auto' }}>
        <div className="col-lg-6 order-2 order-lg-1" style={{ padding: 0 }}>
          <div className="Signup-container-comp">
            {showBackButton && (
              <BackNav
                className="Back-button"
                label={backButtonLabel ?? 'Back'}
                link={backButtonLink}
              />
            )}
            <Outlet />
          </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2" style={{ padding: 0 }}>
          <div className="Signup-container-bg">
            <div className="container" style={{ height: 'inherit' }}>
              <div className="text-center">
                <Icon name="logo" />
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ height: 'inherit' }}
              >
                <div className="align-self-center">
                  <p className="text-center mb-0">Say hello</p>
                  <p className="text-center">to future of tokenization</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(SignupWrapper)
