import React from 'react'
import './styles.scss'
import { Icon } from '../Icons'
import { Link } from 'react-router-dom'
const webUrl = 'https://fracxn.com'

const Footer: React.FC = () => {
  return (
    <div className="Footer pt-5 pb-3">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div>
              <Icon name="logo" />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-between Items">
            <div className="mt-5 Menu">
              <p>MENU</p>
              <ul>
                <li>
                  <a href={`${webUrl}/terms.php`}>Terms & Conditions</a>
                </li>
                <li>
                  <a href={`${webUrl}/privacy.php`}>Privacy Policy</a>
                </li>
                <li>
                  <a href={`${webUrl}/cookie.php`}>Cookie Policy</a>
                </li>
                <li>
                  <a href={`${webUrl}/consent.php`}>E-Sign Content</a>
                </li>
              </ul>
            </div>

            <div className="align-self-end Extra-menu">
              <ul>
                <li>
                  <Link to="">Connect with us</Link>
                </li>
                <li>
                  <Link to="">Hello@fracxn.com</Link>
                </li>
              </ul>
              <div
                className="d-flex justify-content-between"
                style={{ width: 168 }}
              >
                <Icon name="instagram" />
                <Icon name="linkedin" />
                <Icon name="twitter" />
                <Icon name="whatsapp" />
              </div>
            </div>
          </div>
          <p className="mt-3 text-light">
            &copy; {new Date().getFullYear()} fracxn.All rights reserved
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
