/* eslint-disable @typescript-eslint/restrict-template-expressions */
export const capitalize = (text: string): string =>
  text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()

export const getQueryString = (data: { [key: string]: any }): string => {
  const keys = Object.keys(data)
  let newString = ''
  keys.forEach((key, index) => {
    if (index === 0) {
      if (data[key]) {
        newString = `?${key}=${data[key]}`
      }
    } else if (data[key] !== undefined && data[key] !== null) {
      if (newString) {
        newString = `${newString}&${key}=${data[key]}`
      } else {
        newString = `?${key}=${data[key]}`
      }
    }
  })
  return newString
}
