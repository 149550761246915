import { useSpring, animated } from '@react-spring/web'
import React, { FC } from 'react'
import { Icon } from '../Icons'
import './styles.scss'

const Loader: FC = () => {
  const { x } = useSpring({
    loop: true,
    from: { x: 0 },
    x: 1,
    config: { duration: 1200 },
  })
  return (
    <div className="Loader d-flex justify-content-center align-items-center">
      <animated.div
        style={{
          opacity: x.to({ range: [0.5, 1], output: [0.5, 0.7, 1] }),
          scale: x.to({
            range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
            output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
          }),
        }}
      >
        <Icon name="logo" />
      </animated.div>
    </div>
  )
}

export default Loader
