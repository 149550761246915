import React, { FormEvent, useEffect, useState } from 'react'
import SignupTitleAndDesc from '../../components/SignupTitleAndDesc'
import './styles.scss'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useAuthDispatch } from '../../contexts/authContext'
import { toast } from '../../components/Toast'
import { SIGNIN } from '../../routing/navigation'
import { registrationValidations, validateInput } from '../../utils/validations'

const ForgotPassword: React.FC = () => {
  const { validatePasswordReset, resetPassword } = useAuthDispatch()

  const route = useNavigate()
  const [seachParams] = useSearchParams()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ [key: string]: string }>({})
  const [validated, setValidated] = useState(false)
  const [validationResult, setValidationResult] = useState<{
    [key: string]: string
  }>({})
  const [token, setToken] = useState('')

  useEffect(() => {
    const tokenParam = seachParams.get('token')
    validatePasswordReset(tokenParam as string)
      .then((resp) => {
        setToken(tokenParam as string)
      })
      .catch((err) => {
        const error = err as Error
        toast.error(error.message)
        route('/')
      })
  }, [])

  const validations = {
    password: registrationValidations.password,
    confirmPassword: registrationValidations.confirmPassword,
  }

  const validate = (onSubmit = true): boolean => {
    const validationResponse = validateInput({
      data,
      onSubmit,
      validations,
    })
    setValidationResult((prev) => {
      return { ...prev, ...validationResponse.validationResultData }
    })
    return validationResponse.validated
  }
  useEffect(() => {
    let delayDebounceFn: any = null

    delayDebounceFn = setTimeout(() => {
      const result = validate(false)
      setValidated(result)
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [data])

  const onInputChange = (name: string, value: string): void => {
    setData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      }
    })
  }

  const setPassword = async (): Promise<void> => {
    setLoading(true)

    try {
      await resetPassword({
        password: data.password,
        passwordConfirmation: data.confirmPassword,
        token,
      })
      toast.success('Password set succesfully')
      route(SIGNIN)
    } catch (error) {
      const err = error as Error
      toast.error(err.message)
    }
    setLoading(false)
  }

  const submit = (e: FormEvent): void => {
    e.preventDefault()
    setPassword()
  }

  const passwordScheme = {
    '8 characters': (value: string) => !(value.length < 8),
    '1 special character': (value: string) =>
      !!/[!@#$%^&*(),.?":{}|<>]/.test(value),
    '1 uppercase letter': (value: string) => !!/[A-Z]/.test(value),
    '1 lowercase letter': (value: string) => !!/[a-z]/.test(value),
  }
  return (
    <>
      <SignupTitleAndDesc
        title="Set new password"
        description="Your new password should be different from old password."
        type="STD2"
      />
      <form onSubmit={submit}>
        <TextInput
          label="Password"
          placeholder="Enter your password"
          id="new-password"
          name="password"
          type="password"
          value={data.password}
          onChange={onInputChange}
          isRequired
          passwordScheme={passwordScheme}
          errorText={validationResult.password}
          valid={!validationResult.password}
        />

        <TextInput
          label="Confirm Password"
          placeholder="Confirm your password"
          id="confirm-new-password"
          name="confirmPassword"
          type="password"
          onChange={onInputChange}
          isRequired
          errorText={validationResult.confirmPassword}
          valid={!validationResult.confirmPassword}
        />

        <Button
          disabled={!validated}
          loading={loading}
          label="Reset Password"
        />
      </form>
    </>
  )
}

export default ForgotPassword
