/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useState, useEffect } from 'react'
import './styles.scss'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import {
  BANK_ACCOUNT,
  CRYPTO_TRANSACTION,
  CRYPTO_WALLET,
  FIAT_TRANSACTION,
  FIAT_WALLET,
  PROFILE,
  REFERRAL,
  SAVED_PROPERTIES,
  SETTINGS,
  WALLET,
} from '../../routing/navigation'
import { Icon, IconName } from '../Icons'
import NavDropdown from '../NavDropdown'

const InnerNav: React.FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation()
  const state = location.state as Record<string, any>
  const route = useNavigate()
  const path = location.pathname

  const [data, setData] = useState([
    {
      to: `/${PROFILE}`,
      linkName: 'My Profile',
      icon: 'profile-icon',
    },
    {
      to: `/${WALLET}`,
      linkName: 'Wallet',
      icon: 'wallet-icon',
      isDropdown: true,
      active: false,
      dropdownRoutes: [
        {
          linkName: 'Fiat wallet',
          to: `/${FIAT_WALLET}`,
        },
        {
          linkName: 'Crypto wallet',
          to: `/${CRYPTO_WALLET}`,
        },
      ],
    },
    {
      to: `/${BANK_ACCOUNT}`,
      linkName: 'Bank account',
      icon: 'bank-icon',
    },
    {
      to: `/${SAVED_PROPERTIES}`,
      linkName: 'Saved',
      icon: 'saved-icon',
    },
    {
      to: '/transactions',
      linkName: 'Transaction',
      icon: 'transaction-icon',
      isDropdown: true,
      active: false,
      dropdownRoutes: [
        {
          linkName: 'Fiat transactions',
          to: `/${FIAT_TRANSACTION}`,
        },
        {
          linkName: 'Crypto transaction',
          to: `/${CRYPTO_TRANSACTION}`,
        },
      ],
    },
    {
      to: `/${REFERRAL}`,
      linkName: 'Referral',
      icon: 'referral-icon',
    },
    {
      to: '/support',
      linkName: 'Support',
      icon: 'support-icon',
    },
    {
      to: `/${SETTINGS}`,
      linkName: 'Settings',
      icon: 'setting-icon',
    },
  ])
  useEffect(() => {
    if (state?.sideNav) {
      setData(state.sideNav)
    }
  }, [])
  const toggleDropdown = (to: string, name: string): void => {
    // if (!active) route(to, { state: { ...state, [name]: true } })
    const newData = data.map((dataMap) => {
      if (dataMap.isDropdown) {
        if (dataMap.linkName === name) {
          dataMap.active = !dataMap.active
        }
      }
      return dataMap
    })
    setData(newData)
  }

  return (
    <>
      <div className="Inner-nav-container d-md-none">
        <div className="container">
          <div className="Inner-nav">
            <ul>
              {data.map((dat) => (
                <li key={uuidv4()}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? 'nav-link nav-link-active' : 'nav-link'
                    }
                    end
                    aria-current="page"
                    to={dat.to}
                  >
                    {dat.linkName}
                  </NavLink>{' '}
                </li>
              ))}
            </ul>
          </div>
          {children}
        </div>
      </div>

      <div className="Inner-nav-container d-none d-md-block">
        <div className="row">
          <div className=" col-md-3 col-lg-2 me-0 pe-0 Side-nav-container">
            <div className="Side-nav">
              <ul>
                {data.map((dat) => (
                  <li key={uuidv4()}>
                    {!dat.isDropdown ? (
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? 'nav-link nav-link-active' : 'nav-link'
                        }
                        end
                        aria-current="page"
                        to={dat.to}
                      >
                        <Icon
                          color={path.includes(dat.to) ? '#0b5ca8' : undefined}
                          name={dat.icon as IconName['name']}
                        />{' '}
                        {dat.linkName}
                      </NavLink>
                    ) : (
                      <NavDropdown
                        toggle={toggleDropdown}
                        path={path}
                        data={dat}
                        allData={data}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-9 col-lg-10 ms-0 ps-0">
            <div className=" main  ps-5 pe-5">{children}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(InnerNav)
