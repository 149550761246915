const networks = {
  MUMBAI: {
    rpc: 'https://polygon-testnet.public.blastapi.io',
    currencyName: 'MATIC',
    currencySymbol: 'MATIC',
    chainId: 80001,
    chainName: 'mumbai',
    currencies: {
      usdc: {
        contractAddress: '0xA74b9D306F1275D052149597b02df1641b6F30c0',
      },
      usdt: {
        contractAddress: '0x88aD56e3ef1d0524349163CE97AB0E11C07279EF',
      },
    },
  },
}

export default networks
