export const SIGNUP = 'signup'
export const GET_STARTED = 'getstarted'
export const SIGNIN = 'signin'
export const FORGOT_PASSWORD = 'forgot-password'
export const NEW_PASSWORD = 'new-password'
export const CHECK_EMAIL = 'email/check-email'
export const EMAIL_VERIFIED = 'email-verification'
export const VERIFY_EMAIL = 'email/verify-email'
export const VERIFY_PHONE = 'verify/phone'
export const VERIFY_OTP = 'verify/otp'
export const HOME = 'home'
export const SETTINGS = 'home/settings'
export const PROFILE = 'home/profile'
export const PROPERTIES = 'home/properties'
export const SAVED_PROPERTIES = 'home/saved-properties'
export const INVESTMENTS = 'home/investments'
export const WALLET = 'home/wallet'
export const FIAT_WALLET = 'home/wallet/fiat'
export const CRYPTO_WALLET = 'home/wallet/crypto'
export const CRYPTO_TRANSACTION = 'home/transactions/crypto'
export const FIAT_TRANSACTION = 'home/transactions/fiat'
export const KYC = 'kyc'
export const REFERRAL = 'home/referral'
export const BANK_ACCOUNT = 'home/bank-account'
