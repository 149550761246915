/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from '../Button'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import Card from '../Card'
import PayOptionButton from '../PayOptionButton'
import TextInputGroup from '../TextInputGroup'
import { Icon } from '../Icons'
import { v4 as uuidv4 } from 'uuid'
import './styles.scss'
import { validateInput } from '../../utils/validations'
import API from '../../network/api'
import { useErrorState } from '../../contexts/errorContext'
import { useAuthState } from '../../contexts/authContext'
import { useGlobalDispatch, useGlobalState } from '../../contexts/globalContext'
import { toast } from '../Toast'
import { IError } from '../../interface/error'
import { IWalletResponse } from '../../interface/wallet'
import FiatDepositModal from '../../pages/FiatWallet/components/FiatDepositModal'
import { IModal } from '../Modal'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CryptoDepositModal from '../../pages/CryptoWallet/components/CryptoDepositModal'
import cryptoService from '../../service/crypto'
import Loader from '../Loader'
import { ethers } from 'ethers'
import CryptoSuccessModal from '../CryptoSuccessModal'
import { setAmount } from '../../utils/number'

// const cryptoService = new CryptoService()

const PaymentOptionCard: FC<{
  percentageSold: number
  endTime: string
  currency: string
  price: number
  id: string
  totalInvestors: number
  callBack?: () => void
}> = ({ ...props }) => {
  const route = useNavigate()

  const { addError } = useErrorState()
  const { getWallet } = useGlobalDispatch()
  const { wallet, settings } = useGlobalState()
  const { user } = useAuthState()

  const [searchParams, setSearchParams] = useSearchParams()

  const cardAuthCode = searchParams.get('authcode')
  const cardTransactionType = searchParams.get('type')

  const [currentPage, setCurrentPage] = useState('SelectCurrency')
  const [toConfirm, setToConfirm] = useState(false)
  const [paymentOption, setPaymentOption] = useState<
    'cryptoWallet' | 'fiatWallet'
  >()
  const [data, setData] = useState<{ [key: string]: string }>({ amount: '' })
  const [validationResult, setValidationResult] = useState<{
    [key: string]: string
  }>({})
  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingWallet, setLoadingWallet] = useState(false)
  const [currentCurrency, setCurrentCurrency] = useState('')
  const [fiatBalance, setFiatBalance] = useState('')
  const [cryptoBalance, setCryptoBalance] = useState('')
  const [contract, setContract] = useState<ethers.Contract>()
  const [insufficientBalance, setInsufficientBalance] = useState(false)
  const [showDeposit, setShowFiatDeposit] = useState(false)
  const [showCryptoDeposit, setShowCryptoDeposit] = useState(false)
  const [cardSuccess, setCardSuccess] = useState(false)
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [paymentCurrency, setPaymentCurrent] = useState('')
  const [conversion, setConversion] = useState({
    from: '',
    to: '',
    amount: 0,
    fromAmount: 0,
  })
  const [gas, setGas] = useState<any>()
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [successCurrency, setSuccessCurrency] = useState('')
  const [successAmount, setSuccessAmount] = useState('')

  const CryptoSuccessModalRef = useRef<IModal>(null)
  const ModalRef = useRef<IModal>(null)
  const CryptoDepositModalRef = useRef<IModal>(null)

  useEffect(() => {
    getTransactionStatus()
    cryptoService.getUsdtInfo(user.publicAddress).then((usdtInfo) => {
      setCryptoBalance(usdtInfo.usdtBalance)
      setContract(usdtInfo.usdtContract)
    })
  }, [])

  useEffect(() => {
    if (showSuccessModal) CryptoSuccessModalRef.current?.toggle()
  }, [showSuccessModal])

  useEffect(() => {
    ;(async function () {
      if (contract) {
        const filter = contract.filters.Transfer(null, user.publicAddress)
        const filterFrom = contract.filters.Transfer(user.publicAddress, null)

        contract.on(filter, (from, to, amount, event) => {
          // The to will always be "address"
          console.log(`I got ${ethers.utils.formatEther(amount)} from ${from}.`)
          contract
            .balanceOf(user.publicAddress)
            .then((balance: any) => setCryptoBalance(balance))
            .catch((err: any) => console.log(err))

          setShowSuccessModal(true)
          setSuccessMessage(
            `The transfer of ${amount} has been made to your wallet address from ${from}`
          )
          setSuccessCurrency(currentCurrency)
          setSuccessAmount(ethers.utils.formatEther(amount))
        })
        contract.on(filterFrom, (from, to, amount, event) => {
          // The to will always be "address"
          console.log(
            `I transferred ${ethers.utils.formatEther(amount)} to ${to}.`
          )
          contract
            .balanceOf(user.publicAddress)
            .then((balance: any) => setCryptoBalance(balance))
            .catch((err: any) => console.log(err))

          setShowSuccessModal(true)
          setSuccessMessage(
            `The transfer of ${ethers.utils.formatEther(
              amount
            )} has been made out of your wallet address to ${to}`
          )
          setSuccessCurrency(currentCurrency)
          setSuccessAmount(ethers.utils.formatEther(amount))
        })
      }
    })()
  }, [contract])

  useEffect(() => {
    ;(async function () {
      if (cryptoService.signer) {
        const gasPrice = await cryptoService.signer.getGasPrice()
        setGas(ethers.utils.formatEther(gasPrice))
      }
    })()
  }, [cryptoBalance])

  const getTransactionStatus = async (): Promise<void> => {
    if (cardAuthCode && cardTransactionType) {
      if (cardTransactionType === 'accountFunding') {
        setPaymentLoading(true)
        try {
          const response = await API.verifyCardDeposit(cardAuthCode)
          if (response?.data?.status?.toLowerCase() === 'success') {
            setCardSuccess(true)
            setData({ ...data, amount: response.data.amount.toFixed(2) })

            setPaymentCurrent(response.data.currency)
            setShowFiatDeposit(true)
            setCurrentPage('EnterAmount')
            setPaymentOption('fiatWallet')
            setValidated(true)

            try {
              await getWallet()
            } catch (error) {
              // do nothing
            }
          } else {
            toast.error('Transaction might have failed')
          }
        } catch (err) {
          const error = err as IError
          toast.error(error.message || 'Deposit failed')
        }
        searchParams.delete('authcode')
        searchParams.delete('type')
        setSearchParams(searchParams)
        setPaymentLoading(false)
      }
    }
  }

  useEffect(() => {
    if (showDeposit) ModalRef.current?.toggle()
  }, [showDeposit])

  useEffect(() => {
    if (showCryptoDeposit) CryptoDepositModalRef.current?.toggle()
  }, [showCryptoDeposit])

  const toggleDeposit = (): void => {
    ModalRef.current?.toggle()
    setShowFiatDeposit(false)
    setInsufficientBalance(false)
    setData({ ...data, amount: '' })
  }

  const toggleCryptoDeposit = (): void => {
    CryptoDepositModalRef.current?.toggle()
    setShowCryptoDeposit(false)
  }

  useEffect(() => {
    if (!wallet) {
      setLoadingWallet(true)
      getWallet()
        .then((walletResponse) => {})
        .catch((err) => {
          console.log(err)
        })
      setLoadingWallet(false)
    }
  }, [wallet])

  useEffect(() => {
    if (showSuccessModal) CryptoSuccessModalRef.current?.toggle()
  }, [showSuccessModal])

  const toggleCryptoSuccess = (): void => {
    CryptoSuccessModalRef.current?.toggle()

    setShowSuccessModal(false)
  }

  const convertCurrency = async (
    from: string,
    to: string,
    amount: number
  ): Promise<void> => {
    try {
      const response = await API.convertAnyCurrency(from, to, amount)
      setConversion({
        from,
        to,
        amount: response.data.convertedAmount,
        fromAmount: amount,
      })
    } catch (error) {
      const err = error as IError
      toast.error(err.message || 'Unable to convert currency at this time')
    }
  }

  const convertCryptoCurrency = async (
    from: string,
    to: string,
    amount: number
  ): Promise<void> => {
    try {
      const response = await API.getCryptoRate(from, 'USDT')
      const rate = response.data.rates
      if (rate.USDT) {
        const convertedAmount = Number(amount || 0) * rate.USDT

        setConversion({
          from,
          to: 'USDT',
          amount: convertedAmount,
          fromAmount: amount,
        })
      }
    } catch (error) {
      const err = error as IError
      toast.error(err.message || 'Unable to convert currency at this time')
    }
  }

  useEffect(() => {
    if (currentCurrency) {
      if (paymentOption === 'fiatWallet') {
        const fiatWallet = wallet?.filter(
          (purse) => purse.currency.code === currentCurrency
        )
        if (fiatWallet) {
          const balance = fiatWallet[0].available_balance
          setFiatBalance(Number(balance).toFixed(2))
        }
      } else {
        // get crypto balance
        ;(async function (): Promise<void> {
          switch (currentCurrency) {
            case 'USDT': {
              const usdtInfo = await cryptoService.getUsdtInfo(
                user.publicAddress
              )
              setCryptoBalance(usdtInfo.usdtBalance)
              setContract(usdtInfo.usdtContract)
              break
            }
            case 'USDC': {
              const usdcInfo = await cryptoService.getUsdcInfo(
                user.publicAddress
              )
              setCryptoBalance(usdcInfo.usdcBalance)
              setContract(usdcInfo.usdcContract)
              break
            }
          }
        })()
      }
    }
  }, [currentCurrency, wallet])

  useEffect(() => {
    if (paymentOption === 'fiatWallet') {
      if (!wallet) setCurrentCurrency('USD')
      if (wallet) setCurrentCurrency(wallet[0].currency.code)
    } else if (paymentOption === 'cryptoWallet') {
      setCurrentCurrency('USDT')
    }

    setConversion({
      from: '',
      to: '',
      amount: 0,
      fromAmount: 0,
    })
  }, [paymentOption])

  const SelectCurrency = (): JSX.Element => {
    return (
      <div className="Buy-card">
        <Card>
          <div className="card-body p-3 ps-4 pe-4">
            <h5 className="mb-3">Property price</h5>
            <div className="Buy-details mb-4">
              <h1 className="text-primary mb-3">
                {props.currency} {props.price.toLocaleString()}
              </h1>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-label="investment-progress"
                  style={{ width: `${props.percentageSold}%` }}
                  aria-valuenow={props.percentageSold}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
              <div className="mb-3">
                <small>
                  {`${parseInt(props.percentageSold.toString())}%`} funded
                </small>
              </div>
              <div>
                <small className="mt-5">{props.totalInvestors} investors</small>
              </div>
              <div>
                <small>Funding ends on {props.endTime}</small>
              </div>
            </div>
            <div className="Pay-options d-flex justify-content-between flex-wrap">
              <div className="mb-3">
                <PayOptionButton
                  // icon={{ name: 'cards-small' }}
                  label="Fiat wallet"
                  onClick={() => setPaymentOption('fiatWallet')}
                  isActive={paymentOption === 'fiatWallet'}
                />
              </div>

              <div className="mb-3">
                <PayOptionButton
                  // icon={{ name: 'metamask-small' }}
                  label="Crypto wallet"
                  onClick={() => setPaymentOption('cryptoWallet')}
                  isActive={paymentOption === 'cryptoWallet'}
                />
              </div>
            </div>

            <div
              className="d-flex justify-content-center align-items-end Next"
              style={{ height: '30%', width: '100%' }}
            >
              <Button
                label="Buy"
                disabled={!paymentOption}
                onClick={() => {
                  if (user && user.kycLevel < 2) {
                    // launch kyc modal
                    document.getElementById('kyc-button')?.click()
                    return
                  }
                  setCurrentPage('EnterAmount')
                  setInsufficientBalance(false)
                }}
              />
            </div>
          </div>
        </Card>
      </div>
    )
  }
  const amount = (data: Record<string, string>): string => {
    if (!data.amount) {
      if (insufficientBalance) setInsufficientBalance(false)
      return 'Enter amount'
    }
    if (Number(data.amount) === 0) {
      if (insufficientBalance) setInsufficientBalance(false)
      return 'Amount should be greater than 0'
    }
    if (
      paymentOption === 'fiatWallet' &&
      Number(data.amount) + Number(settings?.fracxnFees?.bid) >
        Number(fiatBalance)
    ) {
      setInsufficientBalance(true)
      return ' '
    }
    if (
      paymentOption === 'cryptoWallet' &&
      Number(data.amount) > Number(cryptoBalance)
    ) {
      setInsufficientBalance(true)
      return ' '
    }
    setInsufficientBalance(false)
    return ''
  }
  const validations = {
    amount,
  }

  const validate = (onSubmit = true): boolean => {
    setValidationResult({})
    const validationResponse = validateInput({
      data,
      onSubmit,
      validations,
    })
    setValidationResult((prev) => {
      return { ...prev, ...validationResponse.validationResultData }
    })
    return validationResponse.validated
  }

  useEffect(() => {
    let delayDebounceFn: any = null
    if (conversion.to || conversion.from) {
      setConversion({ from: '', to: '', amount: 0, fromAmount: 0 })
    }
    delayDebounceFn = setTimeout(() => {
      const result = validate(false)
      setValidated(result)
      if (Number(data.amount || 0) > 0) {
        convertCurrency(
          currentCurrency || paymentCurrency,
          props.currency,
          Number(data.amount)
        )
      }
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [data.amount])

  const onInputChange = useCallback(
    (name: string, value: string): void => {
      setData((prevData) => {
        return {
          ...prevData,
          [name]: value,
        }
      })
    },
    [data.amount]
  )

  const submit = (): void => {
    setToConfirm(true)
  }

  const confirmPayment = async (): Promise<void> => {
    setLoading(true)
    if (
      paymentOption === 'fiatWallet' &&
      Number(data.amount) + Number(settings?.fracxnFees?.bid) >
        Number(fiatBalance)
    ) {
      setInsufficientBalance(true)
    }
    try {
      if (paymentOption === 'cryptoWallet') {
        if (user.authMethod === 'metamask') {
          if (Number(cryptoBalance ?? 0) < Number(data.amount) + Number(gas)) {
            toast.error('Insufficient balance')
            setLoading(false)

            return
          }
          if (contract) {
            const contractWithSigner = contract.connect(cryptoService.signer)
            const amount = ethers.utils.parseUnits(data.amount, 6)

            const tx = await contractWithSigner.transfer(
              settings.cryptoWallet?.address,
              amount
            )

            const receipt = await tx.wait()
            toast.success(
              `Transaction confirmed in block ${
                receipt.blockNumber
              }, gas used: ${receipt.gasUsed.toString()}`
            )
          }
        }
      }
      await API.makeAbid(
        { amount: Number(data.amount), currencyCode: currentCurrency },
        props.id
      )
      setCurrentPage('SelectCurrency')
      setData({})
      toast.success('Bid submitted successfully')
      if (props.callBack) props.callBack()
    } catch (error: unknown) {
      const err = error as IError
      toast.error(err.message || 'An error occured, Please try later')
      addError(err)
    }
    setLoading(false)
  }

  const handleConfirmPayment = (): void => {
    confirmPayment()
  }

  const goBack = (): void => {
    if (toConfirm) {
      setToConfirm(false)
      return
    }
    setCurrentPage('SelectCurrency')
    setData({ ...data, amount: '' })
    setShowCryptoDeposit(false)
    setShowFiatDeposit(false)
  }

  const getCurrency = ():
    | {
        currencies: IWalletResponse['data']['0']['currency'][] | string[]
        currentCurrency: string
      }
    | undefined => {
    if (wallet) {
      if (paymentOption === 'fiatWallet') {
        return {
          currencies: wallet
            .filter((money) => money.currency.type === 'fiat')
            .map((purse) => purse.currency),
          currentCurrency: currentCurrency || 'USD',
        }
      } else {
        return {
          currencies: ['USDT', 'USDC'],
          currentCurrency: currentCurrency || 'USDT',
        }
      }
    }
  }

  const changeCurrency = (currency: string): void => {
    // if (paymentOption === 'fiatWallet') {
    convertCurrency(currency, props.currency, Number(data.amount || 0))
    // }
    // if (paymentOption === 'cryptoWallet') {
    //   convertCryptoCurrency(currency, props.currency, Number(data.amount || 0))
    // }
    setCurrentCurrency(currency)
  }

  const fundWallet = (): void => {
    if (paymentOption === 'fiatWallet') {
      setCardSuccess(false)
      setShowFiatDeposit(true)
    } else {
      setShowCryptoDeposit(true)
    }
  }

  const EnterAmount = (): JSX.Element => {
    return (
      <div className="Buy-card">
        <Card>
          <div className="d-flex">
            <div
              style={{ cursor: 'pointer', position: 'relative', zIndex: 1000 }}
            >
              <Icon name="back-arrow" onClick={goBack} />
            </div>
            {paymentOption === 'fiatWallet' && (
              <h3 className="Title">Select fiat Currency</h3>
            )}
          </div>

          <div className="card-body p-3 pt-2 ps-4 pe-4">
            <div className="Mini-loader">
              {loadingWallet && <Icon name="loader" />}
            </div>

            <div className="Make-payment">
              <p className="Balance">
                Balance:{' '}
                {paymentOption === 'fiatWallet' ? fiatBalance : cryptoBalance}
              </p>
              <TextInputGroup
                id={uuidv4()}
                name="amount"
                onChange={onInputChange}
                placeholder="0.00"
                errorText={validationResult.amount}
                valid={!validationResult.amount}
                currencies={getCurrency()?.currencies}
                currentCurrency={getCurrency()?.currentCurrency}
                isRequired
                disableInputDropdown={toConfirm}
                onChangeCurrency={changeCurrency}
                defaultValue={(data.amount as any) || ''}
              />

              {conversion.from && conversion.to && (
                <div className="text-center">
                  <Icon name="green-dot" />
                  <span className="Fx ms-1">
                    {conversion.fromAmount} {conversion.from} ={' '}
                    {setAmount(conversion.amount)} {conversion.to} &nbsp;
                    <br />
                    <small> Live exchange rate</small>
                  </span>
                </div>
              )}
              <div className="Payment-sum">
                {toConfirm && (
                  <>
                    {paymentOption === 'fiatWallet' ? (
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="mt-3">Fees</p>
                        <p className="mt-3  text-dark">
                          {settings?.fracxnFees?.bid ?? 0}
                        </p>
                      </div>
                    ) : (
                      paymentOption === 'cryptoWallet' && (
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mt-3">Gas</p>
                          <p className="mt-3  text-dark">{gas}</p>
                        </div>
                      )
                    )}
                    <div className="Border d-flex justify-content-between align-items-center">
                      <p className="pb-0">Balance after transfer</p>
                      <p className="pb-0 text-dark">
                        {paymentOption === 'fiatWallet'
                          ? Number(fiatBalance) -
                            (Number(data.amount) +
                              Number(settings?.fracxnFees?.bid))
                          : Number(cryptoBalance) -
                            (Number(data.amount) + Number(gas))}
                      </p>
                    </div>
                  </>
                )}
              </div>

              {insufficientBalance && (
                <div className="Insufficient">
                  <div>
                    <Icon name="insufficientCaution" />
                    <p className="p-4">
                      Your wallet balance is low please fund using available
                      method
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div
              className=" d-flex justify-content-center align-items-end Next"
              style={{ height: '25%', width: '100%' }}
            >
              {!insufficientBalance ? (
                <div>
                  {!toConfirm ? (
                    <Button
                      label="Continue"
                      disabled={!validated}
                      onClick={() => submit()}
                    />
                  ) : (
                    <Button
                      label="Confirm"
                      loading={loading}
                      onClick={() => handleConfirmPayment()}
                    />
                  )}
                </div>
              ) : (
                <Button
                  label="Fund Wallet"
                  loading={loading}
                  onClick={fundWallet}
                />
              )}
            </div>
            {/* </div> */}
          </div>
        </Card>
      </div>
    )
  }

  const flow = {
    SelectCurrency: SelectCurrency(),
    EnterAmount: EnterAmount(),
  }

  const Current = (
    <>
      {paymentLoading && <Loader />}
      {flow[currentPage as keyof typeof flow]}
    </>
  )
  return (
    <div className="Buy-card-container">
      {Current}
      {showDeposit && (
        <FiatDepositModal
          ref={ModalRef}
          currency={currentCurrency}
          paymentOption={paymentOption}
          amount={data.amount}
          cardSuccess={cardSuccess} // paymentdeposit success or pending page
          toggle={() => toggleDeposit()}
          paymentCurrency={paymentCurrency}
        />
      )}

      {showCryptoDeposit && (
        <CryptoDepositModal
          ref={CryptoDepositModalRef}
          toggle={() => toggleCryptoDeposit()}
          address={user.publicAddress}
        />
      )}
      {showSuccessModal && (
        <CryptoSuccessModal
          ref={CryptoSuccessModalRef}
          toggle={() => toggleCryptoSuccess()}
          message={successMessage}
          amount={successAmount}
          currency={successCurrency}
        />
      )}
    </div>
  )
}

export default React.memo(PaymentOptionCard)
