import React, { forwardRef, ForwardRefRenderFunction, useState } from 'react'
import Button from '../../../../components/Button'
// import Button from '../../../../components/Button'
import { Icon } from '../../../../components/Icons'
import Modal, { IModal } from '../../../../components/Modal'
import TextInput from '../../../../components/TextInput'
import TextInputGroup from '../../../../components/TextInputGroup'
// import TextInputGroup from '../../../../components/TextInputGroup'
import { v4 as uuidv4 } from 'uuid'
import './styles.scss'
// import TextInput from '../../../../components/TextInput'

interface ModalProps {
  toggle: () => void
}
const CryptoRentalWithdrawal: ForwardRefRenderFunction<IModal, ModalProps> = (
  { ...props },
  ref
) => {
  const [step, setStep] = useState(1)

  // const style = { borderRadius: 3, borderColor: '#c4c4c4' }

  const nextStep = (): void => {
    if (step === 4) {
      setStep(1)
      return props.toggle()
    }
    setStep((prev) => prev + 1)
  }
  const prevStep = (): void => {
    setStep((prev) => {
      if (prev === 1) {
        props.toggle()
        return prev
      }
      return prev - 1
    })
  }

  const step1 = (): JSX.Element => (
    <div>
      <div className="Modal-header">
        <Icon name="back-arrow" onClick={prevStep} />
        <h3 className="text-center">Withdraw Rental Funds </h3>
      </div>
      <div className="Crypto-withdrawal-body p-3">
        <h4>Crypto</h4>

        <p>Balance: 500</p>

        <TextInputGroup
          groupType="amount"
          onChange={(name, val) => console.log(name, val)}
          name="amount"
          id="amount-123"
        />
        <div className="mb-2">
          <Button label="Continue" onClick={nextStep} />
        </div>
        <div className="text-center">
          <small>
            By depositing money, you agree to our{' '}
            <span className="text-primary">Terms & Conditions</span>
          </small>
        </div>
      </div>
    </div>
  )

  const step2 = (): JSX.Element => (
    <div>
      <div className="Modal-header">
        <Icon name="back-arrow" onClick={prevStep} />
        <h3 className="text-center">Withdraw Rental Funds </h3>
      </div>
      <div className="Crypto-withdrawal-body p-3">
        <h4>Crypto</h4>

        <div
          className="Rental-options d-flex align-items-center p-3"
          onClick={() => nextStep()}
        >
          <div className="Icon-container me-3">
            <Icon name="white-bank" />
          </div>
          <p>Wallet address</p>
        </div>
        <div className="Rental-options d-flex align-items-center p-3">
          <div className="Icon-container me-3">
            <Icon name="wallet" />
          </div>
          <p>Bank Transfer</p>
        </div>

        <div className="text-center mt-3 mb-4">
          <small>
            By depositing money, you agree to our{' '}
            <span className="text-primary">Terms & Conditions</span>
          </small>
        </div>
      </div>
    </div>
  )

  const step3 = (): JSX.Element => (
    <div>
      <div className="Modal-header">
        <Icon name="back-arrow" onClick={prevStep} />
        <h3 className="text-center">Withdraw Rental Funds </h3>
      </div>
      <div className="Crypto-withdrawal-body p-3">
        <h4 className="text-center">Send to wallet address</h4>
        <div className="mb-5">
          <p>Balance: 500</p>

          <TextInputGroup
            groupType="amount"
            onChange={(name, val) => console.log(name, val)}
            name="amount"
            id="amount-123"
          />
        </div>
        <div className="mb-5 Sending-to">
          <TextInput
            label="Sending to"
            id={uuidv4()}
            placeholder="Enter an Ethereum address"
            onChange={() => console.log('changed')}
            name="sendingTo"
          />
        </div>
        <div className="mb-2">
          <Button label="Continue" onClick={nextStep} />
        </div>
      </div>
    </div>
  )

  const step4 = (): JSX.Element => (
    <div>
      <div className="Modal-header">
        <Icon name="back-arrow" onClick={prevStep} />
        <h3 className="text-center">Withdraw Rental Funds </h3>
      </div>
      <div className="Crypto-withdrawal-body p-3">
        <h4 className="text-center mb-3">Payment Confirmation</h4>
        <div className="Confirm-withdraw d-flex justify-content-center align-items-center mb-5">
          <div className="Confirm-container-one pl-2">
            <p>100.00 USDT</p>
            <div>
              <Icon name="usdt-icon" />
              <small>USDT</small>
            </div>
          </div>

          <div className="divider justify-self-center">
            <div className="Arrow-container">
              <Icon name="arrow-right" />
            </div>
          </div>

          <div className="Confirm-container-two">
            <p className="mb-3">Ethereum Address</p>
            <p style={{ fontSize: 10 }}>
              0xf48D8c9631Ac4ae9A7E395ad3B24e1dF01c4fad8
            </p>
          </div>
        </div>

        <div className="Breakdown d-flex justify-content-between mb-2">
          <p>Fee:</p>
          <span className="p">$0.50</span>
        </div>
        <div className="Breakdown  d-flex justify-content-between mb-2">
          <p>Balance after transfer:</p>
          <span className="p">$450</span>
        </div>
        <div className="Breakdown  d-flex justify-content-between mb-2">
          <p>Deposit time:</p>
          <span className="p">instant</span>
        </div>
        <div className="mb-2 mt-4">
          <Button label="Confirm" onClick={nextStep} />
        </div>
      </div>
    </div>
  )

  const withdrawalFlow: { [key: number]: JSX.Element } = {
    1: step1(),
    2: step2(),
    3: step3(),
    4: step4(),
  }
  return (
    <>
      <Modal ref={ref}>
        <div className="Crypto-Withdrawal">{withdrawalFlow[step]}</div>
      </Modal>
    </>
  )
}

export default forwardRef(CryptoRentalWithdrawal)
