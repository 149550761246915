/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import {
  INVESTMENTS,
  KYC,
  PROFILE,
  PROPERTIES,
  SIGNIN,
  VERIFY_PHONE,
} from '../../../routing/navigation'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import { IGetProfileResponseData } from '../../../interface/user'
import { Icon } from '../../Icons'
import API from '../../../network/api'
import { useAuthDispatch, useAuthState } from '../../../contexts/authContext'
import { useErrorState } from '../../../contexts/errorContext'
import { toast } from '../../Toast'
import { IError } from '../../../interface/error'
import { IModal } from '../../Modal'
import KycModal from '../../KycModal'

interface IHomeNavProps {
  setLoading: (value: boolean) => void
  show: boolean
}
const HomeNav: React.FC<IHomeNavProps> = ({ setLoading, show }) => {
  const route = useNavigate()

  const { logout, dispatchLogout, getUser, getProfile } = useAuthDispatch()
  const { user, profile } = useAuthState()

  const { error, removeError } = useErrorState()

  const [userData, setUserData] = useState<IGetProfileResponseData['data']>()

  const kycModalRef = useRef<IModal>(null)

  useEffect(() => {
    getUserProfile()
  }, [])

  const getUserProfile = async (): Promise<void> => {
    try {
      setLoading(true)
      const [user, resp] = await Promise.all([getUser(), getProfile()])
      setUserData(resp.data)
      if (user && !user.data.isPhoneVerified) {
        route(`/${VERIFY_PHONE}`)
      }
      // Email can be verified anytime
      // if (user && !user.data.isEmailVerified) {
      //   route(`/${VERIFY_EMAIL}`)
      //   return
      // }
    } catch (error) {
      const err = error as Error
      toast(err.message || ' Can not get user data at the moment')
    }
    setLoading(false)
  }

  const logoutAction = async (): Promise<void> => {
    try {
      await logout()
      route(`/${SIGNIN}`)
      toast.success('You have logged out')
    } catch (error) {
      const err = error as IError

      if (err.status === 401) {
        await dispatchLogout()
        route(`/${SIGNIN}`)
        return
      }
      toast.error(err.message || 'An error occured, try again later')
    }
  }
  const handleLogout = (): void => {
    logoutAction()
  }

  useEffect(() => {
    if (error.status === 401) {
      logoutAction()
      removeError()
    }
    return () => removeError()
  }, [error.status])

  const toggleModal = (): void => {
    kycModalRef?.current?.toggle()
  }

  const handleCompleteKyc = (): void => {
    route(`/${PROFILE}`)
  }

  const completeKyc = async (): Promise<void> => {
    // await getProfile()
    kycModalRef.current?.toggle()
  }

  const processingKycStatuses = ['pending', 'in-progress', 'submitted']
  return (
    <div
      className={`collapse navbar-collapse ms-lg-5 HomeNav ${
        show ? 'show' : ''
      }`}
      id="navbarSupportedContent"
    >
      <ul className="navbar-nav ms-lg-5 me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <NavLink
            className={({ isActive }) =>
              isActive ? 'nav-link nav-link-active' : 'nav-link'
            }
            aria-current="page"
            to={`/${PROPERTIES}`}
          >
            Properties
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) =>
              isActive ? 'nav-link nav-link-active' : 'nav-link'
            }
            end
            to={`/${INVESTMENTS}`}
          >
            My investment
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={({ isActive }) =>
              isActive ? 'nav-link nav-link-active' : 'nav-link'
            }
            end
            to="/about"
          >
            About
          </NavLink>
        </li>
      </ul>
      {user && !user?.isPoiVerified && (
        <button
          id="kyc-button"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={completeKyc}
          className="btn btn-outline mb-5 mb-lg-0 me-4"
          disabled={processingKycStatuses.includes(user?.kycStatus)}
        >
          <Icon name="verification" />
          {processingKycStatuses.includes(user?.kycStatus)
            ? 'Processing KYC'
            : 'Complete KYC'}
        </button>
      )}

      <div className="mb-5 mb-lg-0 d-flex justify-content-lg-center align-items-lg-center">
        {user?.isPoiVerified && (
          <div className="me-3">
            <Icon name="verified" />
          </div>
        )}
        <Link className="Profile-link" to={`/${PROFILE}`}>
          {userData?.firstName ?? profile?.firstName ?? ''}
          <span className="ms-2">
            <Icon name="profile" />
          </span>
        </Link>
      </div>

      <button
        onClick={handleLogout}
        className="mb-5 mb-lg-0 btn btn-link ms-3 Logout"
      >
        <Icon name="logout" />
      </button>
      {/* </form> */}
      <KycModal
        ref={kycModalRef}
        text={
          userData ?? profile ? undefined : 'Update your profile to continue'
        }
        buttonLabel={userData ?? profile ? undefined : 'Go to profile'}
        onClick={userData ?? profile ? undefined : handleCompleteKyc}
        userData={profile ?? userData}
        toggle={toggleModal}
      />
    </div>
  )
}
export default React.memo(HomeNav)
