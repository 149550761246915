import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import SignupTitleAndDesc from '../../components/SignupTitleAndDesc'
import './styles.scss'
import PhoneInput from '../../components/PhoneInput'
import Button from '../../components/Button'
import 'intl-tel-input/build/css/intlTelInput.css'
import intlTelInput, { Plugin } from 'intl-tel-input'
import { useNavigate } from 'react-router-dom'
import { VERIFY_OTP } from '../../routing/navigation'
import { useAuthDispatch } from '../../contexts/authContext'
import { toast } from '../../components/Toast'

const VerifyPhone: React.FC = () => {
  const route = useNavigate()
  const { verifyPhone, dispatchLogout } = useAuthDispatch()
  const [valid, setValid] = useState(false)
  const [loading, setLoading] = useState(false)

  const phoneInput = useRef<Plugin>()
  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value.length >= 7) setValid(true)
  }

  const handleClick = (): void => {
    onClick()
  }

  const onClick = async (): Promise<void> => {
    try {
      if (phoneInput?.current) {
        setLoading(true)
        const phoneNumber = phoneInput.current.getNumber()
        const resp = await verifyPhone({ phone: phoneNumber })
        // toast.success(resp)
        route(`/${VERIFY_OTP}`, { state: { phone: resp.data.phone } })
      }
    } catch (error) {
      const err = error as Error
      toast.error(err.message || 'An error occured please try again later')
    }
    setLoading(false)
  }
  useEffect(() => {
    const input = document.querySelector('#phone')
    if (input) {
      phoneInput.current = intlTelInput(input, {
        // any initialisation options go here
        utilsScript:
          'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
      })
    }
  }, [])

  return (
    <div className="Verify-phone">
      <SignupTitleAndDesc
        title="Add Phone Number"
        description="An OTP will be sent to your phone number "
        type="STD2"
      />
      <form>
        <div style={{ marginBottom: 100 }}>
          <PhoneInput
            id="phone"
            name="phone"
            onChange={onInputChange}
            ref={phoneInput}
          />
        </div>
        <div className="mb-3 d-flex">
          <Button
            loading={loading}
            onClick={() => {
              if (
                window.confirm(
                  'Are you sure you want to cancel? You will have to login again to continue this step'
                )
              ) {
                dispatchLogout()
              }
            }}
            label="Cancel"
            type="outline"
          />
          <Button
            loading={loading}
            onClick={handleClick}
            label="Send Code"
            disabled={!valid}
          />
        </div>
        {/* <div className="mb-3">
          <Button onClick={handleClick} type="outline" label="Resend Code" />
        </div> */}
      </form>
    </div>
  )
}

export default VerifyPhone
