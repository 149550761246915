import { METHOD, TOKEN } from '../../constants/variables'
import { AuthAction, IAuthState } from '../../interface/auth'
import { GET_PROFILE, GET_USER, LOGIN, LOGOUT } from './types'

export default (state: IAuthState, action: AuthAction): IAuthState => {
  switch (action.type) {
    case LOGIN:
      localStorage.setItem(TOKEN, action.payload.accessToken)
      localStorage.setItem(METHOD, action.payload.method)
      return {
        ...state,
        isAuthenticated: true,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        publicAddress: action.payload.publicAddress,
      }
    case LOGOUT:
      localStorage.removeItem(TOKEN)
      return {
        ...state,
        isAuthenticated: false,
        accessToken: '',
        refreshToken: '',
        publicAddress: '',
        user: null,
      }
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      }
    case GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      }
    default:
      return state
  }
}
