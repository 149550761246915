import React, { useEffect, useRef, useState } from 'react'
import InnerNav from '../../components/InnerNav'
import PageTitle from '../../components/PageTitle'
import WalletComponent from './components/Wallet'
import './styles.scss'
import Footer from '../../components/Footer'
// import Button from '../../components/Button'
import { IModal } from '../../components/Modal'
import BeneficiaryDetailsModal from '../../components/BeneficiaryDetailsModal'

const CryptoWallet: React.FC = () => {
  const [showBeneficiary, setShowBeneficiary] = useState(false)

  const BeneficiaryModalRef = useRef<IModal>(null)

  useEffect(() => {
    if (showBeneficiary) BeneficiaryModalRef.current?.toggle()
  }, [showBeneficiary])

  const toggleBeneficiary = (): void => {
    BeneficiaryModalRef.current?.toggle()

    setShowBeneficiary(false)
  }

  return (
    <>
      {/* {loading && <Loader />} */}
      <InnerNav>
        <div className="Wallet">
          <div className="row">
            <div className="col-lg-6">
              <PageTitle title="Crypto Wallet" />
            </div>
            {/* <div className="col-lg-6">
              <div
                className="d-flex align-items-center justify-content-end"
                style={{ height: 130 }}
              >
                <Button
                  type="outline"
                  size="medium"
                  label="Beneficiary Details"
                  onClick={() => {
                    setShowBeneficiary(true)
                  }}
                />
              </div>
            </div> */}
          </div>
          <div>
            <WalletComponent />
          </div>
          {showBeneficiary && (
            <BeneficiaryDetailsModal
              ref={BeneficiaryModalRef}
              toggle={() => toggleBeneficiary()}
            />
          )}
        </div>
      </InnerNav>
      <Footer />
    </>
  )
}

export default CryptoWallet
