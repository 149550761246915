import React, { FormEvent, useEffect, useState } from 'react'
import SignupTitleAndDesc from '../../components/SignupTitleAndDesc'
import './styles.scss'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import Loader from '../../components/Loader'
import { useAuthState, useAuthDispatch } from '../../contexts/authContext'
import { toast } from '../../components/Toast'
import { useNavigate } from 'react-router-dom'
import { CHECK_EMAIL } from '../../routing/navigation'
import { registrationValidations, validateInput } from '../../utils/validations'

const VerifyEmail: React.FC = () => {
  const route = useNavigate()
  const { user } = useAuthState()
  const { updateEmail } = useAuthDispatch()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ [key: string]: string }>({})
  const [validated, setValidated] = useState(false)
  const [validationResult, setValidationResult] = useState<{
    [key: string]: string
  }>({})

  // useEffect(() => {
  //   if (user?.email) {
  //     ;(async function () {
  //       try {
  //         await updateEmail({ email: user.email })
  //         setLoading(false)
  //         route(`/${CHECK_EMAIL}`, { state: { email: user.email } })
  //       } catch (error) {
  //         const err = error as Error
  //         toast.error(err.message || 'Can not validate email at the moment')
  //       }
  //     })()
  //   } else {
  //     setLoading(false)
  //   }
  // }, [])

  useEffect(() => {
    if (user?.email) {
      setData({ email: user?.email })
    }
  }, [])

  const validations = {
    email: registrationValidations.email,
  }

  const validate = (onSubmit = true): boolean => {
    const validationResponse = validateInput({
      data,
      onSubmit,
      validations,
    })
    setValidationResult((prev) => {
      return { ...prev, ...validationResponse.validationResultData }
    })
    return validationResponse.validated
  }
  useEffect(() => {
    let delayDebounceFn: any = null

    delayDebounceFn = setTimeout(() => {
      const result = validate(false)
      setValidated(result)
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [data])

  const onInputChange = (name: string, value: string): void => {
    setData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      }
    })
  }

  const handleSubmit = async (): Promise<void> => {
    setLoading(true)

    try {
      await updateEmail({
        email: data.email,
      })
      route(`/${CHECK_EMAIL}`, { state: { email: data.email } })
    } catch (error) {
      const err = error as Error
      toast.error(err.message)
    }
    setLoading(false)
  }

  const submit = (e: FormEvent): void => {
    e.preventDefault()
    handleSubmit()
  }
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <SignupTitleAndDesc
            title="Enter your email?"
            description="we will send a verification email to you with link."
            type="STD2"
          />
          <form onSubmit={submit}>
            <TextInput
              label="Email address"
              placeholder="Enter your email address"
              id="verify-password-email"
              name="email"
              type="text"
              onChange={onInputChange}
              errorText={validationResult.email}
              valid={!validationResult.email}
              defaultValue={user?.email}
              isRequired
              disabled={typeof user?.email === 'string'}
            />

            <Button loading={loading} disabled={!validated} label="Submit" />
          </form>
        </>
      )}
    </>
  )
}

export default VerifyEmail
