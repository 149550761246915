import React, { FormEvent, useEffect, useState } from 'react'
import SignupTitleAndDesc from '../../components/SignupTitleAndDesc'
import './styles.scss'
import TextInput from '../../components/TextInput'
import Button from '../../components/Button'
import { useAuthDispatch } from '../../contexts/authContext'
import { toast } from '../../components/Toast'
import { registrationValidations, validateInput } from '../../utils/validations'
// import { useNavigate } from 'react-router-dom'

const ForgotPassword: React.FC = () => {
  const { resetPasswordRequest } = useAuthDispatch()
  // const route = useNavigate()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ [key: string]: string }>({})
  const [validated, setValidated] = useState(false)
  const [validationResult, setValidationResult] = useState<{
    [key: string]: string
  }>({})

  const validations = { email: registrationValidations.email }

  const validate = (onSubmit = true): boolean => {
    const validationResponse = validateInput({
      data,
      onSubmit,
      validations,
    })
    setValidationResult((prev) => {
      return { ...prev, ...validationResponse.validationResultData }
    })
    return validationResponse.validated
  }

  useEffect(() => {
    let delayDebounceFn: any = null

    delayDebounceFn = setTimeout(() => {
      const result = validate(false)
      setValidated(result)
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [data])

  const onInputChange = (name: string, value: string): void => {
    setData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      }
    })
  }

  const requestResetPassword = async (): Promise<void> => {
    setLoading(true)

    try {
      await resetPasswordRequest({
        email: data.email,
      })

      toast.success('Check you email for instructions')
    } catch (error) {
      const err = error as Error
      toast.error(err.message)
    }
    setLoading(false)
  }

  const submit = (e: FormEvent): void => {
    e.preventDefault()
    requestResetPassword()
  }

  return (
    <>
      <SignupTitleAndDesc
        title="Forgot password?"
        description="No worries, we”ll email you reset instructions."
        type="STD2"
      />
      <form onSubmit={submit} className="needs-validation invalid" noValidate>
        <TextInput
          label="Email"
          placeholder="Enter your email address"
          id="forgot-password-email"
          name="email"
          type="text"
          onChange={onInputChange}
          errorText={validationResult.email}
          valid={!validationResult.email}
          isRequired
        />

        <Button
          disabled={!validated}
          loading={loading}
          label="Reset Password"
        />
      </form>
    </>
  )
}

export default ForgotPassword
