import { INetworkResponse } from './network'

export interface ITransactionsResponse extends INetworkResponse {
  data: Array<{
    _id: string
    user: string
    amount: number
    currency: string
    type: string
    category: string
    categoryId: string
    remark: string
    status: string
    createdAt: string
    updatedAt: string
  }>
}

export interface IVerifyCardTransaction extends INetworkResponse {
  data: {
    amount: number
    category: string
    createdAt: string
    currency: string
    currencyType: string
    gatewayMessage: string
    reference: string
    remark: string
    source: string
    sourceId: string
    status: string
    type: string
    updatedAt: string
    user: string
  }
}

export interface IWireDepositData {
  from: {
    swiftCode: string
    country: string
    iban: string
    accountNumber: string
    bankName: string
    accountName: string
  }
  amount: number
  currencyCode: string
}

export interface IWireWithdrawalData {
  remark: string
  beneficiaryReference: string
  amount: number
  currencyCode: string
}

export interface IGetTransactions {
  type?: string
  status?: string
  currencyType?: string
}

export enum ETransactionType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export enum ECurrencyType {
  FIAT = 'FIAT',
  CRYPTO = 'CRYPTO',
}

export enum ETransactionStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

export interface IWireDepositResponseData {
  transactionId: string
  reference: string
  amount: string
  type: string
  status: string
  paymentReference: string
  createdAt: string
  updatedAt: string
  currency: string
  from: {
    accountName: string
    bankName: string
    accountNumber: string
    iban: string
    country: string
    swiftCode: string
  }
  to: {
    accountName: string
    bankName: string
    accountNumber: string
    iban: string
    country: string
    swiftCode: string
  }
}
