import React, { useEffect, useRef, useState } from 'react'
import InnerNav from '../../components/InnerNav'
import PageTitle from '../../components/PageTitle'
import WalletComponent from './components/Wallet'
import './styles.scss'
import Footer from '../../components/Footer'
import Button from '../../components/Button'
import BeneficiaryDetailsModal from '../../components/BeneficiaryDetailsModal'
import { IModal } from '../../components/Modal'
import { useGlobalDispatch, useGlobalState } from '../../contexts/globalContext'
import { IError } from '../../interface/error'
import { toast } from '../../components/Toast'
import { useSearchParams } from 'react-router-dom'
import API from '../../network/api'
import Loader from '../../components/Loader'

const FiatWallet: React.FC = () => {
  const { getWallet } = useGlobalDispatch()
  const { wallet } = useGlobalState()

  const [searchParams, setSearchParams] = useSearchParams()

  const cardAuthCode = searchParams.get('authcode')
  const cardTransactionType = searchParams.get('type')
  const [cardSuccess, setCardSuccess] = useState(false)
  const [paymentCurrency, setPaymentCurrency] = useState('')
  const [showDeposit, setShowFiatDeposit] = useState(false)
  const [amount, setAmount] = useState('')
  const [loading, setLoading] = useState(false)

  const [showBeneficiary, setShowBeneficiary] = useState(false)

  const BeneficiaryModalRef = useRef<IModal>(null)

  useEffect(() => {
    if (!wallet) getUserWallet()

    const timer = setTimeout(() => {
      getTransactionStatus()
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    if (showBeneficiary) BeneficiaryModalRef.current?.toggle()
  }, [showBeneficiary])

  const toggleBeneficiary = (): void => {
    BeneficiaryModalRef.current?.toggle()

    setShowBeneficiary(false)
  }

  // const setFiatDeposit = (value: boolean): void => {
  //   setShowFiatDeposit(value)
  // }

  const getUserWallet = async (): Promise<void> => {
    try {
      await getWallet()
    } catch (error) {
      const err = error as IError
      toast.error(err.message || 'Unable to get balance')
    }
  }

  const getTransactionStatus = async (): Promise<void> => {
    setLoading(true)
    if (cardAuthCode && cardTransactionType) {
      if (cardTransactionType === 'accountFunding') {
        try {
          const response = await API.verifyCardDeposit(cardAuthCode)
          if (response?.data?.status?.toLowerCase() === 'success') {
            setCardSuccess(true)
            setAmount(response.data.amount.toFixed(2))

            setPaymentCurrency(response.data.currency)
            setShowFiatDeposit(true)
          } else {
            toast.error('Transaction might have failed')
          }
        } catch (err) {
          const error = err as IError
          toast.error(error.message || 'Deposit failed')
        }
        searchParams.delete('authcode')
        searchParams.delete('type')
        setSearchParams(searchParams)
      }
    }
    setLoading(false)
  }

  return (
    <>
      {/* {loading && <Loader />} */}

      <InnerNav>
        <>
          {loading && <Loader />}
          <div className="Wallet pe-lg-5">
            <div className="row">
              <div className="col-lg-6">
                <PageTitle title="Fiat Wallet" />
              </div>
              <div className="col-lg-6">
                <div
                  className="d-flex align-items-center justify-content-end"
                  style={{ height: 130 }}
                >
                  <Button
                    type="outline"
                    size="medium"
                    label="Beneficiary Details"
                    onClick={() => {
                      setShowBeneficiary(true)
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <WalletComponent
                getWallet={getUserWallet}
                cardSuccess={cardSuccess}
                amount={amount}
                paymentCurrency={paymentCurrency}
                showDeposit={showDeposit}
                // setShowFiatDeposit={setFiatDeposit}
              />
            </div>
          </div>
        </>
      </InnerNav>

      <Footer />

      {showBeneficiary && (
        <BeneficiaryDetailsModal
          ref={BeneficiaryModalRef}
          toggle={() => toggleBeneficiary()}
        />
      )}
    </>
  )
}

export default React.memo(FiatWallet)
