import React from 'react'
import './styles.scss'

interface IProps {
  text: string
  lat?: number
  lng?: number
}
const LocationPin: React.FC<IProps> = ({ text }) => {
  return (
    <div className="LocationPin">
      <img src="../../assets/icons/geotag.png" />
      <p>{text}</p>
    </div>
  )
}

export default LocationPin
