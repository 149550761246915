import React, { useState, ReactNode, FC } from 'react'

interface IData {
  message: string
  status?: number
}
interface IContext {
  error: IData
  addError: (data: IData) => void
  removeError: () => void
}

export const ErrorContext = React.createContext<IContext>({
  error: {
    message: '',
    status: 0,
  },
  addError: (data: IData) => {},
  removeError: () => {},
})

export const ErrorProvider: FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode
}) => {
  const [error, setError] = useState<IData>({ message: '' })

  const removeError = (): void => setError({ message: '', status: 0 })

  const addError = (data: IData): void => setError(data)

  const contextValue = {
    error,
    addError,
    removeError,
  }

  return (
    <ErrorContext.Provider value={contextValue}>
      {children}
    </ErrorContext.Provider>
  )
}

export function useErrorState(): IContext {
  const context = React.useContext(ErrorContext)
  if (context === undefined) {
    throw new Error('useErrorState must be used with a ErrorProvider')
  }
  return context
}
