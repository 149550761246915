import { EGlobal, GlobalAction, IGLobalState } from '../../interface/global'
import { ISettingsResponse } from '../../interface/settings'
import { IWalletResponse } from '../../interface/wallet'

export default (state: IGLobalState, action: GlobalAction): IGLobalState => {
  switch (action.type) {
    case EGlobal.GET_SETTINGS: {
      const payload = action.payload as ISettingsResponse['data']
      return {
        ...state,
        settings: {
          wireCredential: payload.wireCredential,
          cryptoWallet: payload.cryptoWallet,
          fracxnFees: payload.fracxnFees,
          supportedFiat: payload.supportedFiat,
          supportedCrypto: payload.supportedCrypto,
        },
      }
    }
    case EGlobal.GET_WALLET: {
      const payload = action.payload as IWalletResponse['data']
      return {
        ...state,
        wallet: payload,
      }
    }
    default:
      return state
  }
}
