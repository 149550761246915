import React, {
  FormEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useState,
} from 'react'
import Button from '../../../../components/Button'
import { Icon } from '../../../../components/Icons'
import Modal, { IModal } from '../../../../components/Modal'
import './styles.scss'
import { toast } from '../../../../components/Toast'
import TextInput from '../../../../components/TextInput'
import { validateInput } from '../../../../utils/validations'
import API from '../../../../network/api'

interface ModalProps {
  toggle: () => void
  callback?: () => any
  profileData: Record<string, string>
}
const UpdateUserNameModal: ForwardRefRenderFunction<IModal, ModalProps> = (
  { ...props },
  ref
) => {
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ [key: string]: string }>({})
  const [validated, setValidated] = useState(false)
  const [validationResult, setValidationResult] = useState<{
    [key: string]: string
  }>({})

  useEffect(() => {
    console.log(props.profileData)
    setData({
      ...data,
      firstName: props.profileData?.firstName,
      lastName: props.profileData?.lastName,
    })
  }, [props.profileData])

  const validations = {
    firstName: (data: Record<string, string>): string => {
      if (!data?.firstName) return 'First name is required'
      return ''
    },
    lastName: (data: Record<string, string>): string => {
      if (!data?.lastName) return 'Last name is required'
      return ''
    },
  }

  const nextStep = (): void => {
    if (step === 2) {
      setStep(1)
      return props.toggle()
    }
    setStep((prev) => prev + 1)
  }

  const closeModal = (): void => {
    if (props.callback) props.callback()
    props.toggle()
  }

  const validate = (onSubmit = true): boolean => {
    const validationResponse = validateInput({
      data,
      onSubmit,
      validations,
    })
    setValidationResult((prev) => {
      return { ...prev, ...validationResponse.validationResultData }
    })
    return validationResponse.validated
  }
  useEffect(() => {
    console.log('data', data)
    let delayDebounceFn: any = null

    delayDebounceFn = setTimeout(() => {
      const result = validate(false)
      setValidated(result)
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [data])

  const onInputChange = (name: string, value: string): void => {
    setData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      }
    })
  }

  const handleSubmit = async (): Promise<void> => {
    setLoading(true)

    try {
      await API.updateProfile({
        firstName: data.firstName,
        lastName: data.lastName,
      })
      nextStep()
    } catch (error) {
      const err = error as Error
      toast.error(err.message)
    }
    setLoading(false)
  }

  const submit = (e: FormEvent): void => {
    e.preventDefault()
    handleSubmit()
  }

  const step1 = (): JSX.Element => (
    <div className="UpdatePhoneBody">
      <div className="mb-4 text-center">
        <Icon name="update-profile" />
      </div>
      <div className="mb-4 text-center">
        <p style={{ fontWeight: 600 }}>Update Username</p>
        <small style={{ fontWeight: 500, color: '#979797' }}>
          Please a enter username according to your KYC details
        </small>
      </div>

      <form className="mb-4" onSubmit={submit}>
        <div className="mb-5">
          <TextInput
            label="First name"
            placeholder="Enter first name"
            id="firstname"
            name="firstName"
            type="text"
            onChange={onInputChange}
            errorText={validationResult.firstName}
            valid={!validationResult.firstName}
            defaultValue={props.profileData?.firstName || ''}
            value={data.firstName || ''}
            isRequired
          />

          <TextInput
            label="Last name"
            placeholder="Enter last name"
            id="lastname"
            name="lastName"
            type="text"
            onChange={onInputChange}
            errorText={validationResult.lastName}
            valid={!validationResult.lastName}
            defaultValue={props.profileData?.lastName || ''}
            value={data.lastName || ''}
            isRequired
          />
        </div>
        <div>
          <Button
            loading={loading}
            disabled={!validated}
            label="Update user name"
          />
        </div>
      </form>
    </div>
  )

  const step2 = (): JSX.Element => (
    <div className="UpdatePhoneBody">
      <div className="mb-4 text-center">
        <Icon name="phone-updated" />
      </div>
      <div className="mb-4 text-center">
        <p style={{ fontWeight: 600 }}>Username Updated</p>
        <small style={{ fontWeight: 500, color: '#979797' }}>
          Your username has been successfully updated
        </small>
      </div>

      <div className="mb-4">
        <div>
          <Button onClick={closeModal} label="Back to profile" />
        </div>
      </div>
    </div>
  )

  const UpdatePhoneFlow: { [key: number]: JSX.Element } = {
    1: step1(),
    2: step2(),
  }
  return (
    <>
      <Modal ref={ref}>
        <div className="UpdatePhone">
          <div className="UpdatePhoneHeader d-flex justify-content-end">
            <Icon
              name="close"
              onClick={() => {
                // setStep(1)
                props.toggle()
              }}
            />
          </div>
          <>{UpdatePhoneFlow[step]}</>
        </div>
      </Modal>
    </>
  )
}

export default forwardRef(UpdateUserNameModal)
