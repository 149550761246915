import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import Button from '../../../../components/Button'
import { Icon } from '../../../../components/Icons'
import Modal, { IModal } from '../../../../components/Modal'
import TextInputGroup from '../../../../components/TextInputGroup'
import { v4 as uuidv4 } from 'uuid'
import './styles.scss'
import TextInput from '../../../../components/TextInput'
import { IBeneficiaryResponse } from '../../../../interface/beneficiary'
import API from '../../../../network/api'
import { IError } from '../../../../interface/error'
import { toast } from '../../../../components/Toast'
import { useGlobalState } from '../../../../contexts/globalContext'
import { useErrorState } from '../../../../contexts/errorContext'
import {
  beneficiaryValidations,
  validateInput,
} from '../../../../utils/validations'

interface ModalProps {
  toggle: () => void
  currency: string
  callback?: () => void
}
const FiatWithdrawalModal: ForwardRefRenderFunction<IModal, ModalProps> = (
  { ...props },
  ref
) => {
  const { wallet, settings } = useGlobalState()
  const { addError } = useErrorState()

  const [newBank, setNewBank] = useState(false)
  const [beneficiaries, setBeneficiaries] =
    useState<IBeneficiaryResponse['data']>()
  // const [loadingBeneficiaries, setLoadingBeneficiaries] = useState(false)
  const [selectedBank, setSelectedBank] =
    useState<IBeneficiaryResponse['data'][0]>()
  const [data, setData] = useState<{ [key: string]: string }>({ amount: '' })
  const [beneficiaryData, setBeneficiaryData] = useState<{
    [key: string]: string
  }>({})
  const [validationResult, setValidationResult] = useState<{
    [key: string]: string
  }>({})

  const [beneficiaryValidationResult, setBeneficiaryValidationResult] =
    useState<{
      [key: string]: string
    }>({})
  const [validated, setValidated] = useState(false)
  const [beneficiaryValidated, setBeneficiaryValidated] = useState(false)
  const [currentCurrency, setcurrentCurrency] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1)
  const [depositLoading, setDepositLoading] = useState(false)

  const style = { borderRadius: 3, borderColor: '#c4c4c4' }

  useEffect(() => {
    setValidated(false)
    getBeneficiaries()
  }, [])

  const getBeneficiaries = async (): Promise<void> => {
    // setLoadingBeneficiaries(true)

    try {
      const response = await API.getBeneficiaries()
      setBeneficiaries(response.data)
    } catch (error) {
      const err = error as IError
      toast.error(err.message || 'Unable to get beneficiaries')
    }
    // setLoadingBeneficiaries(false)
  }

  const nextStep = (): void => {
    if (step === 3) {
      setStep(1)
      if (props.callback) props.callback()
      return props.toggle()
    }
    setStep((prev) => prev + 1)
  }
  const prevStep = (): void => {
    setStep((prev) => {
      if (prev === 1) {
        props.toggle()
        return prev
      }
      return prev - 1
    })
  }

  const selectBank = (e: ChangeEvent<HTMLInputElement>, value: any): void => {
    setSelectedBank(value)
  }

  const onBeneficiaryChange = useCallback(
    (name: string, value: string): void => {
      setBeneficiaryData((prevData) => {
        return {
          ...prevData,
          [name]: value,
        }
      })
    },
    [beneficiaryData]
  )

  const onInputChange = useCallback(
    (name: string, value: string): void => {
      setData((prevData) => {
        return {
          ...prevData,
          [name]: value,
        }
      })
    },
    [data.amount]
  )

  const onChangeCurrency = (currency: string): void => {
    setcurrentCurrency(currency)
  }

  const amount = (data: Record<string, string>): string => {
    if (!data.amount) {
      return 'Enter amount'
    }
    if (Number(data.amount) === 0) {
      return 'Amount should be greater than 0'
    }
    return ''
  }
  const validations = {
    amount,
  }

  const validateBeneficiary = (onSubmit = true): boolean => {
    setBeneficiaryValidationResult({})
    const validationResponse = validateInput({
      data: beneficiaryData,
      onSubmit,
      validations: beneficiaryValidations,
    })
    setBeneficiaryValidationResult((prev) => {
      return { ...prev, ...validationResponse.validationResultData }
    })
    return validationResponse.validated
  }

  const validate = (onSubmit = true): boolean => {
    setValidationResult({})
    const validationResponse = validateInput({
      data,
      onSubmit,
      validations,
    })
    setValidationResult((prev) => {
      return { ...prev, ...validationResponse.validationResultData }
    })
    return validationResponse.validated
  }

  useEffect(() => {
    let delayDebounceFn: any = null

    delayDebounceFn = setTimeout(() => {
      const result = validateBeneficiary(false)
      setBeneficiaryValidated(result)
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [beneficiaryData])

  useEffect(() => {
    let delayDebounceFn: any = null

    delayDebounceFn = setTimeout(() => {
      const result = validate(false)
      setValidated(result)
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [data.amount])

  useEffect(() => {
    setcurrentCurrency(props.currency ?? '')
  }, [props.currency])

  const handleCreateBeneficiary = (): void => {
    createBeneficiary()
  }

  const createBeneficiary = async (): Promise<void> => {
    setLoading(true)
    try {
      await API.createBeneficiaries({
        swiftCode: beneficiaryData.swiftCode,
        country: beneficiaryData.country,
        iban: beneficiaryData.iban,
        accountNumber: beneficiaryData.accountNumber,
        bankName: beneficiaryData.bankName,
        accountName: beneficiaryData.accountName,
      })
      await getBeneficiaries()
      setNewBank(false)
      toast.success('Beneficiary added successfully')
    } catch (error: unknown) {
      const err = error as IError
      toast.error(err.message || 'unable to add beneficiary at the moment')
      addError(err)
    }
    setLoading(false)
  }
  const handleWithdraw = (): void => {
    withdraw()
  }
  const withdraw = async (): Promise<any> => {
    try {
      setDepositLoading(true)
      if (selectedBank) {
        const withdrawData = {
          remark: `Withdrawal to ${selectedBank.accountNumber}`,
          beneficiaryReference: selectedBank.reference,
          amount: Number(data.amount) || 0,
          currencyCode: currentCurrency,
        }

        const response = await API.wireWithdraw(withdrawData)
        setSelectedBank({ ...selectedBank, ...response.data })
        // setFraxnBeneficiaryAccount(response.data)
        toast.success('Action successful')
        nextStep()
      }
    } catch (error: unknown) {
      const err = error as IError
      toast.error(err.message || 'An error occured, Please try again later')
    }
    setDepositLoading(false)
  }
  console.log(selectedBank)

  const step1 = (): JSX.Element => (
    <>
      {!newBank && (
        <form className="p-3 Enter-amount">
          <div className="mb-2">
            <label htmlFor="amount-1234">Amount</label>

            <TextInputGroup
              groupType="amount"
              name="amount"
              id="amount-1233221"
              onChange={onInputChange}
              errorText={validationResult.amount}
              valid={!validationResult.amount}
              currencies={wallet?.map((wal) => wal.currency.code)}
              onChangeCurrency={onChangeCurrency}
              currentCurrency={currentCurrency}
            />
            <div className="Payment-sum">
              <div className="d-flex justify-content-center align-items-center">
                <p className="mt-3 me-3">Fees:</p>
                <p className="mt-3  text-dark">
                  {settings?.fracxnFees?.withdrawal ?? 0}
                </p>
              </div>
            </div>
          </div>
        </form>
      )}
      {!newBank ? (
        <div className="p-3">
          <small>Select bank account</small>
          <div className="Bank-added  mt-4 mb-4 ">
            {beneficiaries && (
              <>
                {beneficiaries &&
                  beneficiaries.length >= 1 &&
                  beneficiaries.map((beneficiary) => (
                    <div
                      className="p-3 Check d-flex align-items-center"
                      key={uuidv4()}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input me-3"
                          type="radio"
                          name="checkBank"
                          id={uuidv4()}
                          checked={
                            selectedBank?.reference === beneficiary.reference
                          }
                          onChange={(e) => selectBank(e, beneficiary)}
                        />
                        <label
                          className="form-check-label d-flex align-items-center"
                          htmlFor="checkBank"
                        >
                          <div className="me-2">
                            <Icon name="bank" />
                          </div>
                          <div className="me-5">
                            <p>{beneficiary.bankName?.toUpperCase()}</p>
                            {beneficiary.accountNumber && (
                              <p
                                style={{ fontSize: 10 }}
                              >{`${beneficiary?.accountNumber?.substring(
                                0,
                                4
                              )} ${Array(
                                beneficiary?.accountNumber?.length - 4 < 0
                                  ? 0
                                  : beneficiary?.accountNumber?.length - 4
                              )
                                .fill('*')
                                .join(' ')}`}</p>
                            )}
                          </div>
                          <div>
                            <p>{beneficiary.accountName}</p>
                          </div>
                        </label>
                      </div>
                    </div>
                  ))}
              </>
            )}
            <div className="d-flex p-3 align-items-center New-bank-button">
              <Button
                type="link"
                onClick={() => setNewBank(true)}
                label="Add a new bank account"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="p-3">
          <div className="New-bank mt-4 mb-4 p-3">
            <h6>New Bank Account</h6>
            <div className="row">
              <div className="col-lg-6">
                <TextInput
                  label="Account name"
                  name="accountName"
                  placeholder=" "
                  onChange={onBeneficiaryChange}
                  id={uuidv4()}
                  style={style}
                  errorText={beneficiaryValidationResult.accountName}
                  valid={!beneficiaryValidationResult.accountName}
                />
              </div>
              <div className="col-lg-6">
                <TextInput
                  label="Bank name"
                  name="bankName"
                  placeholder=" "
                  onChange={onBeneficiaryChange}
                  id={uuidv4()}
                  style={style}
                  errorText={beneficiaryValidationResult.bankName}
                  valid={!beneficiaryValidationResult.bankName}
                />
              </div>
              <div className="col-lg-6">
                <TextInput
                  label="Account number"
                  name="accountNumber"
                  placeholder=" "
                  onChange={onBeneficiaryChange}
                  id={uuidv4()}
                  style={style}
                  errorText={beneficiaryValidationResult.accountNumber}
                  valid={!beneficiaryValidationResult.accountNumber}
                />
              </div>
              <div className="col-lg-6">
                <TextInput
                  label="Country"
                  name="country"
                  placeholder=" "
                  onChange={onBeneficiaryChange}
                  id={uuidv4()}
                  style={style}
                  errorText={beneficiaryValidationResult.country}
                  valid={!beneficiaryValidationResult.country}
                />
              </div>

              <div className="col-lg-12">
                <TextInput
                  label="IBAN"
                  name="iban"
                  placeholder=" "
                  onChange={onBeneficiaryChange}
                  id={uuidv4()}
                  style={style}
                  errorText={beneficiaryValidationResult.iban}
                  valid={!beneficiaryValidationResult.iban}
                />
              </div>
              <div className="col-lg-12">
                <TextInput
                  label="Swift Code"
                  name="swiftCode"
                  placeholder=" "
                  onChange={onBeneficiaryChange}
                  id={uuidv4()}
                  style={style}
                  errorText={beneficiaryValidationResult.swiftCode}
                  valid={!beneficiaryValidationResult.swiftCode}
                />
              </div>
            </div>
            <div className="row mt-2">
              <Button
                label="Add bank account"
                onClick={() => handleCreateBeneficiary()}
                disabled={!beneficiaryValidated}
                loading={loading}
              />
            </div>
          </div>
        </div>
      )}
      <div className="p-3 Continue">
        <Button
          label="Continue"
          disabled={!selectedBank || !validated}
          onClick={() => nextStep()}
        />
      </div>
    </>
  )

  const step2 = (): JSX.Element => (
    <>
      <h3 className="text-center">Total Withdrawal Amount </h3>
      <div className="Total-deposit p-3">
        <div className="Total-deposit-container d-flex justify-content-between mb-4 ms-3 me-3">
          <div>
            <small>Withdrawal amount </small>
          </div>
          <div>
            <p>
              {currentCurrency}{' '}
              <strong>
                {Number(
                  Number(data.amount ?? 0) +
                    (settings?.fracxnFees?.withdrawal ?? 0) ?? 0
                ).toFixed(2)}
              </strong>
            </p>
          </div>
        </div>
        <Button
          label="continue"
          loading={depositLoading}
          onClick={handleWithdraw}
        />
      </div>
    </>
  )

  const step3 = (): JSX.Element => (
    <div className="Success">
      <div className="text-center mt-3">
        <Icon name="pending-icon" />
        <p className="mt-3 mb-2 Amount">
          {currentCurrency}{' '}
          {Number(data.amount ?? 0) + (settings.fracxnFees?.withdrawal ?? 0) ??
            0}
        </p>
        <p className="Success-message">
          Wallet will be updated once withdrawal has been received
        </p>
        {settings?.wireCredential && (
          <div>
            <div style={{ gap: 15 }} className="d-flex  Ref">
              <p>Reference no.</p>
              <div className="d-flex">
                <p
                  className="bold me-2"
                  style={{
                    overflowWrap: 'break-word',
                    width: 'auto',
                    maxWidth: '60%',
                  }}
                >
                  {selectedBank?.reference}
                </p>
                {/* <Icon
                  name="copy"
                  onClick={() =>
                    copy(`FRC${currentCurrency}${data.amount || 0}}`)
                  }
                /> */}
              </div>
            </div>
            <div className="d-flex justify-content-between Ref">
              <p>Account number</p>
              <div className="d-flex">
                <p className="bold me-2">{selectedBank?.accountNumber}</p>
                {/* <Icon
                  name="copy"
                  onClick={() => copy(fraxnBeneficiaryAccount?.to.accountName)}
                /> */}
              </div>
            </div>

            <div className="d-flex justify-content-between Ref">
              <p>Bank name</p>
              <div className="d-flex">
                <p className="bold me-2">{selectedBank?.bankName}</p>
                {/* <Icon
                  name="copy"
                  onClick={() => copy(selectedBank?.bankName)}
                /> */}
              </div>
            </div>

            <div className="d-flex justify-content-between Ref">
              <p>IBAN</p>
              <div className="d-flex">
                <p className="bold me-2">{selectedBank?.iban}</p>
                {/* <Icon name="copy" onClick={() => copy(selectedBank?.iban)} /> */}
              </div>
            </div>
            <div className="d-flex justify-content-between Ref">
              <p>Swift code</p>
              <div className="d-flex">
                <p className="bold me-2">{selectedBank?.swiftCode}</p>
                {/* <Icon
                  name="copy"
                  onClick={() => copy(selectedBank?.swiftCode)}
                /> */}
              </div>
            </div>
          </div>
        )}

        <div className="Info mt-3 mb-4" style={{ textAlign: 'left' }}>
          <p>
            <strong>Note:</strong> You will be notified of the status of the
            transaction, you can also check fiat transactions to know the status
            of this transaction.
          </p>
        </div>
        <Button
          label="Back to wallet"
          disabled={!data.amount || !selectedBank}
          onClick={() => {
            nextStep()
          }}
        />
      </div>
    </div>
  )

  const withdrawalFlow: { [key: number]: JSX.Element } = {
    1: step1(),
    2: step2(),
    3: step3(),
    //  4: WirePending(), // DepositReceived(),
  }
  return (
    <>
      <Modal ref={ref} size={step === 3 ? 'small' : 'normal'}>
        <div className="Fiat-Withdrawal">
          <div className="Modal-header">
            <div className="d-flex justify-content-between">
              <Icon name="back-arrow" onClick={prevStep} />
              <Icon name="close" onClick={props.toggle} />
            </div>
            {!newBank && step === 1 && (
              <h3 className="text-center">Enter withdrawal amount </h3>
            )}
          </div>
          <>{withdrawalFlow[step]}</>
        </div>
      </Modal>
    </>
  )
}

export default forwardRef(FiatWithdrawalModal)
