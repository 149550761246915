import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useState,
} from 'react'
import Button from '../../../../components/Button'
// import Button from '../../../../components/Button'
import { Icon } from '../../../../components/Icons'
import Modal, { IModal } from '../../../../components/Modal'
import TextInput from '../../../../components/TextInput'
import TextInputGroup from '../../../../components/TextInputGroup'
// import TextInputGroup from '../../../../components/TextInputGroup'
import { v4 as uuidv4 } from 'uuid'
import './styles.scss'
import { validateInput } from '../../../../utils/validations'
import { ethers } from 'ethers'
import { toast } from '../../../../components/Toast'
// import TextInput from '../../../../components/TextInput'

interface ModalProps {
  toggle: () => void
  currency: string
  usdtContract?: ethers.Contract
  usdcContract?: ethers.Contract
  ethBalance?: string
  usdcBalance?: string
  usdtBalance?: string
  signer?: any
}
const CryptoSendModal: ForwardRefRenderFunction<IModal, ModalProps> = (
  { ...props },
  ref
) => {
  const [step, setStep] = useState(1)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ [key: string]: string }>({})
  const [validated, setValidated] = useState(false)
  const [validationResult, setValidationResult] = useState<{
    [key: string]: string
  }>({})
  const [gas, setGas] = useState<any>()

  // const style = { borderRadius: 3, borderColor: '#c4c4c4' }

  useEffect(() => {
    ;(async function () {
      if (props.signer) {
        const gasPrice = await props.signer.getGasPrice()
        setGas(ethers.utils.formatEther(gasPrice))
      }
    })()
  }, [])
  const nextStep = (): void => {
    if (step === 3) {
      setStep(1)
      return props.toggle()
    }
    setStep((prev) => prev + 1)
  }
  const prevStep = (): void => {
    setStep((prev) => {
      if (prev === 1) {
        props.toggle()
        return prev
      }
      return prev - 1
    })
  }
  const onInputChange = (name: string, value: string): void => {
    setData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      }
    })
  }
  const validationSchema = {
    amount: (data: Record<string, string>): string => {
      if (!data?.amount) return 'Amount is required'
      if (data.amount && Number(data.amount) <= 0) {
        return 'Amount should be greater than 0'
      }
      return ''
    },
    address: (data: Record<string, string>): string => {
      if (!data.address) return 'Address is required'
      return ''
    },
  }

  const validate = (onSubmit = true): boolean => {
    const validationResponse = validateInput({
      data,
      onSubmit,
      validations: validationSchema,
    })
    setValidationResult((prev) => {
      return { ...prev, ...validationResponse.validationResultData }
    })
    return validationResponse.validated
  }
  useEffect(() => {
    let delayDebounceFn: any = null

    delayDebounceFn = setTimeout(() => {
      const result = validate(false)
      setValidated(result)
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [data])

  const submit = (): void => {
    setStep((prev) => prev + 1)
  }

  const completeSend = async (): Promise<void> => {
    try {
      switch (props.currency) {
        // case 'ETH':
        //   if (props.signer) {
        //     if (
        //       Number(props.ethBalance ?? 0) <
        //       Number(data.amount) + Number(gas)
        //     ) {
        //       toast.error('Insufficient balance')
        //       return
        //     }
        //     const tx = await props.signer.sendTransaction({
        //       to: data.address,
        //       value: ethers.utils.parseEther(data.amount),
        //     })
        //     const receipt = await tx.wait()
        //     toast.success(
        //       `Transaction confirmed in block ${
        //         receipt.blockNumber
        //       }, gas used: ${receipt.gasUsed.toString()}`
        //     )
        //     nextStep()
        //   } else {
        //     toast.error('Invalid configuration')
        //   }
        //   break
        case 'USDC':
          if (props.usdcContract && props.signer) {
            if (
              Number(props.usdcBalance ?? 0) <
              Number(data.amount) + Number(gas)
            ) {
              toast.error('Insufficient balance')
              return
            }
            const usdcWithSigner = props.usdcContract.connect(props.signer)
            const usdc = ethers.utils.parseUnits(data.amount, 6)

            const tx = await usdcWithSigner.transfer(data.address, usdc)

            const receipt = await tx.wait()
            toast.success(
              `Transaction confirmed in block ${
                receipt.blockNumber
              }, gas used: ${receipt.gasUsed.toString()}`
            )
            nextStep()
          } else {
            toast.error('Invalid configuration')
          }
          break
        case 'USDT':
          if (props.usdtContract && props.signer) {
            if (
              Number(props.usdtBalance ?? 0) <
              Number(data.amount) + Number(gas)
            ) {
              toast.error('Insufficient balance')
              return
            }
            const usdtWithSigner = props.usdtContract.connect(props.signer)
            const usdt = ethers.utils.parseUnits(data.amount, 6)
            const tx = await usdtWithSigner.transfer(data.address, usdt)
            const receipt = await tx.wait()
            toast.success(
              `Transaction confirmed in block ${
                receipt.blockNumber
              }, gas used: ${receipt.gasUsed.toString()}`
            )
            nextStep()
          } else {
            toast.error('Invalid configuration')
          }
          break
      }
    } catch (error: unknown) {
      const err = error as any
      toast.error(err.data?.message || err.message || 'An error occured')
    }
  }

  const handleComplete = (): void => {
    completeSend()
  }

  const getBalance = (): string | undefined => {
    switch (props.currency) {
      case 'ETH':
        return props.ethBalance
      case 'USDC':
        return props.usdcBalance
      case 'USDT':
        return props.usdtBalance
    }
  }

  const step1 = (): JSX.Element => (
    <div>
      <div className="Modal-header">
        <div className="d-flex justify-content-between">
          <Icon name="back-arrow" onClick={prevStep} />
          <Icon name="close" onClick={props.toggle} />
        </div>
        <div className="text-center mb-3">
          <Icon name="send-with-border" />
        </div>
        <div className="text-center">
          <p>Send to a wallet address</p>
        </div>
      </div>
      <div className="Crypto-send-body">
        <div>
          <small style={{ fontSize: 10, color: '#07172F' }}>Sending from</small>
          <TextInputGroup
            groupType="amount"
            onChange={(name, val) => null}
            name="amount"
            id="amount-123"
            defaultValue={`Balance: ${getBalance()}`}
          >
            <img
              style={{ marginRight: 10 }}
              src="../../assets/icons/USDC.svg"
            />
            <span style={{ fontSize: 12, color: '#07172F' }}>
              {props.currency}
            </span>
          </TextInputGroup>
        </div>

        <div>
          <TextInput
            name="address"
            id={uuidv4()}
            placeholder="Enter wallet address"
            label="Enter address"
            onChange={onInputChange}
            errorText={validationResult.address}
            valid={!validationResult.address}
            isRequired
          />
        </div>
        <div>
          <small style={{ fontSize: 10, color: '#07172F' }}>Sending from</small>
          <TextInputGroup
            groupType="amount"
            onChange={onInputChange}
            name="amount"
            id="amount-123"
            // defaultValue="Balance: 5,000.00"
            errorText={validationResult.amount}
            valid={!validationResult.amount}
            isRequired
          >
            <img
              style={{ marginRight: 10 }}
              src="../../assets/icons/USDC.svg"
            />
            <span style={{ fontSize: 12, color: '#07172F' }}>
              {props.currency}
            </span>
          </TextInputGroup>
        </div>
      </div>
      <div className="mb-3 text-center">
        <Button label="Continue" disabled={!validated} onClick={submit} />
      </div>
    </div>
  )

  const step2 = (): JSX.Element => (
    <div>
      <div className="Modal-header">
        <div className="d-flex justify-content-between">
          <Icon name="back-arrow" onClick={prevStep} />
          <Icon name="close" onClick={props.toggle} />
        </div>
        <div className="text-center mb-3">
          <Icon name="send-with-border" />
        </div>
        <div className="text-center">
          <p>Payment confirmation</p>
          <small>Please confirm transaction before proceeding</small>
        </div>
      </div>
      <div className="Crypto-withdrawal-body p-3">
        {/* <h4 className="text-center mb-3">Payment Confirmation</h4> */}
        <div className="Confirm-withdraw d-flex justify-content-center align-items-center mb-5">
          <div className="Confirm-container-one pl-2">
            <p>
              {data.amount} {props.currency}
            </p>
            <div>
              <Icon name="usdt-icon" />
              <small>{props.currency}</small>
            </div>
          </div>

          <div className="divider justify-self-center">
            <div className="Arrow-container">
              <Icon name="arrow-right" />
            </div>
          </div>

          <div className="Confirm-container-two">
            <p className="mb-3">Polygon Address</p>
            <div className="d-flex justify-content-center align-items-center">
              <Icon name="polygon" />
              {data.address && (
                <p style={{ fontSize: 10, marginBottom: 0, marginLeft: 10 }}>
                  {`${data.address.substring(0, 5)}....${data.address.substring(
                    data.address.length - 5,
                    data.address.length
                  )}`}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="Breakdown d-flex justify-content-between mb-2">
          <p>Fee:</p>
          <span className="p">$0.50(BE)</span>
        </div>
        <div className="Breakdown  d-flex justify-content-between mb-2">
          <p>Balance after transfer:</p>
          <span className="p">
            {Number(
              props.currency === 'USDC' ? props.usdcBalance : props.usdtBalance
            ) -
              (Number(data.amount) + Number(gas))}
          </span>
        </div>
        <div className="Breakdown  d-flex justify-content-between mb-2">
          <p>Deposit time:</p>
          <span className="p">instant</span>
        </div>
        <div className="mb-2 mt-4">
          <Button label="Confirm" onClick={handleComplete} />
        </div>
      </div>
    </div>
  )

  const step3 = (): JSX.Element => (
    <div>
      <div className="Modal-header">
        <div className="d-flex justify-content-between">
          <Icon name="back-arrow" onClick={prevStep} />
          <Icon name="close" onClick={props.toggle} />
        </div>
        <div className="text-center mb-3">
          <Icon name="payment-success" />
        </div>
        <div className="text-center">
          <p>Payment Success</p>
          <small>Congrats! your transaction is completed successfully. </small>
        </div>
      </div>
      <div className="Crypto-withdrawal-body p-3">
        {/* <h4 className="text-center mb-3">Payment Confirmation</h4> */}
        <div className="Confirm-withdraw d-flex justify-content-center align-items-center mb-5">
          <div className="Confirm-container-one pl-2">
            <p>
              {data.amount} {props.currency}
            </p>
            <div>
              <Icon name="usdt-icon" />
              <small>USDT</small>
            </div>
          </div>

          <div className="divider justify-self-center">
            <div className="Arrow-container">
              <Icon name="arrow-right" />
            </div>
          </div>

          <div className="Confirm-container-two">
            <p className="mb-3">Polygon Address</p>
            <div className="d-flex justify-content-center align-items-center">
              <Icon name="polygon" />
              {data.address && (
                <p style={{ fontSize: 10, marginBottom: 0, marginLeft: 10 }}>
                  {`${data.address.substring(0, 5)}....${data.address.substring(
                    data.address.length - 5,
                    data.address.length
                  )}`}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="Breakdown d-flex justify-content-between mb-2">
          <p>Fee:</p>
          <span className="p">$0.50(BE)</span>
        </div>
        <div className="Breakdown  d-flex justify-content-between mb-2">
          <p>Balance after transfer:</p>
          <span className="p">
            {' '}
            {Number(props.ethBalance) - (Number(data.amount) + Number(gas))}
          </span>
        </div>
        <div className="Breakdown  d-flex justify-content-between mb-2">
          <p>Deposit time:</p>
          <span className="p">instant</span>
        </div>
        <div className="mb-2 mt-4">
          <Button label="Back to wallet" onClick={nextStep} />
        </div>
      </div>
    </div>
  )

  const withdrawalFlow: { [key: number]: JSX.Element } = {
    1: step1(),
    2: step2(),
    3: step3(),
  }
  return (
    <>
      <Modal ref={ref}>
        <div className="Crypto-Send">{withdrawalFlow[step]}</div>
      </Modal>
    </>
  )
}

export default forwardRef(CryptoSendModal)
