import React, { useEffect, useRef, useState } from 'react'
import PropertyCard from '../../components/PropertyCard'
import { v4 as uuidv4 } from 'uuid'
import './styles.scss'
import Footer from '../../components/Footer'
import { useNavigate } from 'react-router-dom'
import { PROPERTIES } from '../../routing/navigation'
import API from '../../network/api'
import { toast } from '../../components/Toast'
import { IAuctionResponseData } from '../../interface/auction'
import Loader from '../../components/Loader'
import Card from '../../components/Card'
import { getDaysDiff } from '../../utils/date'
import { useErrorState } from '../../contexts/errorContext'

const Properties: React.FC = () => {
  const route = useNavigate()
  const { addError } = useErrorState()
  const isMounted = useRef(false)
  const [auctions, setAuctions] = useState<IAuctionResponseData[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const getAllAuctions = (): void => {
    setLoading(true)

    API.getActiveAutions({ assetType: 'Property' })
      .then((autionsResponse) => {
        if (isMounted.current) {
          setLoading(false)
          setAuctions(autionsResponse.data)
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setLoading(false)
        }
        addError(err)
        toast.error(err.message || 'Unable to get properties at the moment')
      })
  }

  const getFundedAuctions = (): void => {
    setLoading(true)

    API.getFundedAutions({ assetType: 'Property' })
      .then((autionsResponse) => {
        if (isMounted.current) {
          setLoading(false)
          setAuctions(autionsResponse.data)
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setLoading(false)
        }
        addError(err)
        toast.error(err.message || 'Unable to get properties at the moment')
      })
  }

  useEffect(() => {
    isMounted.current = true
    getAllAuctions()
    return () => {
      isMounted.current = false
    }
  }, [])

  const [status, setStatus] = useState<'available' | 'funded'>('available')

  const handleFundedClick = (): void => {
    getFundedAuctions()

    setStatus('funded')
  }

  const handleAvailableClick = (): void => {
    getAllAuctions()

    setStatus('available')
  }

  const onClickFav = (id: string): void => {
    let newAuction = auctions
    newAuction = newAuction.map((auction) => {
      if (auction._id === id) {
        auction.favorite = !auction.favorite
      }
      return auction
    })
    setAuctions(newAuction)
  }

  return (
    <>
      <div className="Properties">
        {loading && <Loader />}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="nav nav-pills mt-5 mb-5">
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      status === 'available' ? 'active' : ''
                    }`}
                    aria-current="page"
                    onClick={handleAvailableClick}
                  >
                    Available
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${
                      status === 'funded' ? 'active' : ''
                    }`}
                    onClick={handleFundedClick}
                  >
                    Funded
                  </button>
                </li>
              </ul>
            </div>
            {auctions?.length ? (
              <>
                {auctions.map((auction) => (
                  <div className="col-md-6 col-lg-4" key={uuidv4()}>
                    <PropertyCard
                      id={auction._id}
                      onClickFavorite={() => onClickFav(auction._id)}
                      onClick={() => route(`/${PROPERTIES}/${auction._id}`)}
                      sold={!auction.asset.forSale}
                      favorite={auction.favorite}
                      country={auction.asset.locationInfo.country?.toLowerCase()}
                      images={auction.asset.image.map(
                        (img) => img.thumbnail.url
                      )}
                      status={auction.asset.status}
                      summary={auction.asset.title || auction.asset.type}
                      price={Number(auction.asset?.price)}
                      currency={auction.asset.currency}
                      percentageSold={auction.percentageFunded}
                      projectedReturn={auction.asset.projectedReturn}
                      grossYield={auction.asset.grossYield}
                      daysLeft={getDaysDiff(auction.startTime, auction.endTime)}
                      rentPerMonth={Number(auction.asset.rentPerMonth || 0)}
                      distributionFrequency={
                        auction.asset.distributionFrequency
                      }
                    />
                  </div>
                ))}
              </>
            ) : (
              !loading && (
                <Card>
                  <div
                    style={{ height: '300px' }}
                    className="card-body d-flex justify-content-center align-items-center"
                  >
                    <h4 className="text-secondary">No Property found</h4>
                  </div>
                </Card>
              )
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Properties
