import React, { forwardRef, ForwardRefRenderFunction } from 'react'
import Button from '../Button'
import { Icon } from '../Icons'
import Modal, { IModal } from '../Modal'
import './styles.scss'

interface ModalProps {
  toggle: () => void
  message?: string
  currency?: string
  amount: any
}
const CrytoSuccessModal: ForwardRefRenderFunction<IModal, ModalProps> = (
  { ...props },
  ref
) => {
  return (
    <>
      <Modal ref={ref}>
        <div className="Crypto-success">
          <div className="Success">
            <div className="Modal-header">
              <div className="d-flex justify-content-end">
                <Icon
                  name="close"
                  onClick={() => {
                    props.toggle()
                  }}
                />
              </div>
              <h3 className="text-center">Transfer Successful </h3>
            </div>
            <div className="text-center mt-3">
              <Icon name="sucess-icon" />
              <p className="mt-3 mb-2 Amount">
                {props.currency} {props.amount}
              </p>
              <p className="Success-message">
                {props.message ??
                  'The transfer has been made to your wallet address'}
              </p>

              {/* <div className="d-flex justify-content-between Ts-date">
          <p className="Date-label">Date</p>
          <p className="Date">{moment().format('MMMM Do, YYYY')}</p>
        </div> */}
              <Button
                label="Continue"
                onClick={() => {
                  props.toggle()
                  // TEMP
                  window.location.reload()
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default forwardRef(CrytoSuccessModal)
