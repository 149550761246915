import React, { FC } from 'react'
import { Icon, IconProps } from '../Icons'
import './styles.scss'

interface ISignupButtonProps {
  label?: string
  icon?: IconProps
  isActive?: boolean
  onClick: () => void
}

const PayOptionButton: FC<ISignupButtonProps> = ({ label, icon, ...props }) => {
  return (
    <div>
      <button
        onClick={() => props.onClick()}
        className={`btn btn-outline Payoption-button${
          props.isActive ? ' active' : ''
        }`}
        disabled={label === 'Connect Bank Account'}
      >
        <div className="text-center">
          {icon && (
            <Icon name={icon.name} width={icon.width} height={icon.height} />
          )}
          {label && <p>{label}</p>}
          {label && label === 'Connect Bank Account' && (
            <div className="Is-coming">
              <p>Coming soon</p>
            </div>
          )}
        </div>
      </button>
    </div>
  )
}
export default PayOptionButton
