import React, { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'

interface ITitleAndDescProps {
  title: string
  description: string
  linkName?: string
  link?: string
  type: 'STD1' | 'STD2'
}

const SignupTitleAndDesc: FC<ITitleAndDescProps> = ({
  title,
  description,
  linkName,
  ...props
}) => {
  const renderType = (): ReactElement<any, any> | null => {
    switch (props.type) {
      case 'STD1':
        return (
          <div className="Title-desc">
            <h1>{title}</h1>
            <h4>
              {description}{' '}
              <Link
                to={props.link === undefined ? '/' : props.link}
                className="text-primary"
              >
                {linkName}
              </Link>
            </h4>
          </div>
        )
      case 'STD2':
        return (
          <div className="Title-desc">
            <h2 className="mb-3">{title}</h2>
            <h6>{description}</h6>
          </div>
        )
      default:
        return null
    }
  }
  return <>{renderType()}</>
}
export default SignupTitleAndDesc
