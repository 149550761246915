import React, { useEffect, useState } from 'react'
import Card from '../../components/Card'
import InnerNav from '../../components/InnerNav'
import Loader from '../../components/Loader'
import PageTitle from '../../components/PageTitle'
import Table from '../../components/Table'
import { toast } from '../../components/Toast'
import { useErrorState } from '../../contexts/errorContext'
import { IError } from '../../interface/error'
import {
  ECurrencyType,
  // ETransactionStatus,
  ITransactionsResponse,
} from '../../interface/transaction'
import API from '../../network/api'
import { fullDateString } from '../../utils/date'
import { setAmount } from '../../utils/number'
import { capitalize } from '../../utils/string'
import './styles.scss'

const FiatTransactions: React.FC = () => {
  const { addError } = useErrorState()

  const [transactions, setTransactions] =
    useState<ITransactionsResponse['data']>()
  const [loading, setLoading] = useState(false)

  const getTransactions = async (): Promise<void> => {
    setLoading(true)
    try {
      const allTransactions = await API.getAllTransactions({
        // status: ETransactionStatus.SUCCESS,
        currencyType: ECurrencyType.FIAT,
      })
      setTransactions(allTransactions.data)
    } catch (error) {
      const err = error as IError
      toast.error(err.message || 'An error occured please try later')
      addError(err)
    }
    setLoading(false)
  }
  useEffect(() => {
    getTransactions()
  }, [])

  const setTable = (): any => {
    let theData: any = []
    let count = 0
    if (transactions) {
      for (const transaction of transactions) {
        const layer = [
          <td key={transaction._id}>
            {fullDateString(transaction.createdAt)}
          </td>,

          <td key={transaction._id}>{capitalize(transaction.type)}</td>,
          <td key={transaction._id}>₦{setAmount(transaction.amount)}</td>,
          <td key={transaction._id}>{transaction.currency}</td>,
          <td key={transaction._id}>{transaction._id}</td>,
          <td key={transaction._id}>{capitalize(transaction.status)}</td>,
        ]
        theData = [...theData, layer]

        count += 1
        if (count === transactions.length) {
          return theData
        }
      }
    }
  }
  return (
    <div className="Transactions">
      <InnerNav>
        <PageTitle title="Fiat Transactions" />
        {loading && <Loader />}
        {transactions?.length ? (
          <div className="mb-5">
            <Table
              numberOfPages={10}
              limit={10}
              totalResult={10}
              hideSearchBar
              currentPage={1}
              hidePagination
              headers={[
                <th key={1}>Date</th>,
                <th key={2}>Type</th>,
                <th key={3}>Amount</th>,
                <th key={4}>Currency</th>,
                <th key={5}>Transaction Reference</th>,
                <th key={6}>Status</th>,
              ]}
              data={setTable()}
            />
          </div>
        ) : (
          !loading && (
            <Card>
              <div
                style={{ height: '300px' }}
                className="card-body d-flex justify-content-center align-items-center"
              >
                <h4 className="text-secondary">No Transaction found</h4>
              </div>
            </Card>
          )
        )}
      </InnerNav>
    </div>
  )
}

export default FiatTransactions
