import React from 'react'
import SignupTitleAndDesc from '../../components/SignupTitleAndDesc'
import './styles.scss'
import Button from '../../components/Button'
import { useLocation } from 'react-router-dom'
import { useAuthDispatch } from '../../contexts/authContext'
import { toast } from '../../components/Toast'

const CheckEmail: React.FC = () => {
  const state = useLocation().state as { email: string }
  const { updateEmail } = useAuthDispatch()

  const openEmail = (): void => {
    if (state?.email) {
      window.open('mailto:' + state.email)
    }
  }

  const resend = async (): Promise<void> => {
    try {
      await updateEmail({ email: state?.email })
      toast.success('Email sent successfully')
    } catch (error) {
      const err = error as Error
      toast.error(
        err.message || 'Can not resend email at the moment please try later'
      )
    }
  }

  const handleResend = (): void => {
    resend()
  }

  return (
    <>
      <SignupTitleAndDesc
        title="Check your email"
        description="we have sent a verification email."
        type="STD2"
      />

      <Button label="Open Email App" onClick={openEmail} />
      <div className="d-flex Resend-text">
        <p>Didn’t receive a email ?</p>
        <Button label="Resend email" onClick={handleResend} type="link" />
      </div>
    </>
  )
}

export default CheckEmail
