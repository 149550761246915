import { EStatus } from '../interface/auction'
import { IGetTransactions } from '../interface/transaction'
import { getQueryString } from '../utils/string'

/* eslint-disable @typescript-eslint/restrict-template-expressions */
const BASE_URL = process.env.REACT_APP_BASE_URL
// const VERIFF_BASE_URL = process.env.REACT_APP_VERIFF_BASE_URL
// const PAYVERTICAL_BASE_URL = process.env.REACT_APP_PAYVERTICAL_BASE_URL

export default {
  REQUEST_METAMASK_LOGIN: `${BASE_URL}/auth/request/metamask`,
  METAMASK_LOGIN: `${BASE_URL}/auth/login/metamask`,
  GOOGLE_LOGIN: `${BASE_URL}/auth/login/google`,
  EMAIL_REGISTRATION: `${BASE_URL}/auth/register`,
  LOGIN: `${BASE_URL}/auth/login`,
  LOGOUT: `${BASE_URL}/auth/logout`,
  REQUEST_PASSWORD_RESET: `${BASE_URL}/auth/request/password-reset`,
  VALIDATE_PASSWORD_RESET: (token: string) =>
    `${BASE_URL}/auth/password/reset/${token}`,
  RESET_PASSWORD: `${BASE_URL}/auth/password/reset`,
  GET_USER: `${BASE_URL}/auth`,
  UPDATE_PASSWORD: `${BASE_URL}/user/password`,
  VERIFY_PHONE: `${BASE_URL}/user/phone`,
  VERIFY_OTP: `${BASE_URL}/user/phone/verify`,
  UPDATE_EMAIL: `${BASE_URL}/user/email`,
  VERIFY_EMAIL: (token: string, email: string) =>
    `${BASE_URL}/user/email/verify?token=${token}&email=${email}`,
  AUCTION: (status: EStatus) => `${BASE_URL}/auctions?status=${status}`,
  ACTIVE_AUCTION: `${BASE_URL}/auctions/active`,
  FUNDED_AUCTION: `${BASE_URL}/auctions/funded`,
  SINGLE_AUCTION: (id: string) => `${BASE_URL}/auctions/${id}`,
  SAVED_AUCTION: () => `${BASE_URL}/auctions/saved`,
  SAVE_AUCTION: (id: string) => `${BASE_URL}/auctions/${id}/save`,
  UNSAVE_AUCTION: (id: string) => `${BASE_URL}/auctions/${id}/save`,
  MAKE_A_BID: (id: string) => `${BASE_URL}/auctions/${id}/bid`,
  TRANSACTIONS: (query: IGetTransactions) =>
    `${BASE_URL}/transactions${getQueryString(query)}`,
  BIDS: `${BASE_URL}/bids`,
  INVESTMENTS: `${BASE_URL}/investments`,
  PROFILE: `${BASE_URL}/profile`,
  CREATE_VERIFICATION_SESSION: `${BASE_URL}/kyc/sessions`,
  KYC_DECISION: (sessionId: string) =>
    `${BASE_URL}/kyc/sessions/${sessionId}/decision`,
  SETTINGS: `${BASE_URL}/settings`,
  WALLETS: `${BASE_URL}/wallets`,
  CARD_DEPOSIT: `${BASE_URL}/transactions/deposits`,
  WIRE_DEPOSIT: `${BASE_URL}/transactions/deposits/wire-transfers`,
  WIRE_WITHDRAWAL: `${BASE_URL}/transactions/withdraw/request`,
  VERIFY_CARD_DEPOSIT: (reference: string) =>
    `${BASE_URL}/transactions/deposits/${reference}/verify`,
  BENEFICIARY: `${BASE_URL}/beneficiaries`,
  CONVERT_FIAT_CURRENCY: (from: string, to: string, amount: number) =>
    `${BASE_URL}/currencies/fiat/convert?from=${from}&to=${to}&amount=${amount}`,
  GET_CRYPTO_RATE: (from: string, to: string) =>
    `${BASE_URL}/currencies/crypto/rate?from=${from}&to=${to}`,
  CONVERT_ANY: (from: string, to: string, amount: number) =>
    `${BASE_URL}/currencies/convertAny?from=${from}&to=${to}&amount=${amount}`
}
