import React, { createContext, useReducer, ReactNode, FC } from 'react'
import UserReducer from './reducer'
import Api from '../../network/api'
import { GET_PROFILE, GET_USER, LOADING, LOGIN, LOGOUT } from './types'
import {
  IAuthState,
  IEmailReg,
  IGoogleLogin,
  ILogin,
  ILoginResponse,
  IMetaLoginResponse,
  IMetaMaskLogin,
  IMetaMaskRequest,
  IMetaMaskRequestResponse,
  IReqestPassword,
  IReqestPasswordResponse,
  IResetPassword,
  IUpdatePassword,
  IValidatePasswordResetResponse,
} from '../../interface/auth'
import { TOKEN } from '../../constants/variables'
import {
  IGetProfileResponseData,
  IUpdateEmail,
  IUser,
  IVerifyEmail,
  IVerifyOtp,
  IVerifyOTPResponse,
  IVerifyPhone,
  IVerifyPhoneResponse,
} from '../../interface/user'
import { useErrorState } from '../errorContext'
import { IError } from '../../interface/error'

const initialState: IAuthState = {
  isAuthenticated: !!localStorage.getItem(TOKEN),
  accessToken: localStorage.getItem(TOKEN) ?? '',
  refreshToken: '',
  publicAddress: '',
  user: null,
  profile: null,
}
interface IAuthDispatch {
  requestMetamaskLogin: (
    data: IMetaMaskRequest
  ) => Promise<IMetaMaskRequestResponse>
  metamaskLogin: (data: IMetaMaskLogin) => Promise<IMetaLoginResponse>
  googleLogin: (data: IGoogleLogin) => Promise<any>
  emailRegistration: (data: IEmailReg) => Promise<any>
  login: (data: ILogin) => Promise<ILoginResponse>
  logout: () => Promise<any>
  resetPasswordRequest: (
    data: IReqestPassword
  ) => Promise<IReqestPasswordResponse>
  validatePasswordReset: (
    token: string
  ) => Promise<IValidatePasswordResetResponse>
  resetPassword: (
    data: IResetPassword
  ) => Promise<IValidatePasswordResetResponse>

  getUser: () => Promise<IUser>
  updatePassword: (data: IUpdatePassword) => Promise<string>
  verifyPhone: (data: IVerifyPhone) => Promise<IVerifyPhoneResponse>
  verifyOtp: (data: IVerifyOtp) => Promise<IVerifyOTPResponse>
  updateEmail: (data: IUpdateEmail) => Promise<any>
  verifyEmail: (data: IVerifyEmail) => Promise<any>
  dispatchLogout: () => Promise<void>
  getProfile: () => Promise<IGetProfileResponseData>
}

const contextError = 'Invalid dispatch request'

export const AuthContext = createContext<IAuthState>(initialState)
export const AuthDispatch = createContext<IAuthDispatch>({
  requestMetamaskLogin: async (data: IMetaMaskRequest) => {
    throw new Error(contextError)
  },
  metamaskLogin: async (data: IMetaMaskLogin) => {
    throw new Error(contextError)
  },
  googleLogin: async (data: IGoogleLogin) => {
    throw new Error(contextError)
  },
  emailRegistration: (data: IEmailReg) => {
    throw new Error(contextError)
  },
  login: (data: ILogin) => {
    throw new Error(contextError)
  },
  logout: () => {
    throw new Error(contextError)
  },
  resetPasswordRequest: (data: IReqestPassword) => {
    throw new Error(contextError)
  },
  validatePasswordReset: (token: string) => {
    throw new Error(contextError)
  },
  resetPassword: (data: IResetPassword) => {
    throw new Error(contextError)
  },
  getUser: () => {
    throw new Error(contextError)
  },
  updatePassword: (data: IUpdatePassword) => {
    throw new Error(contextError)
  },
  verifyPhone: (data: IVerifyPhone) => {
    throw new Error(contextError)
  },
  verifyOtp: (data: IVerifyOtp) => {
    throw new Error(contextError)
  },
  updateEmail: (data: IUpdateEmail) => {
    throw new Error(contextError)
  },
  verifyEmail: (data: IVerifyEmail) => {
    throw new Error(contextError)
  },
  dispatchLogout: () => {
    throw new Error(contextError)
  },
  getProfile: () => {
    throw new Error(contextError)
  },
})

export const AuthProvider: FC<{ children: ReactNode }> = ({
  children,
}: {
  children: ReactNode
}) => {
  const [state, dispatch] = useReducer(UserReducer, initialState)
  const { addError } = useErrorState()

  const requestMetamaskLogin = async (
    data: IMetaMaskRequest
  ): Promise<IMetaMaskRequestResponse> => Api.requestMetaMask(data)

  const metamaskLogin = async (
    data: IMetaMaskLogin
  ): Promise<IMetaLoginResponse> => {
    const resp = await Api.loginMetaMask(data)
    dispatch({
      type: LOGIN,
      payload: resp.data,
    })
    return resp
  }

  const googleLogin = async (data: IGoogleLogin): Promise<any> => {
    const resp = await Api.loginGoogle(data)
    dispatch({
      type: LOGIN,
      payload: resp.data,
    })
    return resp
  }

  const emailRegistration = async (data: IEmailReg): Promise<any> => {
    return Api.emailReg(data)
  }

  const login = async (data: ILogin): Promise<ILoginResponse> => {
    const resp = await Api.login(data)
    dispatch({
      type: LOGIN,
      payload: resp.data,
    })
    return resp
  }

  const logout = async (): Promise<any> => {
    const resp = await Api.logout()
    dispatch({
      type: LOGOUT,
    })
    return resp
  }

  const dispatchLogout = async (): Promise<void> => {
    dispatch({
      type: LOGOUT,
    })
  }

  const resetPasswordRequest = async (
    data: IReqestPassword
  ): Promise<IReqestPasswordResponse> => {
    return Api.requestPasswordReset(data)
  }

  const validatePasswordReset = async (
    token: string
  ): Promise<IValidatePasswordResetResponse> => {
    return Api.validatePasswordReset(token)
  }

  const resetPassword = async (
    data: IResetPassword
  ): Promise<IValidatePasswordResetResponse> => {
    return await Api.resetPassword(data)
  }

  const getUser = async (): Promise<IUser> => {
    try {
      dispatch({ type: LOADING })
      const resp = await Api.getUser()
      dispatch({
        type: GET_USER,
        payload: resp.data,
      })
      return resp
    } catch (error) {
      const err = error as IError
      addError({ message: err.message, status: err.status })
      throw error
    }
  }

  const updatePassword = async (data: IUpdatePassword): Promise<string> => {
    try {
      const resp = await Api.updatePassword(data)
      return resp
    } catch (error) {
      const err = error as IError
      addError({ message: err.message, status: err.status })
      throw error
    }
  }

  const verifyPhone = async (
    data: IVerifyPhone
  ): Promise<IVerifyPhoneResponse> => {
    try {
      const resp = await Api.verifyPhone(data)
      return resp
    } catch (error) {
      const err = error as IError
      addError({ message: err.message, status: err.status })
      throw error
    }
  }

  const verifyOtp = async (data: IVerifyOtp): Promise<IVerifyOTPResponse> => {
    try {
      const resp = await Api.verifyOtp(data)
      return resp
    } catch (error) {
      const err = error as IError
      addError({ message: err.message, status: err.status })
      throw error
    }
  }

  const updateEmail = async (data: IUpdateEmail): Promise<any> => {
    try {
      const resp = await Api.updateEmail(data)
      return resp
    } catch (error) {
      const err = error as IError
      addError({ message: err.message, status: err.status })
      throw error
    }
  }

  const verifyEmail = async (data: IVerifyEmail): Promise<any> => {
    try {
      const resp = await Api.verifyEmail(data)
      return resp
    } catch (error) {
      const err = error as IError
      addError({ message: err.message, status: err.status })
      throw error
    }
  }

  const getProfile = async (): Promise<IGetProfileResponseData> => {
    try {
      const resp = await Api.getProfile()
      dispatch({
        type: GET_PROFILE,
        payload: resp.data,
      })
      return resp
    } catch (error) {
      const err = error as IError
      addError({ message: err.message, status: err.status })
      throw error
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
        publicAddress: state.publicAddress,
        user: state.user,
        profile: state.profile,
      }}
    >
      <AuthDispatch.Provider
        value={{
          requestMetamaskLogin,
          metamaskLogin,
          googleLogin,
          emailRegistration,
          login,
          resetPasswordRequest,
          validatePasswordReset,
          resetPassword,
          getUser,
          updatePassword,
          verifyPhone,
          verifyOtp,
          updateEmail,
          verifyEmail,
          logout,
          dispatchLogout,
          getProfile,
        }}
      >
        {children}
      </AuthDispatch.Provider>
    </AuthContext.Provider>
  )
}

function useAuthState(): IAuthState {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuthState must be used with a AuthProvider')
  }
  return context
}

function useAuthDispatch(): IAuthDispatch {
  const context = React.useContext(AuthDispatch)
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used with a AuthProvider')
  }
  return context
}

export { useAuthDispatch, useAuthState }
